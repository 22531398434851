import { makeStyles } from "@material-ui/core";
import { SearchOutlined, PersonAdd } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

export default function ChatSearch({ searchChats, addUser }) {
  const styles = useStyles();
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    searchChats(searchString);
  }, [searchString]);

  const onSearch = (event) => {
    setSearchString(event.target.value);
  };

  const onAddUser = () => {
    addUser();
  };

  return (
    <div className={styles.chatSearchContainer}>
      <div className={styles.chatSearchBar}>
        <div className={styles.iconContainer}>
          <SearchOutlined></SearchOutlined>
        </div>
        <input type="search" value={searchString} onChange={onSearch}></input>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatSearchContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "2.5rem",
    padding: "1rem",
    margin: "20px 0",
  },
  chatSearchBar: {
    display: "flex",
    flexGrow: 2,
    flexDirection: "row",
    alignItems: "center",
    color: "#6B6F82",
    backgroundColor: "#ECEFF1",
    height: "2.5rem",
    width: "5rem",
    padding: "0.2rem 0.75rem",
    borderRadius: "1.5rem",
    marginRight: "1rem",

    "& input": {
      border: "none",
      backgroundColor: "#ECEFF1",
      height: "2rem",
      fontSize: "1.2rem",
      color: "#6B6F82",
      width: "100%",
    },

    "& input:focus": {
      outline: "none",
    },
  },
  iconContainer: {},
  addUserButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#212121",
    color: "#FFF",
    height: "2.5rem",
    width: "2.5rem",
    borderRadius: "1.5rem",
    border: "none",
    cursor: "pointer",

    "&:focus": {
      outline: "none",
    },
  },
  "#Search::-ms-clear": {
    color: "#6B6F82",
  },
  "#Search::-webkit-search-cancel-button": {
    color: "#6B6F82",
  },
}));
