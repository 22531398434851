import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const AccountService = {
  sendResetPasswordLink,
  getCurrentUser,
  resetPassword,
  changeEmail,
  changeEmailConfirmation,
  changePassword,
};

function getCurrentUser() {
  return fetchWrapper.get(`${API_URL}/User/current-user`);
}

function sendResetPasswordLink(email) {
  return fetchWrapper.get(`${API_URL}/Account/send-reset-link?email=${email}`);
}

function resetPassword(token) {
  return fetchWrapper.get(`${API_URL}/Account/reset-password?code=${token}`);
}

function changeEmailConfirmation(newEmail) {
  return fetchWrapper.post(`${API_URL}/Account/email-change-confirmation`, { newEmail });
}

function changeEmail(token) {
  return fetchWrapper.get(`${API_URL}/Account/change-email`, { token });
}

function changePassword(oldPassword, newPassword) {
  return fetchWrapper.post(`${API_URL}/Account/change-password?OldPassword=${oldPassword}&NewPassword=${newPassword}`);
}
