import React from "react";
import { Route } from "react-router-dom";
import AuthLayout from "components/layouts/AuthLayout/AuthLayout";

export default function AuthRouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout {...props}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
}
