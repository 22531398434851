import { getFullBlobUrl } from "./blobHelpers";
export function aggregateChatMessagesIntoBlocks(
  chatMessages,
  currentUserId,
  currentUserPrimaryImageUrl,
  activeRecipientIdPrimaryImageUrl
) {
  return chatMessages.reduce(
    (acc, item, idx) => {
      let direction;
      let avatar;

      if (item.senderId === currentUserId) {
        direction = "out";
        avatar = getFullBlobUrl(currentUserPrimaryImageUrl);
      } else {
        direction = "in";
        avatar = getFullBlobUrl(activeRecipientIdPrimaryImageUrl);
      }

      if (!acc.messageBlocks.length) {
        acc.messageBlocks.push({
          userId: item.senderId,
          direction,
          avatar,
          messages: [],
        });

        acc.lastMessageDirection = direction;
      }

      if (acc.lastMessageDirection !== direction) {
        acc.messageBlocks.push({
          userId: item.senderId,
          direction,
          avatar,
          messages: [],
        });

        acc.lastMessageDirection = direction;
      }

      acc.messageBlocks[acc.messageBlocks.length - 1].messages.push({
        id: item.id,
        text: item.text,
        isSeen: item.isSeen,
        date: new Date(item.sentDateTime),
      });

      return acc;
    },
    {
      messageBlocks: [],
      lastMessageDirection: "",
    }
  ).messageBlocks;
}

export function getCombinedMessageBlocks(currentMessageBlocks, newMessageBlocks) {
  const blocks = [...currentMessageBlocks];
  if (blocks[blocks.length - 1].direction === newMessageBlocks[0].direction) {
    blocks[blocks.length - 1].messages.push(...newMessageBlocks[0].messages);
  } else {
    blocks.push(newMessageBlocks[0]);
  }

  if (newMessageBlocks.length > 1) {
    blocks.push(...newMessageBlocks.slice(1));
  }
  return blocks;
}

export function sortArrayBySentDateTime(array) {
  return array.sort((x, y) => (new Date(x.sentDateTime) > new Date(y.sentDateTime) ? -1 : 1));
}

export function aggregateChatMessagesIntoChatDays(
  chatMessages,
  currentUserId,
  currentUserPrimaryImageUrl,
  activeRecipientIdPrimaryImageUrl
) {
  const chatMessageDays = [];
  chatMessages.forEach((m) => {
    const messageDate = new Date(m.sentDateTime);
    const year = messageDate.getFullYear().toString();
    const month = messageDate.getMonth().toString();
    const date = messageDate.getDate().toString();

    let foundDay = chatMessageDays.find((day) => day.year === year && day.month === month && day.date === date);

    if (!foundDay) {
      foundDay = { year, month, date, messages: [], messageBlocks: [] };
      chatMessageDays.push(foundDay);
    }

    foundDay.messages.push(m);
  });

  chatMessageDays.forEach(
    (day) =>
      (day.messageBlocks = aggregateChatMessagesIntoBlocks(
        day.messages,
        currentUserId,
        currentUserPrimaryImageUrl,
        activeRecipientIdPrimaryImageUrl
      ))
  );

  return chatMessageDays;
}

export function getRecipientId(locationString) {
  if (!locationString) {
    return null;
  }
  const recipientParam = locationString
    .replace("?", "")
    .split("&")
    .find((l) => l.includes("recipientId="));

  if (!recipientParam) {
    return null;
  }

  const recipientIdString = recipientParam.replace("recipientId=", "");

  const parsedId = parseInt(recipientIdString);

  return Number.isNaN(parsedId) ? null : parsedId;
}
