import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const usePaginate = () => {
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ]);
  const [page, setPage] = useState(1);

  React.useEffect(() => {
    const currentPage = Number(queryParams.get("page"));
    if (currentPage >= 0) {
      setPage(currentPage);
    }
  }, [queryParams, setPage]);

  return { page };
};

export default usePaginate;