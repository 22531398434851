import { makeStyles } from "@material-ui/core";
import React, { useRef, useEffect, useState } from "react";
import ChatMessageBlock from "../chatMessageBlock/chatMessageBlock";
import ChatMessageDay from "../chatMessageDay/chatMessageDay";
import { useTranslation } from 'react-i18next';

export default function ChatPaneBody({
  activeRecipient,
  messageBlocks,
  messageDays,
  getOlderMessages,
  noOlderMessagesAvailable,
  isLoadingOlderMessages,
  openActiveRecipientProfile,
}) {
  const styles = useStyles();
  const chatContentRef = useRef(null);
  const [paneOffset, setPaneOffset] = useState(null);
    const { t, i18n } = useTranslation();

  useEffect(() => {
    if (chatContentRef.current) {
      if (
        chatContentRef.current.children &&
        chatContentRef.current.children[0] &&
        chatContentRef.current.children[0].offsetHeight
      ) {
        chatContentRef.current.scrollTop = isLoadingOlderMessages
          ? chatContentRef.current.children[0].offsetHeight - paneOffset + 32
          : chatContentRef.current.children[0].offsetHeight;
      } else {
        chatContentRef.current.scrollTop = chatContentRef.current.offsetHeight;
      }
    }
  }, [messageBlocks]);

  const onScroll = (event) => {
    if (
      messageBlocks &&
      !noOlderMessagesAvailable &&
      !isLoadingOlderMessages &&
      chatContentRef &&
      chatContentRef.current &&
      chatContentRef.current.scrollTop == 0
    ) {
      event.preventDefault();
      setPaneOffset(chatContentRef.current.children[0].offsetHeight);
      getOlderMessages();
    }
  };

  return (
    <div className={styles.chatContent} ref={chatContentRef} onScroll={onScroll}>
      {activeRecipient && (
        <div className={styles.chatPaneBody}>
          <div className={styles.chatPaneHead}>
            {" "}
                      {isLoadingOlderMessages ? t("chat.loading")  : ""}{" "}
                      {!isLoadingOlderMessages && noOlderMessagesAvailable ? t("chat.chatStart") : ""}{" "}
          </div>
          {messageDays.map((messageDay, idx) => (
            <ChatMessageDay
              key={`${messageDay.year}-${messageDay.month}-${messageDay.date}`}
              messageDay={messageDay}
              openActiveRecipientProfile={openActiveRecipientProfile}
            ></ChatMessageDay>
          ))}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatPaneBody: {
    // height: '100%',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    minHeight: "100%",
    paddingBottom: "1rem",
    // minWidth: '37.625rem',
    overflowX: "hidden",
  },
  chatContent: {
    flex: "1 2 auto",
    overflowY: "scroll",
  },
  chatPaneHead: {
    width: "100%",
    height: "1rem",
    textAlign: "center",
    paddingTop: "0.5rem",
    color: "#999",
  },
}));
