import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography, Button, FormControl, CardHeader, TextField, FormHelperText } from "@material-ui/core";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import Alert from "components/ui/Alert/Alert";

import useForm from "hooks/useForm";
import validateRegistration from "validations/registration";
import { useTranslation } from 'react-i18next';
import { AccountService } from "services/account-service";

const ForgotPasswordPage = ({ history }) => {
  const classes = useStyles();
  const { handleChange, handleSubmit, values, errors } = useForm(postResetLink, validateRegistration);

  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [modifiedEmail, setModifiedEmail] = useState("");
    const { t, i18n } = useTranslation();

  function postResetLink() {
    const { email } = values;
    let replace;
    if (email.includes("@")) {
      replace = email.replace("@", "%40");
      setModifiedEmail(replace);
    }
    AccountService.sendResetPasswordLink(modifiedEmail)
      .then(() => {
        setAlert(true);
        setTimeout(() => {
          history.push("/login");
        }, 5000);
      })
      .catch(() => {
        setIsError(true);
      });
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <CardHeader className={classes.formHeader} title={t("auth.forgotPassword.resetPass")} />

      <div className={classes.inputsContainer}>
        <div className={classes.textFieldContainer}>
          <MailOutlinedIcon className={classes.icon} color={"primary"} />
          <TextField
            label="Email"
            name="email"
            type="email"
            color={"primary"}
            className={classes.textField}
            value={values.email}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: classes.input,
                focused: classes.focused,
                underline: classes.underline,
                input: classes.inputInput,
              },
            }}
          />
        </div>
      </div>
      {errors.email && (
        <FormHelperText className={classes.errorText} error={errors.email}>
          {errors.email}
        </FormHelperText>
      )}

      <FormControl fullWidth>
        <Button
          fullWidth
          variant={"contained"}
          type="submit"
          classes={{
            root: classes.enterButtonRoot,
            label: classes.enterButtonLabel,
          }}
        >
          {t("buttons.send")}
        </Button>
      </FormControl>

      <div className={classes.forgetPassword}>
        <Typography variant="h6" className={classes.bottomLink}>
          <Link color={"secondary"} href={"/login"} underline={"none"}>
            {t("auth.forgotPassword.returnToLogin")}
          </Link>
                  {isError && <div>{t("auth.forgotPassword.error")}</div>}
        </Typography>
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgba(255,255,255,0.8)",
    borderRadius: "6px",
  },
  formHeader: {
    color: "#B53E85",
    padding: 14,
    paddingBottom: 10,
  },
  input: {
    "&:not(.Mui-disabled):hover::before": {
      borderBottomWidth: "1px",
    },
  },
  inputInput: {
    height: 20,
  },
  underline: {
    "&:hover": {
      borderBottomWidth: "1px",
    },
  },
  checkboxRoot: {
    marginLeft: 1,
    marginTop: 10,
  },
  checkboxLabel: {
    fontSize: 14,
    color: "rgba(0,0,0,0.87)",
  },
  // BUTTONS
  enterButtonRoot: {
    width: "80%",
    marginTop: 15,
    marginBottom: 5,
    borderRadius: 2,
    background: "linear-gradient(45deg,#8e24aa,#ff6e40) !important",
    alignSelf: "center",
  },
  enterButtonLabel: {
    color: "white",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  },
  socialButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  socialButton: {
    width: "80% !important",
    fontSize: "13px !important",
    height: "35px !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
  },
  bottomSignUp: {
    textAlign: "center",
    marginTop: 24,
    marginBottom: 16,
  },
  forgetPassword: {
    marginTop: "20px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#36a0e2",
    borderBottom: "4px",
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.only("sm")]: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  bottomLink: {
    [theme.breakpoints.only("sm")]: {
      fontSize: "16px",
    },
  },
  icon: {
    fontSize: 28,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  textFieldContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
    width: "90%",
    paddingTop: "5px",
    "&:last-child": {
      paddingBottom: "15px",
    },
  },
  textField: {
    width: "80%",
  },
  errorText: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default ForgotPasswordPage;
