import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import "./app.css";
import _ from "lodash";

import Login from "modules/Auth/LoginPage";
import ForgotPassword from "modules/Auth/ForgotPassword";
import ResetPassword from "modules/Auth/ResetPassword";
import ChangeEmail from "modules/Auth/ChangeEmail";
import UserPage from "modules/User/userPage";
import SearchPage from "modules/Search/searchPage";
import ChatPage from "modules/Chat/chatPage";
import FavoritesPage from "modules/Favorites/favoritesPage";
import BlackListPage from "modules/Blacklist/blacklistPage";
import SettingsPage from "modules/Settings/settingsPage";
import RulesPage from "modules/Rules/RulesPage";
import PersonalInfo from "modules/PersonalInfo/PersonalInfo";
import AdminPage from "modules/Admin/adminPage";
import ErrorPage from "components/errorPage";
import AdminChatPage from "modules/AdminChat/adminChatPage";

import Loader from "components/ui/Loader/loader";

import AuthRoute from "helpers/authRoutesWrapper";
import PrivateRoute from "helpers/privateRoute";

import { AuthService } from "services/auth-service";
import { AccountService } from "services/account-service";
import { InfoService } from "services/info-service";
import { WsService } from "services/ws-service";

//check if currentUser object is empty for refreshLogin()

const App = () => {
  const { pathname } = useLocation();
  const [auth, setAuth] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [lookups, setLookups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesPath, setImagesPath] = useState("");
  const [wsConnection, setWsConnection] = useState(null);

  const state = {
    auth,
    currentUser,
    setCurrentUser,
    isLoading,
    setIsLoading,
    lookups,
    imagesPath,
  };

  useEffect(() => {
    const subscription = AuthService.user.subscribe((item) => setAuth(item));
    if (AuthService && AuthService.userValue) {
      WsService.connect();
    }
    setWsConnection(WsService.connection);
  }, []);

  useEffect(() => {
    if (wsConnection && wsConnection.state == "Disconnected" && auth) {
      wsConnection
        .start()
        .then((result) => {
          console.log("Connected!");
          wsConnection.on("UserOnline", (userId) => {});
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [wsConnection, auth]);

  useEffect(() => {
    if (!_.isEmpty(auth)) {
      setIsLoading(true);
      AccountService.getCurrentUser()
        .then((res) => setCurrentUser(res))
        .finally(() => setIsLoading(false));
    }
    if (!auth && wsConnection) {
      wsConnection.stop();
    }
    if (!auth) {
      setCurrentUser({});
    }
  }, [auth]);

  useEffect(() => {
    if (!Object.keys(currentUser).length) return;
    InfoService.getLookups().then((res) =>
      setLookups({
        ...lookups,
        genders: res.genders,
        lookingForGender: res.lookingForGender,
        kids: res.kids,
        sexualOrientations: res.sexualOrientations,
        currentRelationshipStatuses: res.currentRelationshipStatuses,
        positions: res.positions,
        languages: res.languages,
        level: res.level,
        canWorkRemotely: res.canWorkRemotely,
      })
    );
    InfoService.getImagesPath().then((res) => setImagesPath(res.imagesPath));
  }, [currentUser]);

  if (window.location.pathname === "/user/undefined") {
    return <Redirect to={{ pathname: `/user/${currentUser?.id}` }} />;
  }

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route
            exact
            path="/"
            render={() => {
              if (!_.isEmpty(auth)) {
                {
                  return !isLoading && <Redirect to={{ pathname: `/user/${currentUser?.id}` }} />;
                }
              } else {
                return <Redirect to={{ pathname: "/login" }} />;
              }
            }}
          />
          <AuthRoute path="/login" component={Login} currentUser={currentUser} />
          <AuthRoute path="/forgot-password" component={ForgotPassword} currentUser={currentUser} />
          <AuthRoute path="/Account/reset-password" component={ResetPassword} currentUser={currentUser} />
          <AuthRoute path="/Account/change-email" component={ChangeEmail} currentUser={currentUser} auth={auth} />
          <PrivateRoute path="/search/" component={SearchPage} state={state} currentUser={currentUser} />
          {!isLoading && <PrivateRoute path="/user/:id?" component={UserPage} state={state} />}
          <PrivateRoute path="/chat/:id?" component={ChatPage} state={state} wsConnection={wsConnection} />
          <PrivateRoute path="/favorites" component={FavoritesPage} state={state} />
          <PrivateRoute path="/blacklist" component={BlackListPage} state={state} />
          <PrivateRoute path="/settings" component={SettingsPage} state={state} />
          <PrivateRoute path="/rules" component={RulesPage} state={state} />
          <PrivateRoute path="/personal-info" component={PersonalInfo} state={state} />
          {currentUser?.role === "Admin" && (
            <PrivateRoute path="/admin" component={AdminPage} state={state} currentUser={currentUser} />
          )}
          {currentUser?.role === "Admin" && (
            <PrivateRoute path="/admin-chat/:id" component={AdminChatPage} state={state} />
          )}
          <Route path="/error-request-500" render={(props) => <ErrorPage props={props} error={500} />} />
          {!isLoading && <Route path="*" render={(props) => <ErrorPage props={props} error={404} />} />}
        </Switch>
      )}
    </>
  );
};

export default App;
