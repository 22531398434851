import React from "react";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const RulesPage = () => {
    const { t, i18n } = useTranslation();
  return (
    <Container className="rules-page">
      <div>Страница правил</div>
          <Link to="/settings">{t("buttons.back")}</Link>
    </Container>
  );
};

export default RulesPage;
