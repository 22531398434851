import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import brandCardHeaderStyles from "./cardHeaderStyles";
import { Avatar, Typography } from "@material-ui/core";

const BrandCardHeader = React.memo(
  withStyles(brandCardHeaderStyles, {
    name: "BrandCardHeader",
  })(({ classes, image, extra }) => (
    <div className={classes.root}>
      <div className={classes.header}>
        {image && <Avatar alt={"brand logo"} className={classes.avatar} src={image} />}
        <Typography className={classes.extra}>{extra}</Typography>
      </div>
      <hr />
    </div>
  ))
);

BrandCardHeader.propTypes = {
  image: PropTypes.string,
  extra: PropTypes.string,
};
BrandCardHeader.defaultProps = {
  image: "",
  extra: "",
};

export default BrandCardHeader;
