import Validator from "validator";

export default function validateLogin(values, isSubmitting = false) {
  let errors = {};

  if (("email" in values && (!values.email || !values.email.trim())) || (!("email" in values) && isSubmitting)) {
    errors.email = "Введите почту";
  } else if (values.email && !Validator.isEmail(values.email)) {
    errors.email = "Некорректный адрес почты";
  }

  if (("password" in values && !values.password) || (!("password" in values) && isSubmitting)) {
    errors.password = "Пароль обязателен для ввода";
  } else if (values.password && (values.password.length > 20 || values.password.length < 4)) {
    errors.password = "Пароль должен содержать от 5 до 20 символов";
  }

  return errors;
}
