import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import useWindowDimensions from "hooks/useWindowDimension";
import { adminRoutes, userRoutes } from "routes";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

export default function NavContent({ currentUser }) {
  const classes = useStyles();
  let match = useRouteMatch();
  const { width } = useWindowDimensions();
    const { t, i18n } = useTranslation();

  const currentRoutes = currentUser.role === "User" ? userRoutes : adminRoutes;

  const isActive = () => {
    if (+match.params.id === currentUser.id) {
      return true;
    }
    return;
  };

  return (
    <List className={classes.list}>
      <NavLink className={classes.listItemLogo} exact to="/search" isActive={isActive}>
        PingPing
      </NavLink>
      <ListItem className={classes.listItem} button disableGutters>
        <NavLink
          className={classes.link}
          exact
          to={`/user/${currentUser.id}`}
          isActive={isActive}
          activeClassName={classes.activeLink}
        >
          <FaceIcon className={classes.icon} />
                  <h6 className={classes.text}>{t("menu.myPage")}</h6>
        </NavLink>
      </ListItem>
      {currentRoutes.map(({ name, path, icon: Icon }, index) => {
        return (
          name?.length && (
            <div key={index}>
              <ListItem className={classes.listItem} button disableGutters>
                <NavLink className={classes.link} to={path} activeClassName={classes.activeLink}>
                  <Icon className={classes.icon} />
                  <h6 className={classes.text}>{t(name)}</h6>
                </NavLink>
              </ListItem>
            </div>
          )
        );
      })}
    </List>
  );
}

const useStyles = makeStyles({
  list: {
    display: "flex",
    width: "100%",
    maxWidth: "902px", // *custom width: 950px from container - paddings
    margin: "0 auto",
    padding: "0",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItem: {
    padding: "0",
    marginLeft: "10px",
    width: "100px",
    paddingRight: "3px",
    borderRadius: "4px",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  listItemLogo: {
    padding: "0",
    width: "auto",
    fontFamily: "Spicy Rice",
    fontSize: "35px",
    color: "rgb(81, 45, 168)",
    textDecoration: "none",
  },
  activeLink: {
    color: "#512da8 !important",
    //background: 'linear-gradient(left, #373797, #7224a2)',
    borderRadius: "2px",
    //boxShadow: '2px 3px 3px 1px #cab2d6'
  },
  text: {
    padding: 0,
    margin: 0,
    fontWeight: "500",
    fontSize: "9px",
    textTransform: "uppercase",
  },
  link: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
    width: "100%",
    height: "100%",
    alignItems: "center",
    color: "gray",
  },
  icon: {
    fontSize: "1.5em",
    height: "1.15em",
    width: "1.15em",
    //'&:hover': {
    //transform: 'rotate(25deg)'
    //}
  },
  logoimg: {
    width: "58px",
  },
});
