import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const BlackListService = {
  getBlackList,
  putBlackList,
  deleteBlackList,
};

function getBlackList(CurrentPage, PageSize) {
  return fetchWrapper.get(`${API_URL}/BlackList?CurrentPage=${CurrentPage}&PageSize=${PageSize}`);
}

function putBlackList(id) {
  return fetchWrapper.put(`${API_URL}/BlackList`, id);
}

function deleteBlackList(blackListedUserId) {
  return fetchWrapper.delete(`${API_URL}/BlackList/${Number(blackListedUserId)}`);
}
