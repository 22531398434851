import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import { purple, teal } from "@material-ui/core/colors";

export default function setCheckbox({ setValue, value, title, type }) {
  return (
    <FormControlLabel
      control={<ColorizedCheckbox checked={value} onChange={(e) => setValue(type, e.target.checked)} />}
      label={title}
      labelPlacement="end"
      style={{ margin: 0 }}
    />
  );
}

const ColorizedCheckbox = withStyles((theme) => ({
  root: {
    color: purple[600],
    "&$checked": {
      color: purple[800],
    },
  },
  checked: {},
}))((props) => <Checkbox color="primary" {...props} />);
