import React from "react";
import { Container } from "@material-ui/core";
import SettingsActions from "./components/settingsActions";
// import './settingsPage.scss';
import "./settingsPage.css";

const SettingsPage = ({ currentUser }) => {
  return (
    // <Container className="settings-page">
      <SettingsActions currentUser={currentUser} />
    // </Container> 
  );
};

export default SettingsPage;
