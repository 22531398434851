import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

const ComplaintsService = {
  getUserComplaints,
  getCommentComplaints,
  getTopicComplaints,
  deleteComplaint,
  deleteAllComplaintsByUserId,
  deleteAllComplaintsByCommentId,
  deleteAllComplaintsByTopicId,
};

const suffix = "Complaints";

function getUserComplaints(currentPage = 0, pageSize = 10, filterQuery = "") {
  return fetchWrapper.get(`${API_URL}/${suffix}/users?CurrentPage=${currentPage}&PageSize=${pageSize}${filterQuery}`);
}

function getCommentComplaints(currentPage = 0, pageSize = 10) {
  return fetchWrapper.get(`${API_URL}/${suffix}/comments?CurrentPage=${currentPage}&PageSize=${pageSize}`);
}

function getTopicComplaints(currentPage = 0, pageSize = 10) {
  return fetchWrapper.get(`${API_URL}/${suffix}/topic?CurrentPage=${currentPage}&PageSize=${pageSize}`);
}

function deleteComplaint(id) {
  return fetchWrapper.delete(`${API_URL}/${suffix}/${id}`);
}

function deleteAllComplaintsByUserId(id) {
  return fetchWrapper.delete(`${API_URL}/${suffix}/user-all/${id}`);
}

function deleteAllComplaintsByCommentId(id) {
  return fetchWrapper.delete(`${API_URL}/${suffix}/comment-all/${id}`);
}

function deleteAllComplaintsByTopicId(id) {
  return fetchWrapper.delete(`${API_URL}/${suffix}/topic-all/${id}`);
}

export default ComplaintsService;
