import SearchPage from "modules/Search/searchPage";
import UserPage from "modules/User/userPage";
import FavoritesPage from "modules/Favorites/favoritesPage";
import BlackListPage from "modules/Blacklist/blacklistPage";
import ChatPage from "modules/Chat/chatPage";
import SettingsPage from "modules/Settings/settingsPage";
import AdminPage from "modules/Admin/adminPage";

import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import StarIcon from "@material-ui/icons/Star";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import BlockIcon from "@material-ui/icons/Block";
import LaptopWindowsIcon from "@material-ui/icons/LaptopWindows";


export const adminRoutes = [
  {
    icon: SearchOutlinedIcon,
    path: "/search",
        name: "menu.search",
    component: SearchPage,
    isExact: true,
    data: {
      name: "Поиск",
    },
  },
  {
    path: "/user/:id",
    component: UserPage,
    data: {},
  },
  {
    icon: ChatBubbleOutlineIcon,
    path: "/chat",
      name: "menu.chat",

    component: ChatPage,
    data: {
      name: "Чат",
    },
  },
  {
    icon: StarIcon,
    path: "/favorites",
      name: "menu.favorites",
    component: FavoritesPage,
    isExact: true,
    data: {
      name: "Избранное",
    },
  },
  {
    icon: BlockIcon,
    path: "/blacklist",
      name: "menu.blackList",
    component: BlackListPage,
    isExact: true,
    data: {
      name: "Чёрный список",
    },
  },
  {
    icon: SettingsIcon,
    path: "/settings",
      name: "menu.settings",
    component: SettingsPage,
    isExact: true,
    data: {
      name: "Настройки",
    },
  },
  {
    icon: LaptopWindowsIcon,
      path: "/admin",
      name: "menu.admin",
    component: AdminPage,
    isExact: true,
    data: {
      name: "Админ",
    },
  },
];

export const userRoutes = [
  {
    icon: SearchOutlinedIcon,
    path: "/search",
        name: "menu.search",
    component: SearchPage,
    data: {
        name: "Поиск", 
    },
  },
  {
    path: "/user/:id",
    component: UserPage,
    data: {},
  },
  {
    icon: ChatBubbleOutlineIcon,
    path: "/chat",
      name: "menu.chat",
    component: ChatPage,
    data: {
      name: "Чат",
    },
  },
  {
    icon: StarIcon,
    path: "/favorites",
      name: "menu.favorites",
    component: FavoritesPage,
    data: {
      name: "Избранное",
    },
  },
  {
    icon: BlockIcon,
    path: "/blacklist",
      name: "menu.blackList",
    component: BlackListPage,
    data: {
      name: "Чёрный список",
    },
  },
  {
    icon: SettingsIcon,
    path: "/settings",
      name: "menu.settings",
    component: SettingsPage,
    data: {
      name: "Настройки",
    },
  },
];

export default {
  userRoutes,
  adminRoutes,
};

/*
{
    path: "/user/:id",
    component: UserPage,
    data: {}
  },
  */
