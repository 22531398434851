import i18n from "i18next";

export function getNums(from, to) {
  let nums = [];
  for (let i = from; i <= to; i++) {
    nums.push(i);
  }
  return nums;
}

export function getNowDate() {
  var date = new Date();
  var aaaa = date.getUTCFullYear();
  var gg = date.getUTCDate();
  var mm = date.getUTCMonth() + 1;

  if (gg < 10) gg = "0" + gg;
  if (mm < 10) mm = "0" + mm;

  var cur_day = gg + "-" + mm + "-" + aaaa;
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var seconds = date.getUTCSeconds();

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  return cur_day + " " + hours + ":" + minutes + ":" + seconds;
}

export function lastSeenDate(date) {
  let lastSeenYear = date.slice(0, 4);
  let lastSeenMonth = date.slice(5, 7);
  let lastSeenDay = date.slice(8, 10);

  let lastSeenHour = date.slice(11, 13);
  let lastSeenMinutes = date.slice(14, 16);

  let dateToShow = lastSeenDay + "-" + lastSeenMonth + "-" + lastSeenYear + " " + lastSeenHour + ":" + lastSeenMinutes;

    return `${i18n.t('favoritesPage.was')} ${dateToShow}`;
}

export function checkYesterday(lastSeenDate) {
  let dt = new Date(lastSeenDate);
  let a = new Date(dt.setDate(dt.getDate())).toString();
  let dateNow = new Date();
  let b = new Date(dateNow.setDate(dateNow.getDate() - 1)).toString();

  if (a.slice(4, 10) === b.slice(4, 10)) {
    return true;
  }
  return;
}
