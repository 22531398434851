import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const UserService = {
  getUsers,
  postUser,
  getUserById,
  deleteUserById,
  showUserInSearchGet,
  showUserInSearchChange,
  postUserLang,
};

function getUsers(params) {
  return fetchWrapper.get(`${API_URL}/User${params}`);
}

function postUser(userObj) {
  return fetchWrapper.post(`${API_URL}/User`, userObj);
}
function postUserLang(langObj) {
  return fetchWrapper.post(`${API_URL}/User/update-language`, langObj);
}

function getUserById(id) {
  return fetchWrapper.get(`${API_URL}/User/${id}`);
}

function deleteUserById(id) {
  return fetchWrapper.delete(`${API_URL}/User/${id}`);
}

function showUserInSearchGet() {
  return fetchWrapper.get(`${API_URL}/User/show-in-search`);
}

function showUserInSearchChange(isShow) {
  return fetchWrapper.post(`${API_URL}/User/show-in-search`, { ShowInSearch: isShow });
}
