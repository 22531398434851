import React, { useState } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardMedia, CardContent, Box, Typography, Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import noAvatar from "assets/noavatar.png";
import Button from "../Buttons/button";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import ItIcon from "assets/itIcon.png";

import { history } from "helpers/history";
import { FavoritesService } from "services/favorites-service";
import { BlackListService } from "services/blacklist-service";
import { lastSeenDate, checkYesterday } from "helpers/functions";
import { useTranslation } from 'react-i18next';

const FavoritesCard = ({ favoriteUser, favoriteUserId, blacklistUser, blackListedUserId, imagesPath }) => {
  const favoriteStyles = useFavoriteCardStyles();
  const blacklistStyles = useBlackListCardStyles();
  const shadowStyles = useOverShadowStyles();
  const history = useHistory();
    const { t, i18n } = useTranslation();

  const [hideCard, setHideCard] = useState(false);

  const yesterdayHHMM = favoriteUser ? favoriteUser.lastSeen.slice(11, 16) : blacklistUser.lastSeen.slice(11, 16);

  const redirectToProfile = () => history.push(favoriteUser ? `/user/${favoriteUser.id}` : `/user/${blacklistUser.id}`);

  function deleteUser() {
    favoriteUser
      ? FavoritesService.deleteFavorites(favoriteUserId)
      : BlackListService.deleteBlackList(blackListedUserId);
    setHideCard(true);
  }

  function startChat() {
    history.push(`/chat/${favoriteUserId}`);
  }

  return (
    <>
      {!hideCard && (
        <Card
          className={favoriteUser ? cx(favoriteStyles.root, shadowStyles.root) : blacklistStyles.card}
          onClick={redirectToProfile}
        >
          <CardMedia
            className={favoriteUser ? favoriteStyles.media : blacklistStyles.media}
            image={
              favoriteUser
                ? favoriteUser?.primaryImageUrl
                  ? `${imagesPath}/${favoriteUser.primaryImageUrl}`
                  : noAvatar
                : blacklistUser?.primaryImageUrl
                ? `${imagesPath}/${blacklistUser.primaryImageUrl}`
                : noAvatar
            }
          ></CardMedia>
          <CardContent className={favoriteUser ? favoriteStyles.content : blacklistStyles.content}>
            <Box
              mb={1}
              className={favoriteUser ? favoriteStyles.nameLastSeenContainer : blacklistStyles.nameLastSeenContainer}
            >
              <div className={favoriteUser ? favoriteStyles.nameContainer : blacklistStyles.nameContainer}>
                <Typography
                  className={favoriteUser ? favoriteStyles.heading : blacklistStyles.heading}
                  component="h2"
                  variant={favoriteUser ? "srOnly" : "inherit"}
                  gutterBottom
                >
                  {favoriteUser ? favoriteUser.fullName : blacklistUser.fullName}
                </Typography>
              </div>
              <div className={favoriteUser ? favoriteStyles.lastSeen : blacklistStyles.lastSeen}>
                {favoriteUser && favoriteUser.isOnline && (
                  <Typography className={favoriteStyles.online} variant="body2" gutterBottom align="center">
                    ONLINE
                  </Typography>
                )}
                {favoriteUser && !favoriteUser.isOnline && checkYesterday(favoriteUser.lastSeen) && (
                  <Typography className={favoriteStyles.lastSeen} variant="caption" gutterBottom align="center">
                    <span>{t("favoritesPage.wasYesterday")}{yesterdayHHMM}</span>
                  </Typography>
                )}
                {favoriteUser && !favoriteUser.isOnline && !checkYesterday(favoriteUser.lastSeen) && (
                  <Typography className={favoriteStyles.lastSeen} variant="caption" gutterBottom align="center">
                    {lastSeenDate(favoriteUser.lastSeen)}
                  </Typography>
                )}
                {blacklistUser && blacklistUser.isOnline && (
                  <Typography className={blacklistStyles.online} variant="body2" gutterBottom align="center">
                    ONLINE
                  </Typography>
                )}
                {blacklistUser && !blacklistUser.isOnline && checkYesterday(blacklistUser.lastSeen) && (
                  <Typography className={blacklistStyles.lastSeen} variant="caption" gutterBottom align="center">
                    <span>{t("favoritesPage.wasYesterday")}{yesterdayHHMM}</span>
                  </Typography>
                )}
                {blacklistUser && !blacklistUser.isOnline && !checkYesterday(blacklistUser.lastSeen) && (
                  <Typography className={blacklistStyles.lastSeen} variant="caption" gutterBottom align="center">
                    {lastSeenDate(blacklistUser.lastSeen)}
                  </Typography>
                )}
              </div>
            </Box>
            <Box mb={1}>
              <Typography
                className={favoriteUser ? favoriteStyles.title : blacklistStyles.title}
                variant="inherit"
                gutterBottom
              >
                {favoriteUser ? `${favoriteUser.age} лет, ` : `${blacklistUser.age} лет, `}
              </Typography>
              <Typography
                className={favoriteUser ? favoriteStyles.title : blacklistStyles.title}
                variant="inherit"
                gutterBottom
              >
                {favoriteUser
                  ? favoriteUser.genderId === 1
                                      ? t("favoritesPage.boy")
                                      : t("favoritesPage.girl")
                  : blacklistUser.genderId === 1
                                      ? t("favoritesPage.boy")
                                      : t("favoritesPage.girl") }
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography
                className={favoriteUser ? favoriteStyles.title : blacklistStyles.title}
                variant="inherit"
                gutterBottom
              >
                {favoriteUser
                  ? `${favoriteUser.cityName}, ${favoriteUser.countryName}`
                  : `${blacklistUser.cityName}, ${blacklistUser.countryName}`}
              </Typography>
            </Box>
            <div className={favoriteUser ? favoriteStyles.buttonsBottomLine : blacklistStyles.buttonsBottomLine}>
              {favoriteUser && (
                <div className={favoriteStyles.buttonContainer} onClick={(e) => e.stopPropagation()}>
                                  <Button title={t("buttons.sentMessage")} color={"primary"} onClick={startChat} />
                </div>
              )}
              <div
                className={favoriteUser ? favoriteStyles.buttonContainer : blacklistStyles.buttonContainer}
                onClick={(e) => e.stopPropagation()}
              >
                              <Button title={t("buttons.remove")} color={"secondary"} onClick={deleteUser} />
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

const useFavoriteCardStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: 0,
    borderRadius:"4", 
    transition: "0.3s",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.12)",
    position: "relative",
    width: "100%",
    height: 150,
    // marginLeft: spacing(4),
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    color: "rgb(49, 63, 157)",
    //paddingBottom: spacing(1), //8px
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(1),
    },
    [breakpoints.up("sm")]: {
      flexDirection: "row",
      paddingTop: spacing(1),
    },
    [breakpoints.up("xs")]: {
      flexDirection: "row",
      paddingTop: spacing(1),
    },
  },
  media: {
    width: "30%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing(-3),
    height: "0",
    // maxWidth: "30%",
    paddingBottom: "15%",
    borderRadius: "4px",
    backgroundColor: "#fff",
    position: "relative",
    [breakpoints.up("md")]: {
    //   width: "100%",
      marginLeft: spacing(-2.5),
      marginTop: spacing(-1),
    },
    [breakpoints.up("sm")]: {
    //   width: "100%",
      marginLeft: spacing(1.0),
      marginTop: spacing(-1),
    },
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      opacity: 0.5,
    },
  },
  content: {
    width: "100%",
    padding: spacing(0, 1, 0, 2.5),
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
  heading: {
    marginTop: "5px",
    marginBottom: "0px",
    fontSize: 17,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    display: "contents",
  },
  nameLastSeenContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    marginTop: "3px",
    marginBottom: "10px",
    paddingRight: "10px",
  },
  lastSeen: {
    color: palette.grey[500],
    marginRight: "5px",
  },
  buttonsBottomLine: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  badge: {
    marginLeft: "96%",
    marginBottom: "15px",
  },
  itIcon: {
    marginBottom: "15px",
    width: spacing(2),
    height: spacing(2),
  },
  nameContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
  online: {
    marginRight: "10px",
    color: "mediumseagreen",
    marginBottom: "0px",
  },
}));

const useBlackListCardStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    marginBottom: "10px",
    marginLeft: 0,
    display: "flex",
    padding: spacing(1),
    borderRadius: "4px",
    margin: "0",
    height: "150px",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.12)",
    // width: "700px",
    "&:hover": {
      cursor: "pointer",
      transition: "0.3s",
      transform: "translateY(3px)",
    },
  },
  media: {
    marginLeft: "2px",
    minWidth: "22%",
    maxWidth: "22%",
    flexShrink: 0,
    borderRadius: "4px",
  },
  content: {
    alignItems: "baseline",
    width: "100%",
    padding: "0px 0px 10px 16px",
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
  divider: {
    width: "100%",
  },
  heading: {
    marginTop: "10px",
    marginBottom: "0px",
    fontSize: 17,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    display: "inline-block",
  },
  nameLastSeenContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    marginTop: "3px",
    marginBottom: "10px",
    paddingRight: "10px",
  },
  lastSeen: {
    color: palette.grey[500],
    marginRight: "5px",
  },
  online: {
    marginRight: "10px",
    color: "mediumseagreen",
    marginBottom: "0px",
  },
  buttonsBottomLine: {
    display: "flex",
      justifyContent: "flex-end",
      "@media (min-width: 375px) and (max-width: 599px)": {
          justifyContent: "flex-start",
      },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginTop: "-45px", //difference
    "@media (min-width: 375px) and (max-width: 599px)": {
        marginTop: 0, 
    },
  },
  itIcon: {
    marginBottom: "15px",
    width: spacing(2),
    height: spacing(2),
  },
  nameContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
  badge: {
    marginLeft: "96%",
    marginBottom: "15px",
  },
}));

export default FavoritesCard;
