import Validator from "validator";

export default function validateChangeEmail(values) {
  let errors = {};

  if (!values.email?.trim()) {
    errors.email = "Введите почту";
  } else if (!Validator.isEmail(values.email)) {
    errors.email = "Не корректный адрес почты";
  }

  if (!values.newEmail) {
    errors.newEmail = "Введите почту";
  } else if (!Validator.isEmail(values.newEmail)) {
    errors.newEmail = "Не корректный адрес почты";
  } else if (values.email === values.newEmail) {
    errors.newEmail = "Новый адрес не отличается от предыдущего";
  }

  return errors;
}
