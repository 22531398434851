import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";
import { AuthService } from "services/auth-service";

export const ImagesService = {
  addImage,
  deleteImage,
  setPrimary,
};

function addImage(image) {
  const url = `${API_URL}/Images/`;
  const user = AuthService.userValue;

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user.accessToken}`);

  const formdata = new FormData();
  formdata.append("images", image);

  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(url, requestOptions)
    .then((response) => response.text())
    .then(() => window.location.reload())
    .catch((error) => console.log("error", error));

  //.then(fetchWrapper.handleResponse)
}

function deleteImage(id) {
  return fetchWrapper.delete(`${API_URL}/Images/${id}`);
}

function setPrimary(userId, imageId) {
  return fetchWrapper.post(`${API_URL}/Images/set-primary`, { userId: userId, imageId: imageId });
}
