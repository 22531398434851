import React from "react";
// import './index.scss';
import "./index.css";

const GorizontalLine = ({ children = null, text = null }) => {
  return (
    <div className="gorizontal-line">
      <span className="gorizontal-line__title">{text}</span>
      <span className="gorizontal-line__icon">{children}</span>
    </div>
  );
};

export default GorizontalLine;
