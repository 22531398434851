import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const BasicPagination = ({ page, setPage, searchPage, usersAmount, onClick, pageSize, mini }) => {
  const classes = useStyles();

  function getPagesAmount() {
    let result = Math.ceil(usersAmount / pageSize);
    return result;
  }

  const changeSearchPagePagination = (event, value) => {
    onClick(value - 1);
  };

  const changePagination = (event, value) => {
    setPage(value);
  };

  return (
    <div className={classes.root}>
      <Pagination
        classes={{ ul: classes.ul }}
        page={page}
        count={getPagesAmount()}
        onChange={searchPage ? changeSearchPagePagination : changePagination}
        variant="outlined"
        shape="rounded"
        color={mini ? "primary" : "secondary"}
        size={mini ? "small" : "large"}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(126, 76, 203, 0.3)",
      "&:hover": {
        backgroundColor: "rgba(126, 76, 203, 0.4)",
      },
      borderColor: "rgb(126, 76, 203)",
      color: "rgb(126, 76, 203)",
    },
  },
  ul: {
    "& .MuiPaginationItem-root": {
      //color: rgba(126, 76, 203, 0.8)
    },
    "& .MuiPaginationItem-icon": {
      color: "rgb(126, 76, 203)",
    },
  },
}));

export default BasicPagination;
