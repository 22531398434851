import { makeStyles } from "@material-ui/core";
import { StarBorder, Delete, MoreVert } from "@material-ui/icons";
import React from "react";
import { getFullBlobUrl } from "helpers/blobHelpers";
import { getMessageDate } from "helpers/dateHelpers";
import { useTranslation } from 'react-i18next';

export default function ChatPaneHeader({ activeRecipient, openActiveRecipientProfile }) {
  const styles = useStyles();
    const { t, i18n } = useTranslation();

  const onStar = () => {
    console.log("Star button pressed");
  };

  const onDelete = () => {
    console.log("Delete button pressed");
  };

  const onMore = () => {
    console.log("More button pressed");
  };

  const onProfileImageClick = () => {
    openActiveRecipientProfile();
  };

  return (
    <>
      {activeRecipient && (
        <div className={styles.chatPaneHeaderContainer}>
          <div className={styles.userSection}>
            <div className={styles.imageWrapper} onClick={onProfileImageClick}>
              <img src={getFullBlobUrl(activeRecipient.primaryImage)} alt={"Your recipient profile image"}></img>
              {activeRecipient.isOnline && <div className={styles.onlineIndicator}></div>}
            </div>
            <div className={styles.userInfo}>
              <p className={[styles.userName, "chat-list-item-username"].join(" ")}>{activeRecipient.partnerName}</p>
              {!activeRecipient.isOnline && (
                              <p className={styles.latestMessage}>{`${t("chat.lastOnline")} ${getMessageDate(activeRecipient.lastSeen)}`}</p>
              )}
            </div>
          </div>
          <div className={styles.controlsSection}>
            <button onClick={onStar}>
              <StarBorder></StarBorder>
            </button>
            <button onClick={onDelete}>
              <Delete></Delete>
            </button>
            <button onClick={onMore}>
              <MoreVert></MoreVert>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  chatPaneHeaderContainer: {
    height: "3rem",
    padding: "0.75rem 1rem 0.5rem 1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    borderBottom: "1px solid #eeeeee",
    marginBottom: "1px",
  },
  userSection: {
    flexGrow: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  imageWrapper: {
    position: "relative",
    width: "3rem",
    height: "3rem",
    marginRight: "0.3rem",
    "& > img": {
      borderRadius: "50%",
      width: "3rem",
      height: "3rem",
      display: "block",
      fontSize: "0.5rem",
      objectFit: "cover",
    },
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "60%",
  },
  userName: {
    padding: "0.1rem",
    margin: "0.1rem",
    color: "#263238",
    fontWeight: 700,
    // fontSize: '0.9rem',
  },
  latestMessage: {
    padding: "0.1rem",
    margin: "0.1rem",
    color: "#616161",
    fontSize: "0.75rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  controlsSection: {
    "& button": {
      color: "#9e9e9e",
      backgroundColor: "#fff",
      border: "none",
      marginLeft: "0.2rem",
      cursor: "pointer",
    },
    "& button:focus": {
      outline: "none",
    },
  },
  onlineIndicator: {
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    backgroundColor: "limegreen",
    position: "absolute",
    right: "0",
    bottom: "0",
  },
}));
