import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import { BlackListService } from "services/blacklist-service";
import { useTranslation } from 'react-i18next';

import BlacklistCard from "components/ui/Cards/FavouriteAndBlacklistCard";
import Pagination from "components/ui/Pagination/pagination";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export default function BlacklistPage({ imagesPath }) {
  const styles = useStyles();

  const [blackList, setBlackList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const pageSize = 20;
    const { t, i18n } = useTranslation();

  const getBlackList = () => {
    setIsLoading(true);
    BlackListService.getBlackList(page - 1, pageSize)
      .then((res) => {
        setBlackList(res);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBlackList();
  }, [page]);

  return (
    <Container className={styles.page}>
      {!isLoading && (
        <Container className={styles.content}>
          {blackList?.results?.map((item, index) => {
            return (
              <BlacklistCard
                blacklistUser={item.blackListedUser}
                blackListedUserId={item.blackListedUserId}
                key={index}
                imagesPath={imagesPath}
              />
            );
          })}
          {blackList.resultsCount > 0 && (
            <div className={styles.paginationContainer}>
              <Pagination
                usersAmount={blackList.resultsCount}
                onClick={getBlackList}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
              />
            </div>
          )}
          {blackList.resultsCount === 0 && (
            <div className={styles.emptyListContainer}>
              <div className={styles.iconContainer}>
                <InfoIcon className={styles.icon} />
              </div>
              <Typography variant={"h4"}>{t("favoritesPage.emptyList")}</Typography>
            </div>
          )}
        </Container>
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  page: {
    padding: 0,
    margin: 0,
  },
  content: {
    margin: "20px 0 0",
    padding: 0,
  },
  paginationContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
  },
  emptyListContainer: {
    position: "absolute",
    top: "40%",
    left: "43%",
  },
  iconContainer: {
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    fontSize: "60px",
  },
}));

/*
<Arrow
            leftPress={() => setPage(page - 1)}
            rightPress={() => setPage(page + 1)} />


             <Pagination
           size='large'
           page={page + 1}
         //paginationHandleChange={paginationHandleChange}
         />
            */
