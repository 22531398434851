import { makeStyles, Hidden } from "@material-ui/core";
import React from "react";
import ChatListItem from "./chatListItem/chatListItem";
import ChatSearch from "./chatSearch/chatSearch";
import ChatSidebarHeader from "./chatSidebarHeader/chatSidebarHeader";

export default function ChatSidebar({
  currentUser,
  chatList,
  activeRecipientId,
  chatListIsLoading,
  activateChat,
  searchChats,
  addUser,
  openSenderProfile,
}) {
  const styles = useStyles();

  return (
    <div className={styles.sidebarContainer}>
      <Hidden smDown>
        <ChatSidebarHeader openSenderProfile={openSenderProfile} currentUser={currentUser}></ChatSidebarHeader>
      </Hidden>
      <ChatSearch addUser={addUser} searchChats={searchChats}></ChatSearch>

      {chatListIsLoading && <div className={styles.chatsListLoading}>Loading...</div>}
      {!chatListIsLoading && (
        <div className={styles.chatsList}>
          {chatList.map((chatItem) => (
            <ChatListItem
              isActive={chatItem.partnerId == activeRecipientId}
              key={chatItem.partnerId}
              chatItem={chatItem}
              activateChat={activateChat}
            ></ChatListItem>
          ))}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  sidebarContainer: {
    borderRight: "1px solid #eeeeee",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chatsList: {
    overflowY: "scroll",
  },
  chatsListLoading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
