import React, { useState, useRef, useEffect } from "react";
import { CitiesService } from "services/cities-service";
import { useTranslation } from 'react-i18next';

import "./styles.css";


export default function CountriesSelect({

    inputChooseItemInListClick,
    topCountries,
    inputCountriesValue,
    searchedCountries,
    changeInputCountriesValue,
    visibleCountryList,
    setVisibleCountryList,
    changeInputCountryCitiesValue,
    setPickedCountry,
    pickedCountry,
    userPage }) {
    const { t, i18n } = useTranslation();

    const [placeholder, setPlaceholder] = useState(t("userPage.country"));
    const dropdownRef = useRef(null);
    const inputCountryRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);

    useEffect(() => {
        if (userPage) {
            return;
        }

        let isFetching = true;

        if (localStorage.getItem("lastSearchCity")) {
            const getData = async () => {
                const lastSearch = JSON.parse(localStorage.getItem("lastSearchCity"));
                if (lastSearch.countryId) {
                    const country = await CitiesService.getCountryById(lastSearch.countryId);
                    if (isFetching) {
                        changeInputCountriesValue(country.name);
                        setPickedCountry(country)
                    }
                }
                if (lastSearch.cityId) {
                    const city = await CitiesService.getCityById(lastSearch.cityId);
                    if (isFetching) {
                        changeInputCountryCitiesValue(city.name);
                    }
                }
            }
            getData();
        };

        return () => {
            isFetching = false;
        };
    }, []);


    const handleClick = e => {
        if (dropdownRef.current.contains(e.target)) {
            return;
        }
        setVisibleCountryList(false);
    };

    function clear() {
        changeInputCountriesValue('');
        inputCountryRef.current.focus();
    }

    const selectItem = item => {
        inputChooseItemInListClick(item)
        setVisibleCountryList(false);
        // setSearchedCountries([])
    };

    function onInputFocus() {
        setPlaceholder(" ")
        setVisibleCountryList(true);
    }
    function onInputBlur() {
        setTimeout(() => {
            setPlaceholder(t("userPage.country"))
        }, 100);
    }

    return (
        <div ref={dropdownRef} className="container">
            <div tabIndex="0" className="input_container line">
                {!userPage && <label className={`city ${visibleCountryList ? 'v' : ''} ${inputCountriesValue ? 'a' : ''}`}>{t("userPage.country")}</label>}
                <div className="input-wrapper">
                    <input
                        ref={inputCountryRef}
                        className="input"
                        type="text"
                        placeholder={placeholder}
                        value={inputCountriesValue}
                        onChange={(e) => changeInputCountriesValue(e.target.value)}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                    />
                    {inputCountriesValue && <div className="clearIcon" onClick={() => clear()}></div>}
                </div>
            </div>
            <div className={`dropdown ${visibleCountryList ? 'v' : ''}`}>
                {visibleCountryList && (

                    <ul className="list">
                        {searchedCountries.length > 0 &&
                            searchedCountries.map(x => (
                                <li
                                    key={x.id}
                                    onClick={() => selectItem(x)}
                                    className="dropdown_item"
                                >
                                    <div className="item_text">{x.name}</div>
                                </li>
                            ))}
                        {/* you can remove the searchFilter if you get results from Filtered API like Google search */}
                        {searchedCountries.length === 0 && topCountries.length > 0 &&
                            topCountries.map(x => (
                                <li
                                    key={x.id}
                                    onClick={() => selectItem(x)}
                                    className="dropdown_item"

                                >
                                    <div className="item_text">{x.name}</div>
                                </li>
                            ))}
                    </ul>

                )}
            </div>

        </div>
    );
}


