import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, InputLabel, MenuItem, FormControl, Select, Checkbox } from "@material-ui/core";

const MultipleSelect = ({ setValue, lookups, type, value, title, searchPage }) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        {title && <InputLabel>{title}</InputLabel>}
        <Select
          multiple
          className={classes.select}
          value={value ? value : []}
          onChange={(e) => setValue(type, e.target.value)}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          classes={{ select: classes.selectCl }}
        >
          {lookups &&
            lookups.map((item, index) => (
              <MenuItem className={classes.menuItem} key={index} value={item.name} divider={true} dense={true}>
                <Checkbox checked={value?.indexOf(item.name) > -1} />
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    padding: 0,
    // width: '190px',
  },
  menuItem: {
    padding: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
};

export default MultipleSelect;
