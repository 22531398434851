import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, FormControl, CardHeader } from "@material-ui/core";

import queryString from "query-string";
import { AccountService } from "services/account-service";

function ResetPassword({ history, classes }) {
  const [token, setToken] = useState("");

  useEffect(() => {
    let token = queryString.parse(window.location.search);
    setToken(token.code);
  }, []);

  useEffect(() => {
    if (token.length) {
      AccountService.resetPassword(token);
    }
  }, [token]);

  function onSubmit(e) {
    e.preventDefault();
    history.push("/login");
  }

  return (
    <form className={classes.form}>
      <CardHeader className={classes.formHeader} subheader="Новый пароль отправлен вам на почту" />

      <FormControl fullWidth>
        <Button
          classes={{ root: classes.enterButtonRoot, label: classes.enterButtonLabel }}
          fullWidth
          variant={"contained"}
          type="submit"
          onClick={(e) => onSubmit(e)}
        >
          На страницу входа
        </Button>
      </FormControl>
    </form>
  );
}

const styles = ({ breakpoints }) => {
  const backDropBG = "rgba(255,255,255,0.1)";
  return {
    form: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      background: "rgba(255,255,255,0.8)",
      borderRadius: "6px",
    },
    formHeader: {
      color: "#B53E85",
      padding: 14,
      paddingBottom: 10,
      marginLeft: "10px",
    },
    inputsContainer: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    input: {
      "&:not(.Mui-disabled):hover::before": {
        borderBottomWidth: "1px",
      },
    },
    inputInput: {
      height: 20,
    },
    underline: {
      "&:hover": {
        borderBottomWidth: "1px",
      },
    },
    checkboxRoot: {
      marginLeft: 1,
      marginTop: 10,
    },
    checkboxLabel: {
      fontSize: 14,
      color: "rgba(0,0,0,0.87)",
    },
    // BUTTONS
    enterButtonRoot: {
      width: "80%",
      marginTop: 15,
      marginBottom: 5,
      borderRadius: 10,
      background: "linear-gradient(45deg,#8e24aa,#ff6e40) !important",
      alignSelf: "center",
    },
    enterButtonLabel: {
      color: "white",
      fontSize: 14,
      fontWeight: 500,
      textTransform: "none",
    },
    socialButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    socialButton: {
      width: "80% !important",
      fontSize: "13px !important",
      height: "35px !important",
      display: "flex !important",
      flexDirection: "row !important",
      justifyContent: "center !important",
    },
    bottomSignUp: {
      textAlign: "center",
      marginTop: 24,
      marginBottom: 16,
    },
    forgetPassword: {
      marginTop: "20px",
      marginBottom: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#36a0e2",
      borderBottom: "4px",
      marginLeft: "20px",
      marginRight: "20px",
      [breakpoints.only("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
      },
    },
    bottomLink: {
      [breakpoints.only("sm")]: {
        fontSize: "16px",
      },
    },
  };
};

export default withStyles(styles)(ResetPassword);

/*
  {alert &&
      <Alert alertShow={() => setAlert(true)} closeAlert={() => setAlert(false)}
        text={'Ваш пароль успешно изменен. Вы будете перенаправлены на страницу входа.'} type={'success'}
      />}
      */
