import { useState, useEffect } from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (isSubmitting) {
      setErrors(validate(values, isSubmitting));
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  useEffect(() => {
    if (values && Object.keys(values).length) {
      setErrors(validate(values));
      setIsSubmitting(false);
    }
  }, [values]);

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};

export default useForm;
