import React from "react";
import { Modal, Typography, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const styleButtons = {
  margin: "30px 30px 0 30px",
};

const StopRegistrationModals = ({
  openRegistration,
  setOpenRegistration,
  openAskModal,
  setOpenAskModal,
  handleSubmit,
}) => {
  return (
    <>
      <Modal open={openAskModal} onClose={() => setOpenRegistration(false)} aria-labelledby="modal-modal-title">
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Вы уверены?
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleSubmit} style={styleButtons}>
            Да
          </Button>
          <Button variant="contained" color="primary" onClick={() => setOpenAskModal(false)} style={styleButtons}>
            Нет
          </Button>
        </Box>
      </Modal>
      <Modal open={openRegistration} onClose={() => setOpenRegistration(false)} aria-labelledby="modal-modal-title">
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Статус регистрации успешно изменён
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenRegistration(false)}
            style={{ marginTop: "30px" }}
          >
            Закрыть
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default StopRegistrationModals;
