import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography, Button, FormControl, FormHelperText, TextField, CardHeader, Modal } from "@material-ui/core";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

import useForm from "hooks/useForm";
import validateLogin from "validations/login";
import { AuthService } from "services/auth-service";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from 'react-i18next';
import GenderPicker from "./components/genderPickerModal";

const FACEBOOK = "facebook";
const GOOGLE = "google";

const LoginPage = ({ history, location, currentUser }) => {
  const classes = useStyles();
  const { handleChange, handleSubmit, values, errors } = useForm(postLogin, validateLogin);

  const [isError, setIsError] = useState(false);
  const [isMale, setIsMale] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socialLoginState, setSocialLoginState] = useState(null);
    const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!socialLoginState) {
      return;
    }

    openModal();
  }, [socialLoginState]);

  function postLogin() {
    const { email, password } = values;
    AuthService.login(email, password)
      .then(setUserTokensAndRedirect)
      .catch((error) => {
        setIsError(true);
      });
  }

  const handleGoogleLoginCallback = (googleData) => {
    console.log("handling google data with response: ", googleData);
    if (googleData && googleData.accessToken) {
      AuthService.loginWithGoogle({ code: googleData.accessToken }).then((user) => {
        if (user && user.accessToken) {
          setUserTokensAndRedirect(user);
          return;
        }
        setSocialLoginState({ code: googleData.accessToken, network: GOOGLE });
      });
    }
  };

  const loginWithFacebook = (event) => {
    AuthService.getFacebookKey().then((fbKeyRes) => {
      window.FB.init({
        appId: fbKeyRes.facebookAppId,
        cookie: true,
        xfbml: true,
        version: "v11.0",
      });

      window.FB.getLoginStatus(function (response) {
        console.log("FB RESPONSE", response);
        if (response.status === "connected") {
          if (response && response.authResponse && response.authResponse.accessToken) {
            AuthService.loginWithFacebook({ code: response.authResponse.accessToken }).then((user) => {
              if (user && user.accessToken) {
                setUserTokensAndRedirect(user);
                return;
              }
              setSocialLoginState({ code: response.authResponse.accessToken, network: FACEBOOK });
            });
          }
        } else {
          window.FB.login(
            function (response) {
              if (response && response.authResponse && response.authResponse.accessToken) {
                AuthService.loginWithFacebook({ code: response.authResponse.accessToken }).then((user) => {
                  if (user && user.accessToken) {
                    setUserTokensAndRedirect(user);
                    return;
                  }
                  setSocialLoginState({ code: response.authResponse.accessToken, network: FACEBOOK });
                });
              }
            },
            { scope: "public_profile,email" }
          );
        }
      });
    });
  };

  const loginWithGoogle = (event) => {
    AuthService.getGoogleKey().then((googleKeyRes) => {
      window.gapi.load("auth2", function () {
        window.auth2 = window.gapi.auth2.init({
          client_id: googleKeyRes.googleClientId,
        });

        window.auth2.grantOfflineAccess().then((googleData) => {
          console.log("handling google data with response: ", googleData);
          if (googleData && googleData.code) {
            AuthService.loginWithGoogle({ code: googleData.code }).then((user) => {
              if (user && user.accessToken) {
                setUserTokensAndRedirect(user);
                return;
              }
              setSocialLoginState({ code: googleData.code, network: GOOGLE });
            });
          }
        });
      });
    });
  };

  const setUserTokensAndRedirect = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    AuthService.userSubject.next(user);

    const from =
      location && location.state && location.state.from
        ? location.state.from
        : { pathname: `/user/${currentUser?.id}` };
    history.push(from);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleModalClick = (isMale) => {
    console.log("modal clixked with isMale", true);
    const code = socialLoginState.code;
    if (socialLoginState.network === GOOGLE) {
      AuthService.loginWithGoogle({ code, isMale }).then((user) => {
        if (user && user.accessToken) {
          setUserTokensAndRedirect(user);
          return;
        }
      });
    } else if (socialLoginState.network === FACEBOOK) {
      AuthService.loginWithFacebook({ code, isMale }).then((user) => {
        if (user && user.accessToken) {
          setUserTokensAndRedirect(user);
          return;
        }
      });
    }
  };

  const handleChangeWrapper = (e) => {
    if (isError) {
      setIsError(false);
    }
    handleChange(e);
  };

  return (
    <div className={classes.formContainer}>
      <FormControl fullWidth>
        <div className={classes.socialButtonContainer}>
          <FacebookLoginButton onClick={loginWithFacebook} type="button" className={classes.socialButton}>
                      <span>{t("auth.login.Facebook")}</span>
          </FacebookLoginButton>
        </div>
      </FormControl>

      <FormControl>
        <div className={classes.socialButtonContainer}>
          <GoogleLoginButton onClick={loginWithGoogle} type="button" className={classes.socialButton}>
                      <span>{t("auth.login.Google")}</span>
          </GoogleLoginButton>
        </div>
      </FormControl>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {<GenderPicker handleGenderClick={handleModalClick}></GenderPicker>}
      </Modal>

      <div className={classes.divider}>
        <div className={classes.dividerLine}></div>
              <div className={classes.dividerText}> {t("auth.login.or")} </div>
        <div className={classes.dividerLine}></div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={classes.inputsContainer}>
          <div className={classes.textFieldContainer}>
            <MailOutlinedIcon className={classes.icon} color={tabIndex === 0 ? "secondary" : "primary"} />
            <TextField
              label="Email"
              name="email"
              type="email"
              color={tabIndex === 0 ? "secondary" : "primary"}
              className={classes.textField}
              value={values.email || ""}
              onChange={handleChangeWrapper}
              InputProps={{
                classes: {
                  root: classes.input,
                  focused: classes.focused,
                  underline: classes.underline,
                  input: classes.inputInput,
                },
              }}
              error={errors.email ? true : false}
            />
          </div>
          {errors.email && (
            <FormHelperText className={classes.errorText} error={errors.email ? true : false}>
              {errors.email}
            </FormHelperText>
          )}

          <div className={classes.textFieldContainer}>
            <LockOutlinedIcon className={classes.icon} color={tabIndex === 0 ? "secondary" : "primary"} />
            <TextField
              label={t("auth.login.password")}
              name="password"
              type="password"
              color={tabIndex === 0 ? "secondary" : "primary"}
              className={classes.textField}
              value={values.password || ""}
              onChange={handleChangeWrapper}
              error={errors.password ? true : false}
              InputProps={{
                classes: {
                  root: classes.input,
                  focused: classes.focused,
                  underline: classes.underline,
                  input: classes.inputInput,
                },
              }}
            />
          </div>
          {errors.password && (
            <FormHelperText className={classes.errorText} error={errors.password ? true : false}>
              {errors.password}
            </FormHelperText>
          )}
        </div>

        <FormControl fullWidth>
          <div className={classes.socialButtonContainer}>
            <Button
              fullWidth
              variant={"contained"}
              type="submit"
              classes={{
                root: classes.enterButtonRoot,
                label: classes.enterButtonLabel,
              }}
            >
                          {t("buttons.logIn")}
            </Button>
          </div>
                  {isError && <div className={classes.socialButtonError}>{t("auth.login.errorEmailPass")}</div>}
        </FormControl>
      </form>

      <div className={classes.forgetPassword}>
        <Typography variant="h6" className={classes.bottomLink}>
          <Link href={"/forgot-password"} className={classes.bottomLinkLink} underline={"none"}>
                      {t("auth.login.forgotPassword")}
          </Link>
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgba(255,255,255,0.8)",
    borderRadius: "6px",
    padding: "24px 12% 12px 12%",
  },
  input: {
    "&:not(.Mui-disabled):hover::before": {
      borderBottomWidth: "1px",
    },
  },
  inputInput: {
    height: 20,
  },
  underline: {
    "&:hover": {
      borderBottomWidth: "1px",
    },
  },
  // BUTTONS
  enterButtonRoot: {
    marginTop: 15,
    borderRadius: 2,
    background: "linear-gradient(45deg,#8e24aa,#ff6e40) !important",
    alignSelf: "center",
    padding: 4,
  },
  enterButtonLabel: {
    color: "white",
    fontSize: 18,
    textTransform: "none",
  },
  socialButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "12px",
  },
  socialButton: {
    width: "100% !important",
    margin: "0 !important",
    fontSize: "13px !important",
    height: "35px !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
  },
  forgetPassword: {
    color: "#36a0e2",
  },
  bottomLink: {
    [theme.breakpoints.only("sm")]: {
      fontSize: "16px",
    },
  },
  bottomLinkLink: {
    color: "inherit",
    fontSize: 14,
  },
  icon: {
    fontSize: 28,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  textFieldContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "100%",
    paddingTop: "5px",
    "&:last-child": {
      paddingBottom: "15px",
    },
  },
  textField: {
    width: "90%",
    marginLeft: "auto",
  },
  errorText: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  dividerLine: {
    flexGrow: "99",
    height: "1.25rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
  },
  dividerText: {
    color: "rgba(0, 0, 0, 0.25)",
    margin: "0.5rem 1rem 0 1rem",
  },
  socialButtonError: {
    color: "red",
    textAlign: "center",
    margin: "4px auto",
  },
}));

export default LoginPage;
