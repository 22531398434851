import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { UserService } from "services/user-service";
import { AuthService } from "services/auth-service";
import { useTranslation } from 'react-i18next';

const ChangeLang = ({ currentUser }) => {
    const classes = useStyles();
    const [selectedLanguage, setSelectedLanguage] = React.useState("");
    const { t, i18n } = useTranslation();
    const user = AuthService.userValue;

    const handleLanguageChange = async (event) => {
        i18n.changeLanguage(event.target.value)
        console.log("handleLanguageChange ~ event.target.value:", event.target.value)
        setSelectedLanguage(event.target.value);
        localStorage.setItem("i18nextLng", event.target.value);
        let num = 1;
        switch (event.target.value) {
            case "en":
                num = 1
                break;
            case "ua":
                num = 2
                break;
            case "ru":
                num = 3
                break;

            default:
                break;
        }
        console.log("handleLanguageChange ~ user.refreshToken:", user.refreshToken)
        const langSettings = await UserService.postUserLang({
            "languageId": num
        })
        const token = await AuthService.refreshToken(user.refreshToken)
        localStorage.setItem("user", JSON.stringify(token));
        console.log("handleLanguageChange ~ token:", token)
        // console.log("handleLanguageChange ~ langSettings:", )
        console.log("handleLanguageChange ~ user.accessToken:", user)
    };

    React.useEffect(() => {
        const storedLanguage = localStorage.getItem("i18nextLng");
        if (storedLanguage) {
            setSelectedLanguage(storedLanguage);
        }
    }, []);

    return (

            <div className={classes.container}>
                <FormControl component="fieldset" className={classes.inputContainer}>
                    <FormLabel component="legend">{t("settingsPage.selectLang")}</FormLabel>
                    <RadioGroup
                        aria-label="language"
                        name="language"
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className={classes.radioWrapper}
                    >
		            	<FormControlLabel value="ua" control={<Radio />} label="Українська" />
                        <FormControlLabel value="en" control={<Radio />} label="English" />
                        <FormControlLabel value="ru" control={<Radio />} label="Русский" />
                    </RadioGroup>
                </FormControl>
            </div>

    );
};



const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    // flexDirection: "column",
  },
  inputContainer: {
    width: "100%",
    marginTop: "20px",
  },
    radioWrapper: {
        display: "flex",
        justifyContent: 'space-between',
    flexDirection: "row",

    // width: "250px",
    marginTop: "20px",
  },


}));

export default ChangeLang;
