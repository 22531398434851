import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Divider } from "@material-ui/core";
import { formatDate } from "helpers/dateHelpers";
import { UserService } from "services/user-service";
import SearchForm from "./searchContainer/searchForm";
import UserCard from "../../components/ui/Cards/userCard";
import Pagination from "components/ui/Pagination/pagination";
import { useLocation, useHistory } from "react-router-dom";
import usePaginate from "hooks/usePaginate";

const SearchPage = ({ lookups, imagesPath, currentUser }) => {
  const classes = useStyles();
  const history = useHistory();
  const { page: currentPage } = usePaginate();
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(0);
  const [searchValues, setSearchValues] = useState([]);
  const pageSize = 20;
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ]);
  useEffect(() => {
    const firstSearchUrl = `?CurrentPage=${(currentPage && currentPage - 1) || 0}&PageSize=20`;
    let isFetching = true;

    UserService.getUsers(firstSearchUrl).then((res) => {
        if (isFetching) { setSearchResult(res); }
    });
      setPage(currentPage || 1);
      return () => {
          isFetching = false;
      }
  }, [lookups, currentPage]);

  const selectCurrentValue = React.useCallback(
    (type, value) => {
      if (type === "RegistrationDateTo" && value?.length) {
        setSearchValues((searchValues) => ({ ...searchValues, "RegistrationDateFrom": formatDate(new Date()) }));
      }
      // !!! когда убрал дату поиска недавнозареганых не убирается currentDate проверка на отсутствие длины нужна

      setSearchValues((searchValues) => ({ ...searchValues, [type]: value }));
    },
    [setSearchValues]
  );

  const onSearchClick = (pageValue) => {
    const currentPage = pageValue + 1;

    queryParams.set('page', currentPage);
    history.push({ search: `?${queryParams.toString()}` });
    
    setSearchResult([]);
    let requestParams = "";
    if (!searchValues.RegistrationDateTo) {
      if (searchValues.RegistrationDateFrom) {
        delete searchValues.RegistrationDateFrom;
      }
    }
    let searchedValues = Object.entries(searchValues);
      console.log("onSearchClick ~ searchValues:", searchValues);
      localStorage.setItem("lastSearchCity", JSON.stringify(searchValues));

    searchedValues = searchedValues.filter((item) => item[1]).map((item) => item.toString().replace(",", "="));
    requestParams = "?";
    if (searchedValues.length) {
      for (let i = 0; i < searchedValues.length; i++) {
        requestParams += `${searchedValues[i]}&`;
      }
    }
    requestParams += `CurrentPage=${pageValue}&PageSize=${pageSize}`;
    setPage(currentPage);
    UserService.getUsers(requestParams).then((res) => {
      setSearchResult(res);
    });
    return;
  };

  return (
    <Container className={classes.container}>
      <SearchForm
        currentUser={currentUser}
        lookups={lookups}
        searchValues={searchValues}
        onSearchClick={onSearchClick}
        selectCurrentValue={selectCurrentValue}
        pageSize={pageSize}
      />

      <div className={classes.usersCardsContainer}>
        {searchResult.results &&
          searchResult.results.map((item, index) => (
            <Grid className={classes.cardContainer} key={index}>
              <UserCard user={item} imagesPath={imagesPath} />
            </Grid>
          ))}
      </div>
      <Divider variant="fullWidth" />
      <div className={classes.paginationContainer}>
        {searchResult.results && (
          <Pagination
            pageSize={pageSize}
            searchPage={true}
            onClick={onSearchClick}
            page={page}
            setPage={setPage}
            usersAmount={searchResult.resultsCount}
          />
        )}
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  usersCardsContainer: {
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    [theme.breakpoints.only("xmd")]: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
    },
    [theme.breakpoints.only("md")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
    },
    [theme.breakpoints.only("sm")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
    },
    [theme.breakpoints.only("smd")]: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
    },
    [theme.breakpoints.only("xs")]: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
    },
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    [theme.breakpoints.only("sm")]: {
      paddingTop: "10px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.only("xs")]: {
      paddingTop: "10px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingBottom: "10px",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default SearchPage;
