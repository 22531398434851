import React from "react";
import { history } from "helpers/history";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardMedia, CardContent, Avatar, Button } from "@material-ui/core";
import ItIcon from "assets/itIcon.png";
import noAvatar from "assets/noavatar.png";
import { getMessageDate } from "helpers/dateHelpers";
import { lastSeenDate, checkYesterday } from "helpers/functions";
import { useTranslation } from 'react-i18next';


export default function UserCard({ user, imagesPath }) {
    const { t, i18n } = useTranslation();
  const yesterdayHHMM = user.lastSeen.slice(11, 16);
  const redirectToProfile = () => history.push(`/user/${user.id}`);
  const classes = useStyles();

  return (
    <Card className={classes.card} classes={{ root: classes.cardRoot }} onClick={redirectToProfile}>
      <CardHeader
        className={classes.cardHeader}
        classes={{
          root: classes.headerRoot,
          content: classes.headerContent,
          avatar: classes.headerAvatar,
        }}
        avatar={""}
        title={
          <div style={{ display: "flex" }}>
            <span>
              {user.firstName}, {user.age}
            </span>
            {user.it && (
              <Avatar src={ItIcon} classes={{ img: classes.imageIt }} className={classes.itIcon} variant={"square"} />
            )}
          </div>
        }
        titleTypographyProps={{ classes: { root: classes.titleTypography } }}
        subheader={
          user.isOnline ? (
            <div className={classes.indicatorContainer}>
              <div className={classes.onlineIndicator}></div>
            </div>
          ) : checkYesterday(user.lastSeen) ? (
            <span>{t("favoritesPage.yesterdayAt")}{yesterdayHHMM}</span>
          ) : (
            `${t("favoritesPage.was")} ${getMessageDate(user.lastSeen)}`
          )
        }
        subheaderTypographyProps={{ classes: { root: classes.subheaderTypography } }}
      />
      <CardMedia
        className={classes.media}
        image={user?.primaryImageUrl ? `${imagesPath}/${user?.primaryImageUrl}` : noAvatar}
      />
      <CardContent className={classes.bottomContainer} classes={{ root: classes.cardContentRoot }}></CardContent>
    </Card>
  );
}

const useStyles = makeStyles({
  card: {
    "&:hover": {
      cursor: "pointer",
      transition: "0.3s",
      transform: "translateY(3px)",
    },
    maxWidth: 300,
    minWidth: 210,
    maxHeight: 280,
    boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.29)",
  },
  imageIt: {
    borderRadius: 0,
    boxShadow: 0,
  },
  cardHeader: {
    height: "50px",
  },
  headerRoot: {
    padding: 0,
  },
  headerContent: {
    marginLeft: "10px",
    //display: 'flex',
    //justifyContent: 'space-between',
    //padding: 0
  },
  headerAvatar: {
    marginRight: "10px",
  },
  subheaderTypography: {
    fontSize: "0.85rem",
  },
  titleTypography: {
    fontSize: 16,
  },
  indicatorContainer: {
    height: "19.64px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  onlineIndicator: {
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    backgroundColor: "limegreen",
  },
  onlineStatusText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  media: {
    height: 180,
    //paddingTop: '56.25%', // 16:9
  },
  cardContentRoot: {
    padding: "0 !important",
  },
  lastSeenContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px",
  },
  itIcon: {
    marginLeft: "10px",
    width: 19,
    height: 19,
    borderRadius: "0 !important",
    boxShadow: 0,
  },
  button: {
    height: "30px",
  },
});
