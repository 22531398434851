import { Grid, Hidden, makeStyles, Drawer, Paper } from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import ChatService from "services/chat-service";
import ChatPane from "./components/chatPane/chatPane";
import ChatSidebar from "./components/chatSidebar/chatSidebar";
import ChatSidebarShort from "./components/chatSidebar/chatSidebarShort/chatSidebarShort";
import {
  aggregateChatMessagesIntoBlocks,
  sortArrayBySentDateTime,
  aggregateChatMessagesIntoChatDays,
} from "helpers/chatHelpers";

import { WsService } from "services/ws-service";

export default function ChatPage({ currentUser }) {
  const styles = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const [chatList, setChatList] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [chatListFilter, setChatListFilter] = useState("");
  const [chatListIsLoading, setChatListIsLoading] = useState(true);
  const [activeRecipientId, setActiveRecipientId] = useState(
    Number.isNaN(parseInt(match.params.id)) ? null : parseInt(match.params.id)
  );
  const [activeRecipient, setActiveRecipient] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageBlocks, setMessageBlocks] = useState([]);
  const [messageDays, setMessageDays] = useState([]);
  const [isSendingInProgress, setIsSendingInProgress] = useState(false);
  const [newWsMessage, setNewWsMessage] = useState(null);
  const [noOlderMessagesAvailable, setNoOlderMessagesAvailable] = useState(null);
  const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false);
  const [latestIncomingMessage, setLatestIncomingMessage] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const latestChatMessages = useRef(null);
  latestChatMessages.current = chatMessages;

  const currentRecipientIdRef = useRef(null);
  currentRecipientIdRef.current = activeRecipientId;

  const isDrawerOpenRef = useRef(null);
  isDrawerOpenRef.current = isDrawerOpen;
  const classes = useStyles();


  useEffect(() => {
    if (WsService.connection) {
      WsService.connection.on("SendMessage", function (message) {
        if (currentUser.id == message.receiverId && currentRecipientIdRef.current == message.senderId) {
          setNewWsMessage(message);
        } else {
          getChatList();
        }
      });

      WsService.connection.on("MarkAsSeen", function (message) {
        if (currentRecipientIdRef.current == message) {
          setChatMessages([...latestChatMessages.current].map((x) => ({ ...x, isSeen: true })));
        }
      });

      WsService.connection.on("UserOnline", function (userId) {
        console.log("User online, getting chat list", userId);
        getChatList();
      });

      WsService.connection.on("UserOffline", function (userId) {
        console.log("User offline, getting chat list", userId);
        getChatList();
      });
    }

    return () => {
      if (WsService && WsService.connection) {
        WsService.connection.off("SendMessage");
        WsService.connection.off("MarkAsSeen");
        WsService.connection.off("UserOnline");
        WsService.connection.off("UserOffline");
      }
    };
  }, []);

  useEffect(() => {
    if (newWsMessage) {
      setChatMessages([...latestChatMessages.current, newWsMessage]);
      getChatList();
    }
  }, [newWsMessage]);

  useEffect(() => {
    let isMounted = true;
    setChatListIsLoading(true);
    ChatService.getChats().then((chatsResponse) => {
      if (isMounted) {
        const sortedChatList = sortArrayBySentDateTime(chatsResponse);
        setChatList(sortedChatList);
        setFilteredChatList(sortedChatList);
        if (!activeRecipientId && sortedChatList && sortedChatList.length) {
          const latestDialogPartnerId = sortedChatList[0].partnerId;
          history.push(`/chat/${latestDialogPartnerId}`);
          setActiveRecipientId(parseInt(latestDialogPartnerId));
        }
        setChatListIsLoading(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (activeRecipientId) {
      currentRecipientIdRef.current = activeRecipientId;
      const requestParameters = {
        partnerId: activeRecipientId,
        pageSize: 50,
      };
      ChatService.getChatMessages(requestParameters).then(
        ({ partnerName, primaryImage, messages, isOnline, lastSeen }) => {
          const reversedMessages = messages.reverse();
          if (isMounted) {
            setIsDrawerOpen(false);
            setActiveRecipient({
              partnerName,
              primaryImage,
              isOnline,
              lastSeen,
              messages: reversedMessages,
              id: activeRecipientId,
            });
            setChatMessages(reversedMessages);
            if (reversedMessages.length < 50) {
              setNoOlderMessagesAvailable(true);
            }
          }
        }
      );

      markAsSeen(true);
    }
    return () => {
      isMounted = false;
    };
  }, [activeRecipientId]);

  useEffect(() => {
    const currentUserPrimaryImageUrl = currentUser ? currentUser.primaryImage : null;
    const activeRecipientIdPrimaryImageUrl = activeRecipient ? activeRecipient.primaryImage : null;
    const aggregatedMessageBlocks =
      !chatMessages && chatMessages.length
        ? []
        : aggregateChatMessagesIntoBlocks(
            chatMessages,
            currentUser.id,
            currentUserPrimaryImageUrl,
            activeRecipientIdPrimaryImageUrl
          );

    const aggregatedMessageDays =
      !chatMessages && chatMessages.length
        ? []
        : aggregateChatMessagesIntoChatDays(
            chatMessages,
            currentUser.id,
            currentUserPrimaryImageUrl,
            activeRecipientIdPrimaryImageUrl
          );

    setMessageBlocks(aggregatedMessageBlocks);

    setMessageDays(aggregatedMessageDays);

    setLatestIncomingMessage(getLatestIncomingChatMessage(chatMessages));

    if (!document.hidden) {
      markAsSeen();
    }
  }, [chatMessages]);

  useEffect(() => {
    if (isLoadingOlderMessages) {
      setIsLoadingOlderMessages(false);
    }
  }, [messageDays]);

  useEffect(() => {
    if (chatListFilter && chatListFilter.length) {
      setFilteredChatList(chatList.filter((c) => c.partnerName.toLowerCase().includes(chatListFilter.toLowerCase())));
    } else {
      setFilteredChatList(chatList);
    }
  }, [chatList, chatListFilter]);

  const activateChat = (id) => {
    if (activeRecipientId == id) {
      setIsDrawerOpen(false);
      return;
    }
    history.push(`/chat/${id}`);
    setActiveRecipientId(parseInt(id));
  };

  const searchChats = (searchString) => {
    if (searchString && searchString.length) {
      setFilteredChatList(chatList.filter((c) => c.partnerName.toLowerCase().includes(searchString.toLowerCase())));
    } else {
      setFilteredChatList(chatList);
    }
  };

  const addUser = () => {};

  const sendMessage = (message) => {
    setIsSendingInProgress(true);
    const payload = {
      message,
      receiverId: activeRecipientId,
    };
    ChatService.sendMessage(payload).then((message) => {
      setChatMessages([...chatMessages, message]);
      setIsSendingInProgress(false);
      getChatList();
    });
  };

  const getOlderMessages = () => {
    setIsLoadingOlderMessages(true);

    const requestParameters = {
      partnerId: activeRecipientId,
      pageSize: 50,
      endDate: chatMessages[0].sentDateTime,
    };
    ChatService.getChatMessages(requestParameters).then(({ messages }) => {
      const reversedMessages = messages.reverse();
      if (reversedMessages.length < 50) {
        setNoOlderMessagesAvailable(true);
      }
      setChatMessages([...reversedMessages, ...chatMessages]);
    });
  };

  const getChatList = () => {
    ChatService.getChats().then((chatsResponse) => {
      const sortedChatList = sortArrayBySentDateTime(chatsResponse);
      setChatList(sortedChatList);
    });
  };

  const markAsSeen = (isNewRecipientActive) => {
    if (!chatMessages || !chatMessages.length) {
      return;
    }
    if (!isNewRecipientActive && (!latestIncomingMessage || latestIncomingMessage.isSeen)) {
      return;
    }
    ChatService.markAsSeen(activeRecipientId).then(() => {
      getChatList();
      setLatestIncomingMessage({ ...latestIncomingMessage, isSeen: true });
    });
  };

  const onVisibilityChange = () => {
    if (!document.hidden) {
      markAsSeen();
    }
  };

  useEffect(() => {
    if (latestIncomingMessage && !latestIncomingMessage.isSeen) {
      // markAsSeen();
    }
  }, [latestIncomingMessage]);

  const getLatestIncomingChatMessage = (messages) => {
    let latestIncomingMessage;
    const messagesLength = messages.length;
    for (let i = messagesLength - 1; i > 0; i--) {
      if (messages[i] && messages[i].receiverId === currentUser.id && messages[i].senderId === activeRecipientId) {
        latestIncomingMessage = messages[i];
      }
      if (latestIncomingMessage) {
        break;
      }
    }
    return latestIncomingMessage;
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    document.addEventListener("focus", onVisibilityChange);
    document.addEventListener("click", onVisibilityChange);
    document.addEventListener("keydown", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      document.removeEventListener("focus", onVisibilityChange);
      document.removeEventListener("click", onVisibilityChange);
      document.removeEventListener("keydown", onVisibilityChange);
    };
  });

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setIsDrawerOpen(isOpen);
  };

  const openActiveRecipientProfile = () => {
    history.push(`/user/${currentRecipientIdRef.current}`);
  };

  const openSenderProfile = () => {
    history.push(`/user/${currentUser.id}`);
  };

   return (            
    <Paper outlined="true" elevation={3} className={styles.page}>
      <Grid container className={styles.containerHolder}>
        <Hidden smDown>
          <Grid item xs={4} className={styles.heightRestricted}>
            <ChatSidebar
              currentUser={currentUser}
              chatList={filteredChatList}
              activeRecipientId={activeRecipientId}
              chatListIsLoading={chatListIsLoading}
              activateChat={activateChat}
              searchChats={searchChats}
              addUser={addUser}
              openSenderProfile={openSenderProfile}
            ></ChatSidebar>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={8} className={[styles.heightRestricted, styles.chatPaneWrapper].join(" ")}>
          <Hidden mdUp>
            <div className={[styles.heightRestricted, styles.sidebarShort].join(" ")} onClick={toggleDrawer(true)}>
              <ChatSidebarShort
                currentUser={currentUser}
                chatList={filteredChatList}
                activeRecipientId={activeRecipientId}
                chatListIsLoading={chatListIsLoading}
              ></ChatSidebarShort>
            </div>
          </Hidden>
          <Drawer
            anchor={"left"}
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            classes={{
              paper: styles.drawer,
            }}
          >
            <ChatSidebar
              currentUser={currentUser}
              chatList={filteredChatList}
              activeRecipientId={activeRecipientId}
              chatListIsLoading={chatListIsLoading}
              activateChat={activateChat}
              searchChats={searchChats}
              addUser={addUser}
              openSenderProfile={openSenderProfile}
            ></ChatSidebar>
          </Drawer>
          <div className={styles.chatPane}>
            <ChatPane
              activeRecipient={activeRecipient}
              messageBlocks={messageBlocks}
              messageDays={messageDays}
              sendMessage={sendMessage}
              getOlderMessages={getOlderMessages}
              currentUser={currentUser}
              isSendingInProgress={isSendingInProgress}
              noOlderMessagesAvailable={noOlderMessagesAvailable}
              isLoadingOlderMessages={isLoadingOlderMessages}
              openActiveRecipientProfile={openActiveRecipientProfile}
            ></ChatPane>
          </div>
        </Grid>
      </Grid>        
    </Paper>

  );
}

const useStyles = makeStyles((theme) => ({
  page: {
    width: "100%",
    height: "calc(100vh - 90px)",
    padding: "20px",
    margin: "20px 0",
  },
  containerHolder: {
    height: "100%",
  },
  heightRestricted: {
    height: "100%",
  },
  drawer: {
    width: "50%",
  },
  chatPaneWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  sidebarShort: {
    height: "100%",
    width: "3.5rem",
  },
  chatPane: {
    height: "100%",
    flexGrow: "100",
  },
}));
