import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Toolbar, AppBar } from "@material-ui/core";
import Alert from "components/ui/Alert/Alert";
import BackgroundImage from "assets/login.jpg";

import { AuthService } from "services/auth-service";
import queryString from "query-string";

function AuthLayout({ children, currentUser }) {
  const classes = useStyles();
  const [alert, setAlert] = useState(false);

  const alertShow = () => setAlert(true);
  const closeAlert = () => setAlert(false);
  let urlToken = queryString.parse(window.location.search);

  return (
    <div className={classes.root}>
      {AuthService.userValue && !urlToken.code ? <Redirect to={`/user/${currentUser?.id}`} /> : null}
      <div className={classes.backDrop} />
      {alert && (
        <Alert
          alertShow={alertShow}
          closeAlert={closeAlert}
          text={"На ваш email отправлено сообщение. Вы будете перенаправлены на страницу входа."}
          type={"success"}
        />
      )}
      <Grid className={classes.container} container justifyContent={"center"}>
        <AppBar className={classes.appBar} position={"absolute"} elevation={0}>
          <Toolbar className={classes.toolbar}>PingPing</Toolbar>
        </AppBar>
        <Grid className={classes.content} item xs={12} sm={6} md={5} lg={4} container alignItems={"center"}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}

const backDropBG = "rgba(255,255,255,0.1)";

const useStyles = makeStyles((theme) => ({
  root: {
    // when you use it, change to 100vh
    // "100vh" is not perfect for all screen ex. iPhone X

    // 2 solutions
    // https://github.com/ulrichformann/react-div-100vh
    // https://github.com/mvasin/react-div-100vh
    paddingRight: 0,
    paddingLeft: 0,
    width: "100%",
    textAlign: "left",
    height: "100vh", // todo change to "100vh" or use react-div-100vh
    position: "relative",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white",
  },
  appBar: {
    background: "linear-gradient(45deg,#8e24aa,#ff6e40) !important",
  },
  toolbar: {
    minHeight: 50,
    fontFamily: "Spicy Rice",
    fontSize: "35px",
    color: "#E5E0EA",
    display: "flex",
    justifyContent: "center",
  },
  backDrop: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: backDropBG,
  },
  container: {
    height: "100%",
    zIndex: 2,
    position: "relative",
  },
  logo: {
    width: "auto",
    height: 40,
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: "auto",
    },
  },
  content: {
    padding: 16,
  },
}));

export default AuthLayout;
