import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import { Card } from "@material-ui/core";

import MessageToAll from "./cardItems/messageToAll";
import BlockUsers from "./cardItems/blockUsers";
import StopRegistartion from "./cardItems/stopRegistration";
import Complaints from "./cardItems/Complaints/complaints";

const SettingsCard = ({ index, currentUser }) => {
  const classes = useStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
  return (
    <Card className={cx(classes.card, cardShadowStyles.root)}>
      {index === 0 && <Complaints />}
      {index === 1 && <MessageToAll />}
      {index === 2 && <>Нужно что-то добавить...</>}
      {index === 3 && <StopRegistartion currentUser={currentUser} />}
    </Card>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    display: "flex",
    minWidth: "85%",
    justifyContent: "center",
    borderRadius: spacing(0.5),
    transition: "0.3s",
    overflow: "initial",
    background: "#ffffff",
  },
  content: {
    textAlign: "left",
    overflowX: "auto",
  },
}));

export default SettingsCard;
