import React, { useState, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography, Box, Modal, Paper } from "@material-ui/core";
import "react-image-gallery/styles/css/image-gallery.css";
import "./imageGallery.css";
import ImageGallery from "react-image-gallery";
import Gallery from "react-photo-gallery";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { ImagesService } from "services/images-service";
import ActionButton from "./actionButton/actionButton";
import UploadButton from "components/ui/Buttons/uploadButton";
import { useTranslation } from 'react-i18next';

export default function PhotoGallery({ images, imagesPath, anotherUser }) {
  const styles = useStyles();
  const refImg = useRef(null);

  const [choosedImage, setChoosedImage] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  
  console.log('img', images);

  const imagesToLowerCase = images?.map((item, index) =>
    Object.keys(item).reduce((c, k) => ((c[k.toLowerCase()] = item[k]), c), {})
  );

  const photos = imagesToLowerCase?.map((item, index) => ({
    ...item,
    width: 1,
    height: 1,
    src: `${imagesPath}/${item.filename}`,
    original: `${imagesPath}/${item.filename}`,
    key: index.toString(), //library require string
    isprimary: item.isprimary.toString(),
  }));

  const openSlider = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeSlider = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const renderCustomControls = () => {
    let state = refImg?.current?.state?.currentIndex;
    let image = images[state];
    setChoosedImage(image);
  };

  const uploadImage = (event) => {
    ImagesService.addImage(event.target.files[0]);
  };

  const renderSettingsButton = () => {
    return (
      <div className={styles.actionButtonContainer}>
        <ActionButton choosedImage={choosedImage} />
      </div>
    );
  };

  console.log('images', images);

  return (
    <div className={styles.container}>
      <Paper elevation={3} className={styles.contentPaper}>
        <Box className={styles.photoTitleContainer}>
          <div className={styles.iconAvatarContainer}>
            <Avatar className={styles.avatarIcon}>
              <PhotoLibraryIcon style={{ fontSize: "22px" }} />
            </Avatar>
            <Typography className={styles.titlePhoto} variant="h6" fontFamily="Monospace">
                          {t("userPage.gallery")}
            </Typography>
          </div>
          {!anotherUser && <UploadButton uploadImage={uploadImage} />}
        </Box>
        {images?.length ? (
          <Gallery photos={photos} onClick={openSlider} direction="column" margin={4} columns={3} />
        ) : null}
      </Paper>
      {viewerIsOpen ? (
        <Modal open={viewerIsOpen} onClose={closeSlider} className="img-gallery-modal">
          <ImageGallery
            items={photos}
            ref={refImg}
            startIndex={currentImage}
            renderCustomControls={renderCustomControls}
            renderPlayPauseButton={renderSettingsButton}
            showThumbnails={false}
            showFullscreenButton={false}
            useTranslate3D={true}
            originalClass={styles.imageGallery}
          />
        </Modal>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  contentPaper: {
    maxWidth: "400px",
    border: "3px solid white",
    width: "100%",
    margin: "8px",
    borderRadius: "10px",
  },
  titlePhoto: {
    fontFamily: "monospace",
    fontVariant: "all-petite-caps",
    fontSize: "22px",
    marginBottom: "2px",
    marginBottom: "5px",
  },
  photoTitleContainer: {
    marginLeft: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatarIcon: {
    color: "#512da8",
    width: "32px",
    marginLeft: "2px",
    backgroundColor: "white",
  },
  iconAvatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  actionButtonContainer: {
    position: "absolute",
    top: "92%",
    left: "20px",
  },
  appImageGallery: {
    height: "100vh",
  },
  actionButtonContainer: {
    position: "absolute",
    bottom: "0",
  },
}));
