import i18n from "i18next";
export function getMessageDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();


    const daysOfWeek = [
        i18n.t('daysOfWeek.sun'),
        i18n.t('daysOfWeek.mon'),
        i18n.t('daysOfWeek.tue'),
        i18n.t('daysOfWeek.wed'),
        i18n.t('daysOfWeek.thu'),
        i18n.t('daysOfWeek.fri'),
        i18n.t('daysOfWeek.sat'),
    ];

    let dateTimeString;

    const timeDiff = now - date;

    const timeDiffDays = timeDiff / (1000 * 60 * 60 * 24);

    const timeDiffWeeks = timeDiffDays / 7;

    const timeDiffYears = now.getFullYear() - date.getFullYear();

    if (timeDiffDays < 1) {
        dateTimeString = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    } else if (1 <= timeDiffDays && timeDiffDays < 7) {
        dateTimeString = `${daysOfWeek[date.getDay()]} ${date.getHours().toString().padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
    } else if (7 <= timeDiffDays && timeDiffDays < 365) {
        dateTimeString = `${Math.floor(timeDiffWeeks)} ${i18n.t('chat.week')}`;
    } else {
        dateTimeString = `${timeDiffYears} ${i18n.t('chat.year')}`;
    }

    return dateTimeString;
}

export function getMessageTime(dateString) {
    const date = new Date(dateString);
    const now = new Date();

    let dateTimeString;

    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
}

export function getUserFriendlyMessageDateTime(dateString) {
    const date = new Date(dateString);
    const now = new Date();

    let dateTimeString = `${date.toLocaleString("en-US", { hour12: false })}`;

    if (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() == now.getDate()
    ) {
        dateTimeString = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    }

    return dateTimeString;
}

export function getUserFriendlyDayBlockDate(year, month, date) {
    const months = [
        i18n.t('month.January'),
        i18n.t('month.February'),
        i18n.t('month.March'),
        i18n.t('month.April'),
        i18n.t('month.May'),
        i18n.t('month.June'),
        i18n.t('month.July'),
        i18n.t('month.August'),
        i18n.t('month.September'),
        i18n.t('month.October'),
        i18n.t('month.November'),
        i18n.t('month.December'),

    ];
    const now = new Date();

    const showYear = now.getFullYear().toString() !== year;

    return localStorage.getItem("i18nextLng") === "en" ?
        `${months[month]} ${date} ${showYear ? year : ""}` :
        `${date} ${months[month]} ${showYear ? year : ""}`;
}

export function formatDate(date) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
}

export function substructDate(date) {
    var d = new Date();
    console.log("d1", d);
    d.setDate(d.getDate() - date);
    console.log("d2", d);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1;
    var curr_year = d.getFullYear();
    var current = curr_year + "-" + curr_month + "-" + curr_date;

    return current;
}
