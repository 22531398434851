import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { history } from "helpers/history";
import App from "./App";
import AppErrorWrapper from "./AppErrorWrapper";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import  "./18n";

startApp();

function startApp() {
    ReactDOM.render(
        <Router history={history}>
            <Suspense fallback={ <div>Loading... </div>}>
                <AppErrorWrapper>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </AppErrorWrapper>
            </Suspense>
        </Router>,
        document.getElementById("root")
    );
}
