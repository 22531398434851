import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Input, InputLabel, FormControl } from "@material-ui/core";
import Button from "components/ui/Buttons/button";
import { Alert } from "@material-ui/lab";
import { AccountService } from "services/account-service";
import { useTranslation } from 'react-i18next';

export default function FormPasswordReset() {
  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
    const { t, i18n } = useTranslation();

  const ChangePassword = () => {
    if (newPassword) {
      AccountService.changePassword(oldPassword, newPassword).then((res) => {
        if (res.status === 200) {
          setShowSuccess(true);
        } else {
          setShowError(true);
        }
      });
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <FormControl className={classes.inputContainer}>
                  <InputLabel>{t("settingsPage.changePass")}</InputLabel>
          <Input value={oldPassword} type="password" onChange={(e) => setOldPassword(e.target.value)} />
        </FormControl>
        <FormControl className={classes.inputContainer}>
                  <InputLabel>{t("settingsPage.changePassNew")}</InputLabel>
          <Input value={newPassword} type="password" onChange={(e) => setNewPassword(e.target.value)} />
        </FormControl>
        <div className={classes.buttonContainer}>
                  <Button onClick={ChangePassword} title={t("buttons.edit")} color="primary" disabled={!(newPassword && oldPassword)} />
        </div>
        <div>
          {showSuccess && <Alert severity="success">Пароль успешно изменен</Alert>}
          {showError && <Alert severity="error">Произошла ошибка, попробуйте позже</Alert>}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    width: "100%",
    marginTop: "20px",
  },
  buttonContainer: {
    marginTop: "10px",
    marginBottom: "5px",
    width: "250px",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    color: "white",
    background: "#8e24aa",
    boxShadow: "0 7px 10px -5px #6a1b9a",
    "&:hover": {
      backgroundColor: "#6a1b9a",
    },
  },
}));
