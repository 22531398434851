import { Grid, Hidden, makeStyles, Drawer } from "@material-ui/core";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import ChatService from "services/chat-service";
import { UserService } from "services/user-service";
import AdminChatPane from "./components/adminChatPane/adminChatPane";
import ChatSidebar from "../Chat/components/chatSidebar/chatSidebar";
import ChatSidebarShort from "../Chat/components/chatSidebar/chatSidebarShort/chatSidebarShort";
import {
  aggregateChatMessagesIntoBlocks,
  sortArrayBySentDateTime,
  aggregateChatMessagesIntoChatDays,
  getRecipientId,
} from "helpers/chatHelpers";

export default function AdminChatPage({ currentUser }) {
  const styles = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [chatList, setChatList] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [chatListFilter, setChatListFilter] = useState("");
  const [chatListIsLoading, setChatListIsLoading] = useState(true);
  const [senderId, setSenderId] = useState(Number.isNaN(parseInt(match.params.id)) ? null : parseInt(match.params.id));
  const [sender, setSender] = useState(null);
  const [activeRecipientId, setActiveRecipientId] = useState(null);
  const [activeRecipient, setActiveRecipient] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageBlocks, setMessageBlocks] = useState([]);
  const [messageDays, setMessageDays] = useState([]);
  const [isSendingInProgress, setIsSendingInProgress] = useState(false);
  const [noOlderMessagesAvailable, setNoOlderMessagesAvailable] = useState(null);
  const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false);
  const [latestIncomingMessage, setLatestIncomingMessage] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const latestChatMessages = useRef(null);
  latestChatMessages.current = chatMessages;

  const currentRecipientIdRef = useRef(null);
  currentRecipientIdRef.current = activeRecipientId;

  const isDrawerOpenRef = useRef(null);
  isDrawerOpenRef.current = isDrawerOpen;

  useEffect(() => {
    if (!senderId) {
      return;
    }
    let isMounted = true;
    UserService.getUserById(senderId).then((sndr) => {
      if (isMounted) {
        setSender(sndr.user);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [senderId]);

  useEffect(() => {
    if (!sender) {
      return;
    }
    let isMounted = true;
    setChatListIsLoading(true);
    ChatService.getChatsAdm(senderId).then((chatsResponse) => {
      if (isMounted) {
        const sortedChatList = sortArrayBySentDateTime(chatsResponse);
        setChatList(sortedChatList);
        setFilteredChatList(sortedChatList);
        if (sortedChatList && sortedChatList.length) {
          const recipientId = getRecipientId(location.search);
          const latestDialogPartnerId = sortedChatList[0].partnerId;
          if (recipientId) {
            setActiveRecipientId(recipientId);
          } else {
            history.push(`/admin-chat/${senderId}?recipientId=${latestDialogPartnerId}`);
          }
        }
        setChatListIsLoading(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [sender]);

  useEffect(() => {
    let isMounted = true;
    if (activeRecipientId) {
      currentRecipientIdRef.current = activeRecipientId;
      const requestParameters = {
        userId: senderId,
        partnerId: activeRecipientId,
        pageSize: 50,
      };
      ChatService.getChatMessagesAdm(requestParameters).then(
        ({ partnerName, primaryImage, messages, isOnline, lastSeen }) => {
          const reversedMessages = messages.reverse();
          if (isMounted) {
            setIsDrawerOpen(false);
            setActiveRecipient({
              partnerName,
              primaryImage,
              isOnline,
              lastSeen,
              messages: reversedMessages,
              id: activeRecipientId,
            });
            setChatMessages(reversedMessages);
            if (reversedMessages.length < 50) {
              setNoOlderMessagesAvailable(true);
            }
          }
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [activeRecipientId]);

  useEffect(() => {
    if (!chatMessages) {
      return;
    }
    const senderPrimaryImageUrl = sender ? sender.primaryImage : null;
    const activeRecipientIdPrimaryImageUrl = activeRecipient ? activeRecipient.primaryImage : null;
    const aggregatedMessageBlocks =
      !chatMessages && chatMessages.length
        ? []
        : aggregateChatMessagesIntoBlocks(
            chatMessages,
            senderId,
            senderPrimaryImageUrl,
            activeRecipientIdPrimaryImageUrl
          );

    const aggregatedMessageDays =
      !chatMessages && chatMessages.length
        ? []
        : aggregateChatMessagesIntoChatDays(
            chatMessages,
            senderId,
            senderPrimaryImageUrl,
            activeRecipientIdPrimaryImageUrl
          );

    setMessageBlocks(aggregatedMessageBlocks);

    setMessageDays(aggregatedMessageDays);

    setLatestIncomingMessage(getLatestIncomingChatMessage(chatMessages));
  }, [chatMessages]);

  useEffect(() => {
    if (isLoadingOlderMessages) {
      setIsLoadingOlderMessages(false);
    }
  }, [messageDays]);

  useEffect(() => {
    if (chatListFilter && chatListFilter.length) {
      setFilteredChatList(chatList.filter((c) => c.partnerName.toLowerCase().includes(chatListFilter.toLowerCase())));
    } else {
      setFilteredChatList(chatList);
    }
  }, [chatList, chatListFilter]);

  const activateChat = (id) => {
    if (activeRecipientId == id) {
      setIsDrawerOpen(false);
      return;
    }
    history.push(`/admin-chat/${senderId}?recipientId=${id}`);
    setActiveRecipientId(parseInt(id));
  };

  const searchChats = (searchString) => {
    if (searchString && searchString.length) {
      setFilteredChatList(chatList.filter((c) => c.partnerName.toLowerCase().includes(searchString.toLowerCase())));
    } else {
      setFilteredChatList(chatList);
    }
  };

  const getOlderMessages = (stuff) => {
    setIsLoadingOlderMessages(true);

    const requestParameters = {
      userId: senderId,
      partnerId: activeRecipientId,
      pageSize: 50,
      endDate: chatMessages[0].sentDateTime,
    };
    ChatService.getChatMessagesAdm(requestParameters).then(({ partnerName, primaryImage, messages }) => {
      const reversedMessages = messages.reverse();
      if (reversedMessages.length < 50) {
        setNoOlderMessagesAvailable(true);
      }
      setChatMessages([...reversedMessages, ...chatMessages]);
    });
  };

  const getLatestIncomingChatMessage = (messages) => {
    let latestIncomingMessage;
    const messagesLength = messages.length;
    for (let i = messagesLength - 1; i > 0; i--) {
      if (messages[i] && messages[i].receiverId === senderId && messages[i].senderId === activeRecipientId) {
        latestIncomingMessage = messages[i];
      }
      if (latestIncomingMessage) {
        break;
      }
    }
    return latestIncomingMessage;
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setIsDrawerOpen(isOpen);
  };

  const openActiveRecipientProfile = () => {
    history.push(`/user/${currentRecipientIdRef.current}`);
  };

  const openSenderProfile = () => {
    history.push(`/user/${senderId}`);
  };

  return (
    <>
      {sender && (
        <Grid container className={styles.page}>
          <Hidden smDown>
            <Grid item xs={4} className={styles.heightRestricted}>
              <ChatSidebar
                currentUser={sender}
                chatList={filteredChatList}
                activeRecipientId={activeRecipientId}
                chatListIsLoading={chatListIsLoading}
                activateChat={activateChat}
                searchChats={searchChats}
                openSenderProfile={openSenderProfile}
              ></ChatSidebar>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={8} className={[styles.heightRestricted, styles.chatPaneWrapper].join(" ")}>
            <Hidden mdUp>
              <div className={[styles.heightRestricted, styles.sidebarShort].join(" ")} onClick={toggleDrawer(true)}>
                <ChatSidebarShort
                  currentUser={sender}
                  chatList={filteredChatList}
                  activeRecipientId={activeRecipientId}
                  chatListIsLoading={chatListIsLoading}
                ></ChatSidebarShort>
              </div>
            </Hidden>
            <Drawer
              anchor={"left"}
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              classes={{
                paper: styles.drawer,
              }}
            >
              <ChatSidebar
                currentUser={sender}
                chatList={filteredChatList}
                activeRecipientId={activeRecipientId}
                chatListIsLoading={chatListIsLoading}
                activateChat={activateChat}
                searchChats={searchChats}
                openSenderProfile={openSenderProfile}
              ></ChatSidebar>
            </Drawer>
            <div className={styles.chatPane}>
              <AdminChatPane
                activeRecipient={activeRecipient}
                messageBlocks={messageBlocks}
                messageDays={messageDays}
                getOlderMessages={getOlderMessages}
                currentUser={sender}
                isSendingInProgress={isSendingInProgress}
                noOlderMessagesAvailable={noOlderMessagesAvailable}
                isLoadingOlderMessages={isLoadingOlderMessages}
                openActiveRecipientProfile={openActiveRecipientProfile}
              ></AdminChatPane>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  page: {
    width: "100%",
    height: "100%",
    padding: 0,
    margin: 0,
  },
  heightRestricted: {
    height: "100%",
  },
  drawer: {
    width: "50%",
  },
  chatPaneWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  sidebarShort: {
    height: "100%",
    width: "3.5rem",
  },
  chatPane: {
    height: "100%",
    flexGrow: "100",
  },
}));
