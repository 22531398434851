import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, CardContent } from "@material-ui/core";
import Button from "components/ui/Buttons/button";

import ModalMessage from "components/ui/Modals/ModalMessage";

export default function FormPasswordReset() {
  const classes = useStyles();

  const [text, setText] = useState("");

  return (
    <CardContent className={classes.content}>
      <div className={classes.container}>
        <TextField
          fullWidth="true"
          id="outlined-multiline-static"
          multiline
          rows="8"
          variant="outlined"
          className={classes.textField}
          onChange={(e) => setText(e.target.value)}
        />
        <div className={classes.buttonContainer}>
          <Button title="Отправить" color="primary" />
        </div>
      </div>
    </CardContent>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    width: "350px",
    marginBottom: "30px",
  },
  buttonContainer: {
    marginTop: "10px",
    marginBottom: "5px",
    width: "350px",
    display: "flex",
    justifyContent: "center",
  },
}));
