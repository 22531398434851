import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const FavoritesService = {
  getFavorites,
  putFavorites,
  deleteFavorites,
};

function getFavorites(CurrentPage, PageSize) {
  return fetchWrapper.get(`${API_URL}/Favorites?CurrentPage=${CurrentPage}&PageSize=${PageSize}`);
}

function putFavorites(id) {
  return fetchWrapper.put(`${API_URL}/Favorites`, id);
}

function deleteFavorites(favoriteUserId) {
  return fetchWrapper.delete(`${API_URL}/Favorites/${Number(favoriteUserId)}`);
}
