import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Tab } from "@material-ui/core";

const Menu = ({ tabs, tabProps, icon, ...props }) => {
  const tabsClasses = useTabsStyles(props);
  const tabClasses = useTabStyles(tabProps);
  return (
    <Tabs {...props} classes={tabsClasses}>
      {tabs.map((tab) => (
        <Tab key={tab.label} {...tab} classes={tabClasses} />
      ))}
    </Tabs>
  );
};

const useTabsStyles = makeStyles(() => ({
  root: {
    //width: '200px',
  },
  indicator: {
    display: "none",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",

    button: {
      textTransform: 'none',
    }
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
  },
}));

const useTabStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    borderRadius: "2px",
    transition: "all .5s",
    color: "#555555",
    height: "auto",
    margin: "0px 0",
    textTransform: "none",

    [breakpoints.up("md")]: {
      minWidth: 202,
    },
    "&:hover": {
      backgroundColor: "lightgray",
    },
    "& + button": {
      margin: "0px 0",
    },
    "&$selected": {
      "&, &:hover": {
        color: "#FFFFFF",
        backgroundColor: "#9c27b0",
        boxShadow: "0 6px 8px -5px rgba(110, 38, 162, 0.9)",
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
  },
  selected: {
    border: "solid",
    borderWidth: "1px",
    borderColor: "#9c27b0",
  },
  wrapper: {
    textAlign: "left",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    color: "inherit",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  labelIcon: {
    minHeight: "50px",
    paddingTop: "5px",
    "& $wrapper :first-child": {
      marginBottom: 0,
    },
  },
}));

Menu.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
    })
  ),
  tabProps: PropTypes.shape({}),
};
Menu.defaultProps = {
  tabs: [],
  tabProps: {},
};

export default Menu;
