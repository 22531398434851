import { AuthService } from "./auth-service";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { SIGNALR_HUB_URL } from "../config";

export const WsService = {
  connect,
};

function connect(accessToken) {
  WsService.connection = new HubConnectionBuilder()
    .withUrl(`${SIGNALR_HUB_URL}/`, {
      accessTokenFactory: () => AuthService.userValue.accessToken,
    })
    .withAutomaticReconnect()
    .build();
}
