import { makeStyles, withStyles, Button, Modal } from "@material-ui/core";
import { Face } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
// import './chatPaneControls.scss';
import "./chatPaneControls.css";
import EmojiPicker from "./emojiPicker";
import { useTranslation } from 'react-i18next';
import { pink } from "@material-ui/core/colors";

export default function ChatPaneControls({ sendMessage, isSendingInProgress }) {
    const styles = useStyles();
    const [message, setMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalCoordinates, setModalCoordinates] = useState({ x: null, y: null });
    const { t, i18n } = useTranslation();
    const [chosenEmoji, setChosenEmoji] = useState(null);

  const onClick = (event) => {
    setModalCoordinates({ x: event.clientX, y: event.clientY - 325 });
  };
  const onInputChange = (event) => {
    setMessage(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!message.trim()) {
      return;
    }

    sendMessage(message);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage(message + emojiObject.emoji);
    setChosenEmoji(emojiObject);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setMessage("");
  }, [isSendingInProgress]);

  useEffect(() => {
    if (modalCoordinates.x && modalCoordinates.y) {
      setIsModalOpen(true);
    }
  }, [modalCoordinates]);

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
      "&:hover": {
        backgroundColor: pink[700],
      },
    },
  }))(Button);

  return (
    <form className={styles.controlsForm} onSubmit={onSubmit}>
      <div className={styles.controlsContainer}>
        <button type="button" className="chatCtrl" onClick={onClick}>
          <Face></Face>
        </button>
        <Modal
          open={isModalOpen}
          onClose={closeModal}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {<EmojiPicker handleEmojiClick={handleEmojiClick} modalCoordinates={modalCoordinates} />}
        </Modal>

        <input
          type="text"
          className={styles.inputContainer}
          onChange={onInputChange}
          value={message}
                  placeholder={t("chat.placeholder")}
          disabled={isSendingInProgress}
        ></input>
        <ColorButton type={"submit"} color={"primary"} variant="contained">
                  {t("buttons.send")}
        </ColorButton>
      </div>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  controlsForm: {
    height: "100%",
    padding: "0 2rem",
    borderTop: "1px solid #eeeeee",
  },
  controlsContainer: {
    height: "100%",
    alignItems: "center",

    display: "flex",
    flexDirection: "row",

    "& .chatCtrl": {
      color: "#6b6f82",
      backgroundColor: "#fff",
      border: "none",
      marginLeft: "0.2rem",
      cursor: "pointer",
    },
    "& .chatCtrl:focus": {
      outline: "none",
    },
    "& *": {
      margin: "0 0.1rem",
    },
  },
  inputContainer: {
    flexGrow: 1,
    color: "#9e9e9e",
    backgroundColor: "#eceff1",
    padding: "0 1.5rem",
    height: "2.4rem",
    borderRadius: "0.3rem",
    display: "flex",
    alignItems: "center",
    border: "none",
    marginRight: "0.8rem",
  },
  emojiModal: {},
}));
