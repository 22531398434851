import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default function ContainedButton({ onClick, color, title, fullWidth, type, disabled = false }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        onClick={onClick}
        variant="contained"
        type={type ? type : ""}
        color={color}
        fullWidth={fullWidth ? true : false}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
