import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import UserComplaintTable from "./userComplaintsTable";
import ComplaintsService from "services/complaints-service";
import { FILTER_MAP } from "helpers/complainstHelpers";

const TABS_MAP = {
  Users: 0,
  Topics: 1,
  Comments: 2,
};

const DEFAULT_PAGINATION_SETTING = {
  currentPage: 0,
  pageSize: 10,
  filter: null,
};

export default function Complaints() {
  const classes = useStyles();
  const history = useHistory();

  const [activeTabIdx, setActiveTabIdx] = useState(TABS_MAP.Users);
  const [complaints, setComplaints] = useState([]);
  const [totalComplaintsCount, setTotalComplaintsCount] = useState(0);
  const [paginationSettings, setPaginationSettings] = useState(DEFAULT_PAGINATION_SETTING);

  useEffect(() => {
    getUserComplaints();
  }, []);

  useEffect(() => {
    getUserComplaints();
  }, [paginationSettings]);

  const onTabChange = (_, newValue) => {
    setActiveTabIdx(newValue);
  };

  const onDeleteComplaint = (id) => {
    ComplaintsService.deleteComplaint(id).then(() => getUserComplaints());
  };

  const onDeleteAllComplaintsByUserId = (id) => {
    ComplaintsService.deleteAllComplaintsByUserId(id).then(() => getUserComplaints());
  };

  const itemsPresentOnPreviousPages = (tabIdx, complainstResponse) => {
    return complainstResponse.resultsCount > 0 && complainstResponse.results.length === 0;
  };

  const getUserComplaints = () => {
    ComplaintsService.getUserComplaints(
      paginationSettings.currentPage,
      paginationSettings.pageSize,
      getFilterQueryString(paginationSettings.filter)
    ).then((complaintsRes) => {
      if (itemsPresentOnPreviousPages(activeTabIdx, complaintsRes)) {
        setPaginationSettings({ ...DEFAULT_PAGINATION_SETTING, filter: paginationSettings.filter });
        return;
      }
      setComplaints(complaintsRes.results);
      setTotalComplaintsCount(complaintsRes.resultsCount);
    });
  };

  const openUserProfile = (id) => {
    history.push(`/user/${id}`);
  };

  const getFilterQueryString = (filter) => {
    if (!filter) {
      return "";
    }
    if (filter.type === FILTER_MAP.Reporter) {
      return `&ReporterId=${filter.value}`;
    }
    if (filter.type === FILTER_MAP.Reported) {
      return `&ReportedId=${filter.value}`;
    }
    return "";
  };

  return (
    <CardContent className={classes.content}>
      <Tabs variant="fullWidth" value={activeTabIdx} onChange={onTabChange} aria-label="basic tabs example">
        <Tab label="Пользователи" />
        <Tab label="Темы" disabled />
        <Tab label="Комментарии" disabled />
      </Tabs>
      {activeTabIdx === TABS_MAP.Users && (
        <UserComplaintTable
          complaints={complaints}
          totalComplaintsCount={totalComplaintsCount}
          paginationSettings={paginationSettings}
          setPaginationSettings={setPaginationSettings}
          onDeleteComplaint={onDeleteComplaint}
          onDeleteAllComplaintsByUserId={onDeleteAllComplaintsByUserId}
          openUserProfile={openUserProfile}
        />
      )}
      {activeTabIdx === TABS_MAP.Topics && <div>Not implemented.</div>}
      {activeTabIdx === TABS_MAP.Comments && <div>Not implemented.</div>}
    </CardContent>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    width: "100%",
  },
}));
