import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={5} ref={ref} variant="filled" {...props} />;
});
const time = 5000;

class AppErrorWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorFound: false,
    };
    this.clearErrorState = this.clearErrorState.bind(this);
  }

  clearErrorState() {
    this.setState({
      errorFound: false,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { errorFound } = state;
    if (errorFound) {
      setTimeout(() => {
        this.clearErrorState();
      }, time);
    }
    return false;
  }

  componentDidCatch(/*error*/) {
    this.setState({
      errorFound: true,
    });
  }

  componentWillUnmount() {
    this.clearErrorState();
  }

  render() {
    const { errorFound } = this.state;

    return (
      <>
        <Snackbar
          open={errorFound}
          autoHideDuration={time}
          onClose={this.clearErrorState}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={this.clearErrorState} severity="error" sx={{ width: "100%" }}>
            Произошла ошибка
          </Alert>
        </Snackbar>
        {this.props.children}
      </>
    );
  }
}

export default AppErrorWrapper;
