import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";

import { BlackListService } from "services/blacklist-service";
import { FavoritesService } from "services/favorites-service";
import { useTranslation } from 'react-i18next';

const PopperList = ({ alertShow, open, setOpen, showModal, user, isAdmin, viewChat }) => {
  const classes = useStyles();

  const [hideFavorites, setHideFavorites] = useState(false);
  const [blocked, setBlocked] = useState(false);

  function addToFavorites() {
    alertShow();
    FavoritesService.putFavorites(user?.user?.id);
    setHideFavorites(true);
    setOpen(!open);
    window.location.reload();
  }

  function addToBlackList() {
    alertShow();
    BlackListService.putBlackList(user?.user?.id);
    setBlocked(true);
    setOpen(!open);
    window.location.reload();
  }

  return (
    <List component="nav" className={classes.root} aria-label="contacts">
      {!user.isInBlacklist && (
        <ListItem
          disabled={!user.isInFavorites ? false : true}
          button
          className={classes.button}
          onClick={addToFavorites}
        >
          <ListItemText
            className={classes.listItemText}
            primary={!user.isInFavorites ? "В избранное" : "Уже в избранном"}
          />
        </ListItem>
      )}
      <Divider />
      {!user.isInFavorites && (
        <ListItem
          disabled={!user.isInBlacklist ? false : true}
          button
          className={classes.button}
          onClick={addToBlackList}
        >
          <ListItemText
            className={classes.listItemText}
            primary={!user.isInBlacklist ? "Блокировать" : "Уже заблокирован"}
          />
        </ListItem>
      )}
      <Divider />
      <ListItem button className={classes.button} onClick={showModal}>
        <ListItemText className={classes.listItemText} primary="Пожаловаться" />
      </ListItem>
      {isAdmin && (
        <>
          <Divider />
          <ListItem button className={classes.button} onClick={viewChat}>
            <ListItemText className={classes.listItemText} primary="Просмотр сообщений" />
          </ListItem>
        </>
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    //borderTopRightRadius: '0px',
    //border: '1px solid gray',
    boxShadow: "0px 0px 15px 1px rgba(0,0,0,0.75)",
  },
  listItem: {
    marginLeft: 0,
  },
  listItemText: {
    marginLeft: 0,
  },
  button: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

export default PopperList;
