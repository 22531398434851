import React from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function SwitchItem({ setToggleOpen, toggleOpen, openedTitle, closedTitle }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormControlLabel
        className={classes.switch}
        classes={{ root: classes.root }}
        control={
          <Switch checked={toggleOpen} onChange={() => setToggleOpen(!toggleOpen)} value={toggleOpen} color="primary" />
        }
        label={toggleOpen ? openedTitle : closedTitle}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  switch: {},
  root: {
    marginRight: 0,
  },
}));
