import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import { FavoritesService } from "services/favorites-service";

import Pagination from "components/ui/Pagination/pagination";
import FavoritesCard from "components/ui/Cards/FavouriteAndBlacklistCard";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from 'react-i18next';

export default function FavoritesPage({ imagesPath }) {
  const styles = useStyles();

  const [favorites, setFavorites] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const pageSize = 20;
    const { t, i18n } = useTranslation();

  function getFavorites() {
    setIsLoading(true);
    FavoritesService.getFavorites(page - 1, pageSize)
      .then((res) => {
        setFavorites(res);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getFavorites();
  }, [page]);

  return (
    <Container className={styles.page}>
      {!isLoading && (
        <Container className={styles.content}>
          {favorites?.results?.map((item, index) => {
            return (
              <FavoritesCard
                favoriteUser={item.favoriteUser}
                favoriteUserId={item.favoriteUserId}
                key={index}
                imagesPath={imagesPath}
              />
            );
          })}
          {favorites.resultsCount > 0 && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageSize={pageSize}
                usersAmount={favorites.resultsCount}
                onClick={getFavorites}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
          {favorites.resultsCount === 0 && (
            <div className={styles.emptyListContainer}>
              <div className={styles.iconContainer}>
                <InfoIcon className={styles.icon} />
              </div>
                          <Typography variant={"h4"}>t("favoritesPage.emptyList")</Typography>
            </div>
          )}
        </Container>
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  page: {
    padding: 0,
    margin: 0,
  },
  content: {
    marginTop: "20px",
    padding: 0,
  },
  paginationContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
  },
  emptyListContainer: {
    position: "absolute",
    top: "40%",
    left: "43%",
  },
  iconContainer: {
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    fontSize: "60px",
  },
}));

/*
  {currentUser.favorites.map((item) => {
            return <UserCard item={item} />
          })}
          */
