import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const CitiesService = {
  getCityById,
  getCountryById,
  getTopCountries,
  getTopCities,
  getSearchedEnteredCities,
  getSearchedEnteredCountries,
};

function getCityById(id) {
  return fetchWrapper.get(`${API_URL}/Cities/city?cityId=${id}`);
}

function getCountryById(id) {
  return fetchWrapper.get(`${API_URL}/Cities/country?countryId=${id}`);
}

function getTopCountries() {
  return fetchWrapper.get(`${API_URL}/Cities/top-countries`);
}

function getTopCities(id) {
  return fetchWrapper.get(`${API_URL}/Cities/top-cities?countryId=${id}`);
}

function getSearchedEnteredCities(id, inputValue) {
  return fetchWrapper.get(`${API_URL}/Cities/cities?countryId=${id}&cityStartPart=${inputValue}`);
}

function getSearchedEnteredCountries(inputValue) {
    return fetchWrapper.get(`${API_URL}/Cities/countries?countryStartPart=${inputValue}`);
}
