import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar, Container, Paper } from "@material-ui/core";
import { Root, Header, InsetSidebar, Content, SidebarTrigger, SidebarTriggerIcon } from "@mui-treasury/layout";
import useWindowDimensions from "hooks/useWindowDimension";

import Nav from "./Nav";
import NavSidebar from "./NavSidebar";
// import HeaderEx from './Header';
import ContentEx from "./Content";

const config = {
  heightAdjustmentDisabled: true,
  insetHiddenBreakpoint: "sm",
  sidebar: {
    variant: "persistent", // won't affect inset sidebar
    collapsible: true, // won't affect inset sidebar
    collapsedWidth: 64, // won't affect inset sidebar
    width: 200, // can be percentage
    anchor: "left",
    inset: true,
    insetProps: {
      position: "sticky",
      top: 0,
      drawerAnchor: "left",
    },
  },
  header: {
    position: "sticky",
    offsetHeight: 64,
    clipped: false,
    persistentBehavior: "fit",
  },
  content: {
    persistentBehavior: "fit",
  },
  footer: {
    persistentBehavior: "flexible",
  },
};

export default function Admin(props) {
  const classes = useStyles();
  const currentUser = props.currentUser;

  const { width } = useWindowDimensions();

  return (
    <Root config={config}>
      <Header>
        <Paper elevation={1}>
          <Toolbar className={classes.toolbar}>
            <SidebarTrigger className={classes.icon}>
              <SidebarTriggerIcon />
            </SidebarTrigger>
            {width > 960 && <Nav currentUser={currentUser} />}
            {/* <HeaderEx /> */}
          </Toolbar>
        </Paper>
      </Header>
      <Container className={classes.container}>
        {width <= 960 && (
          <InsetSidebar
            className={classes.insetSidebar}
            PaperProps={{
              classes: {
                root: classes.sidebar,
              },
            }}
          >
            <NavSidebar currentUser={currentUser} />
          </InsetSidebar>
        )}
        <Content className={classes.content}>
          <ContentEx content={props.children} />
        </Content>
      </Container>
    </Root>
  );
}

const useStyles = makeStyles({
  container: {
    height: "calc(100% - 50px)",
    maxWidth: "950px",
  },
  toolbar: {
    margin: "0",
    background: "white",
    minHeight: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 1rem",
  },
  content: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    width: "100%",
  },
  sidebar: {
    zIndex: "0 !important",
    width: "200px !important",
  },
  icon: {
    color: "#512da8",
    marginLeft: "5px",
  },
});
