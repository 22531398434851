import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Input, InputLabel, FormControl, CardContent, FormHelperText } from "@material-ui/core";
import Button from "components/ui/Buttons/button";
import { AccountService } from "services/account-service";
import useForm from "hooks/useForm";
import validateChangeEmail from "validations/changeEmail";
import Alert from "components/ui/Alert/Alert";
import { useTranslation } from 'react-i18next';

const ChangeEmail = ({ currentUser }) => {
  const classes = useStyles();
  const { handleChange, handleSubmit, values, errors } = useForm(сhangeEmail, validateChangeEmail);
    const { t, i18n } = useTranslation();

  function сhangeEmail() {
    const { newEmail } = values;
    if (currentUser?.email !== newEmail) {
      AccountService.changeEmailConfirmation({ newEmail });
      //console.log('SENDED', newEmail)
    } else {
      // console.log('alertError')
    }
  }

  return (
    <div>
      <div className={classes.container}>
        <FormControl className={classes.inputContainer}>
                  <InputLabel>{t("settingsPage.changeEmail")}</InputLabel>
          <Input
            onChange={handleChange}
            type="email"
            name="newEmail"
            value={values.newEmail || ""}
            error={errors.newEmail}
          />
        </FormControl>
        {errors.newEmail && (
          <FormHelperText className={classes.errorText} error={errors.newEmail}>
            {errors.newEmail}
          </FormHelperText>
        )}
        <div className={classes.buttonContainer}>
                  <Button onClick={handleSubmit} title={t("buttons.edit")} color="primary" />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    width: "250px",
    marginTop: "20px",
  },
  buttonContainer: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "250px",
    display: "flex",
    justifyContent: "center",
  },
  errorText: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default ChangeEmail;
