import { makeStyles } from "@material-ui/core";
import React from "react";
import Popover from "@material-ui/core/Popover";
import { Done } from "@material-ui/icons";
import { getUserFriendlyMessageDateTime, getMessageTime } from "helpers/dateHelpers";
// import './chatMessage.scss';
import "./chatMessage.css";

export default function ChatMessage({ direction, text, messageId, date, isSeen }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const popoverDate = getMessageTime(date);

  const open = Boolean(anchorEl);

  return (
    <>
      <div className={"chatMessage"}>
        <div className={["message", direction].join(" ")}>
          <div className="message-bubble">
            <div className="message-text-container">{text}</div>
            <div className="message-info">
              {direction === "out" && isSeen && (
                <div className="seen-mark">
                  <Done></Done>
                </div>
              )}
              <div className="date-info">{popoverDate}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
