import { makeStyles } from "@material-ui/core";
import React from "react";
import ChatMessage from "../chatMessage/chatMessage";
// import './chatMessageBlock.scss';
import "./chatMessageBlock.css";

export default function ChatMessageBlock({ messageBlock, openActiveRecipientProfile }) {
  const styles = useStyles();

  const { direction, avatar, messages } = messageBlock;

  const onRecipientAvatarClick = () => {
    openActiveRecipientProfile();
  };

  return (
    <div className={styles.chatMessageBlock}>
      <div className={styles.avatar}>
        {direction === "in" && (
          <img className="img-avatar" src={avatar} onClick={onRecipientAvatarClick} alt="Recipient avatar image"></img>
        )}
      </div>
      <div className={["messageContainer", direction].join(" ")}>
        {messages.map((item, idx) => (
          <ChatMessage
            key={item.id}
            messageId={item.id}
            text={item.text}
            date={item.date}
            isSeen={item.isSeen}
            direction={direction}
          ></ChatMessage>
        ))}
      </div>
      <div className={styles.avatar}>
        {direction === "out" && <img className="img-avatar" src={avatar} alt="User avatar image"></img>}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatMessageBlock: {
    display: "flex",
    flexDirection: "row",
    marginTop: "0.5rem",
  },
  avatar: {
    width: "2.5rem",
    height: "2.5rem",
    margin: "0 0.8rem",
    "& > img": {
      width: "2.5rem",
      height: "2.5rem",
      borderRadius: "50%",
    },
    paddingTop: "1rem",
    cursor: "pointer",
  },
}));
