export default ({ palette, breakpoints }) => {
  return {
    root: {
      minWidth: 256,
      marginBottom: "24px",
    },
    header: {
      height: "20px",
      padding: "0px 24px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      width: 36,
      height: 36,
      transform: "translateY(50%)",
      "& > img": {
        margin: 0,
        backgroundColor: "white",
      },
      [breakpoints.up("sm")]: {
        width: 36,
        height: 36,
      },
    },
    divider: {
      height: "1px !important",
      marginBottom: 14 - 1, // minus 1 due to divider height
      [breakpoints.up("sm")]: {
        marginBottom: 14 - 1, // minus 1 due to divider height
      },
    },
    extra: {
      marginTop: "15px",
      textTransform: "uppercase",
      fontSize: 12,
      color: palette.grey[500],
      letterSpacing: "2px",
    },
  };
};
