import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from 'components/ui/Checkbox/checkbox';
import Select from 'components/ui/Inputs/Select';
import InputText from 'components/ui/Inputs/InputText';
import MultipleSelect from 'components/ui/Inputs/MultipleSelect';
import { substructDate } from 'helpers/dateHelpers';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const checkboxLookups = [{ id: true }, { id: false }];
const RegistrationDateToLookups = [{ id: substructDate(1), name: 'День' }, { id: substructDate(7), name: 'Неделя' }, { id: substructDate(30), name: 'Месяц' }];

const BottomSearch = ({
  lookups,
  searchValues,
  selectCurrentValue,
  currentUser
}) => {
  const classes = useStyles();
    const { t, i18n } = useTranslation();

  return (
    <>
      <hr className={classes.line}></hr>
      <Grid container spacing={3} >
        <Grid item xs={4}>
          <MultipleSelect
            searchPage={true}
            value={searchValues.currentRelationshipStatusesIds}
            setValue={selectCurrentValue}
            title={t('searchPage.currentRelationshipStatusesIds')}
            lookups={lookups.currentRelationshipStatuses}
            type={'currentRelationshipStatusesIds'}
          />
          <MultipleSelect
            searchPage={true}
            value={searchValues.Kids}
            setValue={selectCurrentValue}
            title={t('searchPage.kids')}
            lookups={lookups.kids}
            type={'Kids'}
          />
          <div className={classes.checkboxHolder}>
            <Checkbox
              style={{justifySelf: 'self-start'}}
              setValue={selectCurrentValue}
              title={t('searchPage.onlyMonogamy')}
              lookups={checkboxLookups}
              type={'onlyMonogamy'}
              value={searchValues.onlyMonogamy} />
             <Checkbox
              style={{justifySelf: 'self-start'}}
              setValue={selectCurrentValue}
              title={t('searchPage.lookingForSponsorship')}
              lookups={checkboxLookups}
              type={'LookingForSponsorship'}
              value={searchValues.LookingForSponsorship} />
            </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ marginTop: '10px' }}>
            <Checkbox
              style={{justifySelf: 'self-start'}}
              setValue={selectCurrentValue}
              title={`ЗСУ 🫡`}
              lookups={checkboxLookups}
              type={'ZSU'}
              value={searchValues.ZSU} />
          </div>
        </Grid>
        <Grid item xs={4}>
        <Select
          searchPage={true}
          value={searchValues.PositionId}
          title={t('searchPage.positionId')}
          lookups={lookups.positions}
          setValue={selectCurrentValue}
          type={'PositionId'}
        />
        <Select
          searchPage={true}
          value={searchValues.LevelId}
          title={t('searchPage.levelId')}
          lookups={lookups.level}
          setValue={selectCurrentValue}
          type={'LevelId'}
        />   
          <Select
            searchPage={true}
            value={searchValues.LanguageId}
          title={t('searchPage.languageId')}
            lookups={lookups.languages}
            setValue={selectCurrentValue}
            type={'LanguageId'}
          />
          <InputText
            value={searchValues.Company}
            title={t('searchPage.company')}
            setValue={selectCurrentValue}
            type={'Company'}
          />
          <div className={classes.checkboxHolder}>
            <Checkbox
              style={{justifySelf: 'self-start'}}
              setValue={selectCurrentValue}
              title={'Remote'}
              lookups={checkboxLookups}
              type={'CanWorkRemotely'}
              value={searchValues.CanWorkRemotely} />
          </div>
        </Grid>
      </Grid>

      <div className={classes.row} style={{marginTop: '20px'}}>
        {currentUser.role === 'Admin' && <Select
          searchPage={true}
          value={searchValues.RegistrationDateTo}
          title={t('searchPage.registrationDateTo')}
          setValue={selectCurrentValue}
          lookups={RegistrationDateToLookups}
          type={'RegistrationDateTo'}
        />
        }
        {currentUser.role === 'Admin' && <InputText
          value={searchValues.Email}
          title={'Email'}
          setValue={selectCurrentValue}
          type={'Email'}
        />
        }
        
        {currentUser.role === 'Admin' && 
        <div style={{alignSelf: 'flex-end'}}>
          <Checkbox
          style={{justifySelf: 'self-start'}}
          setValue={selectCurrentValue}
          title={t('searchPage.IsBlocked')}
          type={'IsBlocked'}
          lookups={checkboxLookups}
          value={searchValues.IsBlocked} />
        </div>
        }
      </div>

      <div className={classes.lastElementPadding} />
    </>
  );
};

const useStyles = makeStyles(({
  typography: {
    paddingLeft: '0',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'flex-end',
  },
  row: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '33.3% 33.3% 33.3%',
    gridColumnGap: "24px",

  },
  lastElementPadding: {
    marginBottom: '10px',
  },
  line: {
    margin: '40px 0 20px',
    opacity: '.4',
  },
  checkboxHolder: {
    marginTop: '10px',
    marginLeft: '-10px',
  }
}));

export default BottomSearch;
