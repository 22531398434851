import Validator from "validator";

export default function validateRegistration(values) {
  let errors = {};

  if (!values.email.trim()) {
    errors.email = "Введите почту";
  } else if (!Validator.isEmail(values.email)) {
    errors.email = "Не корректный адрес почты";
  }

  return errors;
}
