import React from "react";
import Picker from "emoji-picker-react";
// import './chatPaneControls.scss';
import "./chatPaneControls.css";

export default class EmojiPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  onEmojiClick = (event, emojiObject) => {
    this.props.handleEmojiClick(emojiObject);
  };

  render() {
    return (
      <div
        style={{
          top: this.props.modalCoordinates.y,
          left: this.props.modalCoordinates.x,
          position: "absolute",
        }}
      >
        <Picker onEmojiClick={this.onEmojiClick} preload={true} native></Picker>
      </div>
    );
  }
}
