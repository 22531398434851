import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import AccountIcon from "@material-ui/icons/AccountCircle";
import { useGrowIconButtonStyles } from "@mui-treasury/styles/iconButton/grow";

import { ImagesService } from "services/images-service";

const GrowIconButton = ({ choosedImage }) => {
  const defaultGrowStyles = useGrowIconButtonStyles();
  const styles = useStyles();

  const onDeleteClick = () => {
    ImagesService.deleteImage(choosedImage.id);
    window.location.reload();
  };

  const onSetAvatarClick = () => {
    ImagesService.setPrimary(choosedImage.userId, choosedImage.id);
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div onClick={onDeleteClick}>
        <IconButton classes={defaultGrowStyles}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div onClick={onSetAvatarClick} className={styles.rightButtonContainer}>
        <IconButton classes={defaultGrowStyles}>
          <AccountIcon />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "15px",
    marginLeft: "15px",
    display: "flex",
    flexDirection: "row",
  },
  typography: {
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 1300,
    marginBottom: "1px",
  },
  button: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  iconRoot: {
    minWidth: "40px",
  },
  rightButtonContainer: {
    marginLeft: "10px",
  },
}));

export default GrowIconButton;

/*

 <Popper
        ref={ref}
        className={styles.popper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List>
                <ListItem
                  button
                  className={styles.button}
                  onClick={onDeleteClick}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={styles.listItemText}
                    primary={'Удалить'}
                  />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  className={styles.button}
                  onClick={onSetAvatarClick}
                >
                  <ListItemIcon>
                    <AccountIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={styles.listItemText}
                    primary={'Сделать аватаркой'}
                  />
                </ListItem>
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>

      */
