import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

const ChatService = {
  getChats,
  getChatsAdm,
  getChatMessages,
  getChatMessagesAdm,
  markAsSeen,
  sendMessage,
};

const suffix = "Messages";

function getChats() {
  return fetchWrapper.get(`${API_URL}/${suffix}/dialogs`);
}

function getChatsAdm(id) {
  return fetchWrapper.get(`${API_URL}/${suffix}/dialogs-admin/${id}`);
}

function getChatMessages(params) {
  if (!params.partnerId) {
    throw new Error("Cannot fetch chat messages. Partner ID is missing");
  }
  let url = `${API_URL}/${suffix}/dialog?PartnerId=${params.partnerId}`;

  if (params.pageSize) {
    url += `&Filter.PageSize=${params.pageSize}`;
  }
  if (params.currentPage) {
    url += `&Filter.CurrentPage=${params.currentPage}`;
  }
  if (params.endDate) {
    url += `&EndDate=${params.endDate}`;
  }
  if (params.startDate) {
    url += `&StartDate=${params.startDate}`;
  }

  return fetchWrapper.get(url);
}

function getChatMessagesAdm(params) {
  if (!params.userId) {
    throw new Error("Cannot fetch chat messages. User ID is missing");
  }
  let url = `${API_URL}/${suffix}/dialog-admin?UserId=${params.userId}`;

  if (!params.partnerId) {
    throw new Error("Cannot fetch chat messages. Partner ID is missing");
  }
  url += `&PartnerId=${params.partnerId}`;

  if (params.pageSize) {
    url += `&Filter.PageSize=${params.pageSize}`;
  }
  if (params.currentPage) {
    url += `&Filter.CurrentPage=${params.currentPage}`;
  }
  if (params.endDate) {
    url += `&EndDate=${params.endDate}`;
  }
  if (params.startDate) {
    url += `&StartDate=${params.startDate}`;
  }

  return fetchWrapper.get(url);
}

function markAsSeen(partnerId) {
  return fetchWrapper.post(`${API_URL}/${suffix}/mark-seen`, { partnerId });
}

function sendMessage(payload) {
  return fetchWrapper.put(`${API_URL}/${suffix}`, payload);
}

export default ChatService;
