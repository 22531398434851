import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";

import MainLayout from "components/layouts/MainLayout/index";
import { AuthService } from "services/auth-service";
import { InfoService } from "services/info-service";

import Loader from "components/ui/Loader/loader";

const PrivateRoute = ({ state, path, exact, component: Component }) => {
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        AuthService.userValue ? (
          !isLoading && (
            <MainLayout currentUser={state.currentUser}>
              <Component
                currentUser={state.currentUser}
                setCurrentUser={state.setCurrentUser}
                anotherUser={state.anotherUser}
                lookups={state.lookups}
                favorites={favorites}
                setFavorites={setFavorites}
                imagesPath={state.imagesPath}
              />
            </MainLayout>
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
