import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "125px",
    paddingTop: "4px",
    paddingBottom: "4px",
    marginRight: "5px",
  },
  input: {
    display: "none",
  },
  startIcon: {
    fontSize: "21px",
  },
}));

export default function UploadButtons({ uploadImage }) {
  const classes = useStyles();
    const { t, i18n } = useTranslation();

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={uploadImage}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          classes={{
            root: classes.root,
            startIcon: classes.startIcon,
          }}
          startIcon={<AddPhotoAlternateIcon />}
        >
                  {t("buttons.add")}
        </Button>
      </label>
    </div>
  );
}

/*import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'

export default function UploadButton({ uploadImage }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <input
        onChange={uploadImage}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
      />
      <label>
        <Button
          variant="contained"
          color="primary"
          size="small"
          component="span"
          className={classes.button}
          startIcon={<ImageIcon />}
        >
          Добавить
      </Button>
      </label>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  uploadIcon: {
    color: '#383797'
  },
  icon: {
    padding: '0'
  }
}))
*/
