import React from "react";
import { TextField, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const TextInput = React.memo(function TextInput({ setValue, value, type, title, multiline }) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      defaultValue={value ? value : ""}
      label={title}
      className={multiline ? classes.multiline : classes.textField}
      onChange={(e) => setValue(type, e.target.value)}
      multiline={multiline ? true : false}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  textField: {
    // maxWidth: '190px',
    // background: "white",
  },
  multiline: {
    // background: "white",
  },
}));

export default React.memo(TextInput);

//.MuiOutlinedInput-inputMarginDens
/*
InputProps={userPage ? {
          classes: {
            input: classes.input
          }
        } : null}
        */
