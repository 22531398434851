import React, { useState, useEffect } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { UserService } from "services/user-service";
import { useTranslation } from 'react-i18next';

const ShowMe = () => {
  const [checked, setChecked] = useState(false);
    const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    UserService.showUserInSearchChange(checked);
  };

    useEffect(() => {
        let isMounted = true; // Флаг для отслеживания состояния компонента

        UserService.showUserInSearchGet().then((res) => {
            if (isMounted) {
                setChecked(res);
            }
        });

        return () => {
            isMounted = false; // Устанавливаем флаг в false при размонтировании компонента
        };
    }, []);

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} color="primary" />}
          label={t("settingsPage.closeProfile")}
    />
  );
};

export default ShowMe;
