import React from "react";
import { lastSeenDate } from "helpers/functions";
// import './index.scss';
import "./index.css";

const LastSeenOnline = ({ anotherUser }) => {
  if (!anotherUser) return null;
  const { isOnline, lastSeen } = anotherUser;
  if (isOnline) return <div className="is-online" />;
  if (lastSeen) {
    return <div className="last-seen">{lastSeenDate(lastSeen)}</div>;
  }
  return null;
};

export default LastSeenOnline;
