import { makeStyles, withStyles, Button, Modal } from "@material-ui/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons";
// import './genderPickerModal.scss';
import "./genderPickerModal.css";
import { styled } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";

export default class GenderPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  onMaleClick = () => {
    this.props.handleGenderClick(true);
  };

  onFemaleClick = () => {
    this.props.handleGenderClick(false);
  };

  ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    "&:hover": {
      backgroundColor: pink[700],
    },
  }));

  render() {
    return (
      <div className="gender-picker-modal">
        <div className="gender-picker-title">Для того чтобы завершить регистрацию, укажите пожалуйста ваш пол.</div>
        <div className="gender-picker-body">
          <this.ColorButton
            className="gender-picker-button"
            onClick={this.onFemaleClick}
            type={"button"}
            color={"primary"}
            variant="contained"
          >
            <div className="icon">
              <FontAwesomeIcon icon={faVenus} />
            </div>
            <div className="description">Я девушка</div>
          </this.ColorButton>
          <Button
            className="gender-picker-button"
            onClick={this.onMaleClick}
            type={"button"}
            color={"primary"}
            variant="contained"
          >
            <div className="icon">
              <FontAwesomeIcon icon={faMars} />
            </div>
            <div className="description">Я парень</div>
          </Button>
        </div>
        <div className="gender-picker-modal-background"></div>
      </div>
    );
  }
}
