import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Fade, Paper, Popper } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import PopperList from "./popperList";

export default function PositionedPopper({ title, alertShow, showModal, user, isAdmin, viewChat }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.root}>
        <Popper open={open} anchorEl={anchorEl} placement="bottom" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopperList
                  user={user}
                  alertShow={alertShow}
                  setOpen={setOpen}
                  open={open}
                  showModal={showModal}
                  isAdmin={isAdmin}
                  viewChat={viewChat}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
        <Button variant="contained" color="secondary" onClick={handleClick}>
          {title}
        </Button>
      </div>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  typography: {
    padding: theme.spacing(2),
  },
}));
