import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button, Paper } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InfoForm from "./infoForm";

import useWindowDimensions from "hooks/useWindowDimension";

import Gallery from "./components/ImageGallery/imageGallery";
import PositionedPopper from "./components/positionedPopper";
import Alert from "components/ui/Alert/Alert";
import ModalMessage from "components/ui/Modals/ModalMessage";
import Loader from "components/ui/Loader/loader";

import { UserService } from "services/user-service";
import { useTranslation } from 'react-i18next';

const UserPage = ({ currentUser, setCurrentUser, lookups, imagesPath }) => {
  const styles = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [isAdmin] = useState(currentUser?.role === "Admin");

  const [anotherUser, setAnotherUser] = useState(null);
  const match = useRouteMatch();
  const { width } = useWindowDimensions();

  const alertShow = () => setOpenAlert(true);
  const alertClose = () => setOpenAlert(false);
  const modalShow = () => setOpenMessageModal(true);
  const modalClose = () => setOpenMessageModal(false);

  const startChat = () => {
    history.push(`/chat/${anotherUser.user.id}`);
  };

  const viewChat = () => {
    if (isAdmin) {
      history.push(`/admin-chat/${anotherUser.user.id}`);
    }
  };

  const isCurrentUser = () => Number(match.params.id) === currentUser?.id;

  useEffect(() => {
    let isMounted = true;
    if (Number(match.params.id) !== currentUser?.id) {
      setIsLoading(true);
      let id = Number(match.params.id);
      UserService.getUserById(id)
        .then((res) => {
          if (isMounted) {
            setAnotherUser(res);
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    } else {
        setAnotherUser(null);
    }
    return () => {
      isMounted = false;
    };
  }, [match.params.id]);
  const user = anotherUser ? anotherUser.user : currentUser;

  return (
    <Paper className={styles.page}  outlined="true" elevation={3}>
      {openAlert && <Alert alertShow={alertShow} alertClose={alertClose} />}
      {openMessageModal && <ModalMessage showModal={modalShow} closeModal={modalClose} />}
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
          <Container className={styles.content}>
          <Grid item xs={12} sm={12} md={5} lg={5} className={styles.leftContainerGrid}>
            <div className={styles.leftContainer}>
              <Container className={styles.avatarContainer} direction="row">
                <Avatar
                  className={styles.avatar}
                  src={`${imagesPath}/${isCurrentUser() ? user?.primaryImage : user?.primaryImage}`}
                />
              </Container>
              {anotherUser && (
                <div className={styles.buttonsContainer}>
                  <Button
                    className={styles.button}
                    variant="contained"
                    color="primary"
                    onClick={startChat}
                    startIcon={<MailOutlineIcon />}
                  >
                    Написать
                  </Button>
                  <PositionedPopper
                    user={anotherUser}
                    title={"ДЕЙСТВИЯ"}
                    alertShow={alertShow}
                    showModal={modalShow}
                    isAdmin={isAdmin}
                    viewChat={viewChat}
                  />
                </div>
              )}
              {width > 960 && <Gallery imagesPath={imagesPath} images={user.images} anotherUser={anotherUser} />}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7} className={styles.InfoFormContainer}>
            <InfoForm
              lookups={lookups}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              anotherUser={anotherUser?.user}
            />
          </Grid>
          {width < 960 && <Gallery images={user.images} imagesPath={imagesPath} anotherUser={anotherUser} />}
          </Container>
        )}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  page: {
    background: "#F9F9F9",
    margin: "20px 0",
  },
  InfoFormContainer: {
    background: "white",
    padding: "0",
    margin: 0,
  },
  leftContainerGrid: {
    backgroundColor: "rgb(81, 45, 168, 0.1)",
    padding: "0",
    margin: 0,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "250px",
  },
  content: {
    padding: 0,
    margin: 0,
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }, //mobile
  },
  avatar: {
    height: "165px",
    width: "165px",
    border: "3px solid white",
    boxShadow: "0 12px 38px 1px rgba(0,0,0,.10), 0 5px 10px 8px rgba(0,0,0,.07), 0 9px 10px -5px rgba(0,0,0,.2)",
  },
  avatarContainer: {
    marginTop: "25px",
    marginBottom: "25px",
    justifyContent: "center",
    display: "flex",
  },
  buttonsContainer: {
    marginBottom: "25px",
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  divider: {
    marginTop: "30px",
    marginLeft: "15px",
    marginRight: "15px",
  },
}));

export default UserPage;
