import { makeStyles } from "@material-ui/core";
import { getMessageDate } from "helpers/dateHelpers";
import { getFullBlobUrl } from "helpers/blobHelpers";
import React from "react";

export default function ChatListItemShort({ chatItem, isActive }) {
  const { partnerId, senderId, partnerName, primaryImage, isOnline, isSeen } = chatItem;
  const styles = useStyles();

  return (
    <div className={`${styles.chatItem} ${isActive ? "active" : ""}`}>
      <div className={styles.userSection}>
        <div className={styles.imageWrapper}>
          <img src={getFullBlobUrl(primaryImage)} alt={`User profile image for ${partnerName}`}></img>
          {isOnline && <div className={styles.onlineIndicator}></div>}
          {!isSeen && partnerId === senderId && <div className={styles.messageCounter}>!</div>}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "1px",
    cursor: "pointer",
    height: "2.5rem",
    borderRight: "3px solid transparent",
    "&.active": {
      backgroundColor: "#eceff1",
      borderRight: "3px solid #212121",
    },
    "&:hover": {
      backgroundColor: "#fafafa",
    },
    paddingTop: "0.75rem",
    paddingBottom: "1rem",
    overflowY: "hidden",
  },
  userSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageWrapper: {
    position: "relative",
    width: "2rem",
    height: "2rem",
    marginLeft: "0.1rem",
    marginRight: "0.1rem",
    "& > img": {
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      display: "block",
      fontSize: "0.3rem",
    },
  },
  onlineIndicator: {
    width: "0.5rem",
    height: "0.5rem",
    borderRadius: "50%",
    backgroundColor: "limegreen",
    position: "absolute",
    right: "0",
    bottom: "0",
  },
  messageCounter: {
    backgroundColor: "#F44336",
    color: "#FFF",
    borderRadius: "50%",
    fontSize: "0.675rem",
    height: "0.9rem",
    width: "0.9rem",
    margin: 0,
    padding: 0,
    textAlign: "center",
    lineHeight: "0.9rem",
    fontWeight: "bold",
    position: "absolute",
    right: "-0.3rem",
    top: "-0.3rem",
  },
}));
