import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const SettingsService = {
  getSettingsStatus,
  setSettingsStatus,
};

function getSettingsStatus() {
  return fetchWrapper.get(`${API_URL}/SystemSettings`);
}

function setSettingsStatus(settingsObj) {
  return fetchWrapper.post(`${API_URL}/SystemSettings`, settingsObj);
}
