import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import ChatPaneBody from "./chatPaneBody/chatPaneBody";
import ChatPaneControls from "./chatPaneControls/chatPaneControls";
import ChatPaneHeader from "./chatPaneHeader/chatPaneHeader";

export default function ChatPane({
  activeRecipient,
  currentUser,
  messageBlocks,
  messageDays,
  sendMessage,
  isSendingInProgress,
  getOlderMessages,
  noOlderMessagesAvailable,
  isLoadingOlderMessages,
  openActiveRecipientProfile,
}) {
  const styles = useStyles();

  return (
    <div className={styles.chatPaneContainer}>
      <div className={styles.chatHeader}>
        <ChatPaneHeader
          activeRecipient={activeRecipient}
          openActiveRecipientProfile={openActiveRecipientProfile}
        ></ChatPaneHeader>
      </div>
      <ChatPaneBody
        activeRecipient={activeRecipient}
        currentUser={currentUser}
        messageBlocks={messageBlocks}
        messageDays={messageDays}
        getOlderMessages={getOlderMessages}
        noOlderMessagesAvailable={noOlderMessagesAvailable}
        isLoadingOlderMessages={isLoadingOlderMessages}
        openActiveRecipientProfile={openActiveRecipientProfile}
      ></ChatPaneBody>
      <div className={styles.chatControls}>
        <ChatPaneControls sendMessage={sendMessage} isSendingInProgress={isSendingInProgress}></ChatPaneControls>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatPaneContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chatHeader: {
    flex: "0 1 auto",
  },
  chatControls: {
    flex: "0 0 7rem",
  },
}));
