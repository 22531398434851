import { API_URL } from "config";
import { AuthService } from "services/auth-service";
import isJsonString from "helpers/isJsonString";
import { history } from "helpers/history";

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  handleResponse,
  authHeader,
};

function get(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function authHeader(url) {
  const user = AuthService.userValue;

  const isLoggedIn = user && user.accessToken;
  const isApiUrl = url.startsWith(API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.accessToken}` };
  } else {
    return {};
  }
}

function handleResponse(response, error) {
  return response.text().then((text) => {
    let data;
    if (isJsonString(text)) {
      data = text && JSON.parse(text);
    } else {
      data = text;
    }
    if (!response.ok) {
      if ([401, 403].includes(response.status) && AuthService.userValue) {
        AuthService.logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
