import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import Checkbox from 'components/ui/Checkbox/checkbox';
import PersonIcon from "assets/person.png";
import HeartIcon from "assets/heart.jpeg";
import Work from "assets/work.jpg";
import cn from "classnames";

import Select from "components/ui/Inputs/Select";
import InputText from "components/ui/Inputs/InputText";
import { getNums } from "helpers/functions";
import { yesStr, noStr, checkedState } from "helpers/stringValues";
import { UserService } from "services/user-service";

import CardHeader from "./components/Divider/cardHeader";
import CountriesCities from "components/ui/Inputs/countriesCities/countriesCities";
import { useTranslation } from 'react-i18next';


const ages = getNums(18, 100);
const height = getNums(100, 250);

const EditForm = ({
    currentUser,
    setCurrentUser,
    lookups,
    userValues,
    userValuesForEdit,
    setUserValuesForEdit,
    openEditForm,
    setOpenEditForm,
    selectCurrentValue,
}) => {
    const styles = useStyles();
    const [pickedCountry, setPickedCountry] = useState(currentUser ? { countryName: currentUser.countryName, id: currentUser.countryId, name: currentUser.countryName } : {});
    const [pickedCity, setPickedCity] = useState(currentUser ? { cityName: currentUser.cityName, id: currentUser.cityId, name: currentUser.cityName } : {});
    const [inputCitiesValue, setInputCitiesValue] = useState(currentUser?.cityName);
    const [inputCountriesValue, setInputCountriesValue] = useState(currentUser?.countryName);
    const { t, i18n } = useTranslation();
    const onBackButtonClick = () => {
        setUserValuesForEdit({ ...userValues });
        setOpenEditForm(false);
        setInputCitiesValue(currentUser?.cityName)
    };
    const checkboxLookups = [{ name: true }, { name: false }];

    function getLookupsIdByName(lookups, name) {
        return lookups?.find((item) => item?.name === name)?.id;
    }

    let editInfo = {
        firstName: userValuesForEdit.firstName,
        lastName: userValuesForEdit.lastName,
        email: currentUser.email,
        role: currentUser.role,
        genderId: getLookupsIdByName(lookups?.genders, userValuesForEdit?.genderId),
        age: Number(userValuesForEdit.age),
        height: Number(userValuesForEdit.height),
        weight: 170,
        kidsId: getLookupsIdByName(lookups?.kids, userValuesForEdit?.kids),
        sexualOrientationId: getLookupsIdByName(lookups?.sexualOrientations, userValuesForEdit?.sexualOrientations),
        about: userValuesForEdit.about,
        country: pickedCountry,
        countryId: pickedCountry?.id,
        cityId: pickedCity?.id,
        city: userValuesForEdit.city,
        lookingForGenderId: getLookupsIdByName(lookups?.lookingForGender, userValuesForEdit?.lookingForGenderId),
        lookingForDescription: userValuesForEdit.lookingForDescription,
        relationshipStatusId: getLookupsIdByName(
            lookups?.currentRelationshipStatuses,
            userValuesForEdit?.relationshipStatusId
        ),
        company: userValuesForEdit.company,
        positionId: getLookupsIdByName(lookups?.positions, userValuesForEdit?.positionId),
        levelId: getLookupsIdByName(lookups?.level, userValuesForEdit?.levelId),
        languageId: getLookupsIdByName(lookups?.languages, userValuesForEdit?.languageId),
        canWorkRemotely: userValuesForEdit.canWorkRemotely,
        showInSearchResults: currentUser.showInSearchResults,
        hasEnoughInfoForShowingInSearch: currentUser.hasEnoughInfoForShowingInSearch,
        primaryImageUrl: currentUser.primaryImage,
        imagesAddedToday: currentUser.imagesAddedToday,
        lastImageAddedDate: currentUser.lastImageAddedDate,
        complaints: currentUser.complaints,
        blockedTemporary: currentUser.blockedTemporary,
        unblockDate: null, //currentUser.unblockDate,
        blockedForever: currentUser.blockedForever,
        isDeleted: currentUser.isDeleted,
        hasUnreadMessages: currentUser.hasUnreadMessages,
        isOnline: currentUser.isOnline,
        lastSeen: currentUser.lastSeen,
        registrationDate: currentUser.registrationDate,
        id: currentUser.id,
        kinky: userValuesForEdit.kinky,
        sponsorship: userValuesForEdit.sponsorship,
    };

    const onSaveButtonClick = () => {
        UserService.postUser(editInfo)
            .then((res) => {
                setCurrentUser(res);
            })
            .then(() => {
                setOpenEditForm(false);
                window.scrollTo(0, 0);
            });
    };

    return (
        <div className={cn({ "form--edit": openEditForm })}>
            {!openEditForm ? (
                <>
                    <div className="info-block">
                        <CardHeader className={styles.cardHeader} image={PersonIcon} extra={t("userPage.mainInfo")} />
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.sex")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.genderId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.country")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.countryId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.city")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.cityId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.age")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.age}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.height")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.height}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.children")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.kids}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.orientation")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.sexualOrientations}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.aboutSelf")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.about}</Typography>
                            </Grid>
                        </Grid>

                        <div></div>
                    </div>
                    <div className="info-block">
                        <CardHeader className={styles.cardHeader} image={HeartIcon} extra={t("userPage.relations")} />
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.find")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.lookingForGenderId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.relations")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.relationshipStatusId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.lookingForSponsor")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{checkedState(userValuesForEdit.sponsorship)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>Kinky:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{checkedState(userValuesForEdit.kinky)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.description")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.lookingForDescription}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="info-block">
                        <CardHeader className={styles.cardHeader} image={Work} extra={t("userPage.work")} />
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.specialty")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.positionId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.level")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.levelId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.languages")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.languageId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.company")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{userValuesForEdit.company}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.remoteWork")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{checkedState(userValuesForEdit.canWorkRemotely)}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </>
            ) : (
                <>
                    <div className="edit-block">
                            <CardHeader className={styles.cardHeader} image={PersonIcon} extra={t("userPage.mainInfo")} />
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.sex")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    disabled={true}
                                    lookups={lookups.genders}
                                    type="genderId"
                                    key="genderId"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.genderId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.country")}:</Typography>
                                    <Typography className={styles.citi}>{t("userPage.city")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <CountriesCities
                                    pickedCity={pickedCity}
                                    setPickedCity={setPickedCity}
                                    pickedCountry={pickedCountry}
                                    setPickedCountry={setPickedCountry}
                                    inputCitiesValue={inputCitiesValue}
                                    inputCountriesValue={inputCountriesValue}
                                    setInputCountriesValue={setInputCountriesValue}
                                    selectCurrentValue={selectCurrentValue}
                                    setInputCitiesValue={setInputCitiesValue}
                                    userPage={true}
                                    key="countriesCities"
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.age")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    type="age"
                                    key="age"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.age}
                                    numeric={true}
                                    lookups={ages}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.height")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    type="height"
                                    key="height"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.height}
                                    numeric={true}
                                    lookups={height}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.children")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.kids}
                                    type="kids"
                                    key="kids"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.kids}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.orientation")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.sexualOrientations}
                                    type="sexualOrientations"
                                    key="sexualOrientations"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.sexualOrientations}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.aboutSelf")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    type="about"
                                    key="about"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.about}
                                    multiline={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="edit-block">
                        <CardHeader className={styles.cardHeader} image={HeartIcon} extra={t("userPage.relations")} />
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.find")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.lookingForGender}
                                    type="lookingForGenderId"
                                    key="lookingForGenderId"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.lookingForGenderId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.relations")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.currentRelationshipStatuses}
                                    type="relationshipStatusId"
                                    key="relationshipStatusId"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.relationshipStatusId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem} alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{t("userPage.lookingForSponsor")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Checkbox
                                    style={{justifySelf: 'self-start'}}
                                    setValue={selectCurrentValue}
                                    lookups={checkboxLookups}
                                    type={'sponsorship'}
                                    key="sponsorship"
                                    value={userValuesForEdit.sponsorship}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem} alignItems="center">
                            <Grid item xs={4}>
                                <Typography>Kinky:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Checkbox
                                    style={{justifySelf: 'self-start'}}
                                    setValue={selectCurrentValue}
                                    lookups={checkboxLookups}
                                    type={'kinky'}
                                    key="kinky"
                                    value={userValuesForEdit.kinky} />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.description")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    type="lookingForDescription"
                                    key="lookingForDescription"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.lookingForDescription}
                                    multiline={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="edit-block">
                            <CardHeader className={styles.cardHeader} image={Work} extra={t("userPage.work")} />
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.specialty")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.positions}
                                    type="positionId"
                                    key="positionId"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.positionId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.level")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.level}
                                    type="levelId"
                                    key="levelId"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.levelId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.Languages")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    lookups={lookups.languages}
                                    type="languageId"
                                    key="languageId"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.languageId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                    <Typography>{t("userPage.company")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    type="company"
                                    key="company"
                                    setValue={selectCurrentValue}
                                    value={userValuesForEdit.company}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.blockItem}>
                            <Grid item xs={4}>
                                <Typography>{t("userPage.remoteWork")}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Checkbox
                                    style={{justifySelf: 'self-start'}}
                                    setValue={selectCurrentValue}
                                    lookups={checkboxLookups}
                                    type={'canWorkRemotely'}
                                    key="canWorkRemotely"
                                    value={userValuesForEdit.canWorkRemotely} />
                            </Grid>
                        </Grid>
                    </div>

                    <div className={styles.backAndSaveButtonsContainer}>
                        <Button onClick={onBackButtonClick} className={styles.button} variant="contained" color="primary">
                                {t("buttons.back")}
                        </Button>
                        <Button className={styles.saveButton} onClick={onSaveButtonClick} variant="contained" color="primary">
                                {t("buttons.save")}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    blockItem: {
        margin: "7px 0",

        "&:first-of-type": {
            marginTop: "14px",
        },

        "&:last-of-type": {
            marginBottom: "14px",
        },
    },
    paragraphText: {
        width: "125px",
        marginLeft: theme.spacing(3),
        marginTop: "14px",
        marginRight: theme.spacing(1),
    },
    paragraphTextEdit: {
        width: "125px",
        marginLeft: theme.spacing(3),
        marginTop: "20px",
        marginRight: theme.spacing(1),
    },
    paragraphInput: {
        marginLeft: theme.spacing(2),
        marginTop: "14px",
        marginBottom: theme.spacing(1),
    },
    paragraphValue: {
        marginTop: "14px",

        "&:last-child": {
            marginBottom: "0px",
        },
    },
    backAndSaveButtonsContainer: {
        marginBottom: "30px",
        display: "flex",
        justifyContent: "center",
        marginTop: "14px",
    },
    cardHeader: {
        height: "25px",
        marginBottom: "14px",
    },
    text: {
        fontSize: "16px",
        color: "#4C4744",
    },
    saveButton: {
        marginLeft: "20px",
    },
    citi: {
        marginTop: "20px",
    }
}));

export default EditForm;
