import { makeStyles } from "@material-ui/core";
import { getMessageDate } from "helpers/dateHelpers";
import { getFullBlobUrl } from "helpers/blobHelpers";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ChatListItem({ chatItem, isActive, activateChat }) {
  const { partnerId, senderId, partnerName, lastMessageText, sentDateTime, primaryImage, isSeen, isOnline } = chatItem;
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  const activateSelf = () => {
    activateChat(partnerId);
  };

  return (
    <div className={`${styles.chatItem} ${isActive ? "active" : ""}`} onClick={activateSelf}>
      <div className={styles.userSection}>
        <div className={styles.imageWrapper}>
          <img src={getFullBlobUrl(primaryImage)} alt={`User profile image for ${partnerName}`}></img>
          {isOnline && <div className={styles.onlineIndicator}></div>}
        </div>
        <div className={styles.infoSection}>
          <div className={styles.infoSectionTop}>
            <p className={[styles.userName, "chat-list-item-username"].join(" ")}>{partnerName}</p>
            {!isSeen && partnerId === senderId && <div className={[styles.messageCounter]}>!</div>}
          </div>
          <div className={styles.infoSectionBottom}>
            <p className={[styles.latestMessage, "chat-list-item-message"].join(" ")}>{`${
              partnerId === senderId ? "" : t("chat.you")
            } ${lastMessageText}`}</p>
            <div className={[styles.timeIndicator, "chat-list-item-time"].join(" ")}>
              <span>{getMessageDate(sentDateTime)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #eeeeee",
    cursor: "pointer",
    height: "3rem",
    borderRight: "3px solid transparent",
    "&.active": {
      backgroundColor: "#eceff1",
      borderRight: "3px solid #212121",
    },
    "&:hover": {
      backgroundColor: "#fafafa",
    },
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    overflowY: "hidden",
  },
  userSection: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageWrapper: {
    position: "relative",
    width: "2rem",
    height: "2rem",
    marginLeft: "1rem",
    marginRight: "0.3rem",
    "& > img": {
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      display: "block",
      fontSize: "0.3rem",
      objectFit: "cover",
    },
  },
  infoSection: {
    flexGrow: "2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0.4rem 0.4rem",
    width: "75%",
  },
  infoSectionTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  infoSectionBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  userName: {
    padding: "0.1rem",
    margin: "0.1rem",
    color: "#263238",
    fontWeight: 700,
    // fontSize: '0.9rem',
  },
  latestMessage: {
    padding: "0.1rem",
    margin: "0.1rem",
    color: "#616161",
    // fontSize: '0.75rem',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  timeIndicator: {
    // fontSize: '0.675rem',
    color: "#333333",
    textAlign: "center",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    fontWeight: "600",
  },
  messageCounter: {
    backgroundColor: "#F44336",
    color: "#FFF",
    borderRadius: "50%",
    fontSize: "0.675rem",
    height: "0.9rem",
    width: "0.9rem",
    margin: 0,
    padding: 0,
    textAlign: "center",
    lineHeight: "0.9rem",
    fontWeight: "bold",
  },
  onlineIndicator: {
    width: "0.5rem",
    height: "0.5rem",
    borderRadius: "50%",
    backgroundColor: "limegreen",
    position: "absolute",
    right: "0",
    bottom: "0",
  },
}));
