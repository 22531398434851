import React, { useState } from "react";
import { Slider,  Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CountriesCities from "components/ui/Inputs/countriesCities/countriesCities";
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/ui/Checkbox/checkbox';
import { purple } from "@material-ui/core/colors";

export default function SearchForm({ lookups, searchValues, selectCurrentValue, toggleOpen }) {
    const classes = useStyles();
    const [pickedCountry, setPickedCountry] = useState({});
    const [pickedCity, setPickedCity] = useState({});
    const [inputCitiesValue, setInputCitiesValue] = useState("");
    const [inputCountriesValue, setInputCountriesValue] = useState("");
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const checkboxLookups = [{ id: true }, { id: false }];
    const ages = [{ id: 18 }, { id: 100 }];
    const [value, setValue] = useState([18, 50]);
    const { t, i18n } = useTranslation();

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <CountriesCities
                        pickedCity={pickedCity}
                        setPickedCity={setPickedCity}
                        pickedCountry={pickedCountry}
                        setPickedCountry={setPickedCountry}
                        inputCitiesValue={inputCitiesValue}
                        selectCurrentValue={selectCurrentValue}
                        setInputCitiesValue={setInputCitiesValue}
                        searchValues={searchValues}
                        inputCountriesValue={inputCountriesValue}
                        setInputCountriesValue={setInputCountriesValue}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Grid container alignItems="center"> 
                        <Checkbox
                            style={{justifySelf: 'self-start'}}
                            setValue={selectCurrentValue}
                            title={t("searchPage.checkboxTitleGuy")}
                            lookups={checkboxLookups}
                            type={'lookingForBoy'}
                            value={searchValues.LookingForGenderId} />
                
                        <Checkbox
                            style={{justifySelf: 'self-start'}}
                            setValue={selectCurrentValue}
                            value={searchValues.LookingForGenderId}
                            type={'lookingForGirl'}
                            lookups={checkboxLookups}
                            title={t("searchPage.checkboxTitleGirl")} />

                    </Grid>
                    
                </Grid>
               
                <Grid item xs={4}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <div className={classes.ageBlock}>{t("searchPage.age")}: {value[0]}-{value[1]}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <Slider
                                value={value}
                                onChange={handleSliderChange}
                                aria-labelledby="input-slider"
                                type={"Age"}
                                lookups={ages}
                                color={'primary'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles(({
    ageBlock: {
        paddingTop: '12px',
        paddingBottom: '20px'
      },
}));