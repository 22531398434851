import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";

import Tabs from "components/ui/TabsMenu/tabs";
import Card from "./components/card";

//import { useApi } from '../../../core/fetchAPI';
//import AdminApi from './core/admin.api';

export default function AdminPage({ currentUser }) {
  const styles = useStyles();
  const history = useHistory();
  const [index, setIndex] = useState(0);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const TABS_MAP = [
    { label: "Жалобы", view: "complaints" },
    { label: "Сообщение пользователям", view: "messages" },
    { label: "Отправить e-mail всем пользователям", view: "email" },
    { label: "Остановка регистрации", view: "registration" },
    
  ];

  useEffect(() => {
    const viewName = query.get("view");
    if (!viewName) {
      return;
    }

    const activeTab = TABS_MAP.find((tab) => tab.view === viewName);
    if (!activeTab) {
      return;
    }
    const activeTabIndex = TABS_MAP.indexOf(activeTab);

    if (index !== activeTabIndex) {
      setIndex(activeTabIndex);
    }
  }, []);

  const activateTab = (tabId) => {
    history.replace(`/admin?view=${TABS_MAP[tabId].view}`);
    setIndex(tabId);
  };

  return (
    <Container className={styles.content}>
      <Grid item xs={4} className={styles.leftContainerGrid}>
        <Tabs tabs={TABS_MAP} value={index} onChange={(e, i) => activateTab(i)} />
      </Grid>
      <Grid item xs={8} className={styles.leftContainerGrid}>
        <Card index={index} currentUser={currentUser} />
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  page: {
    padding: 0,
    margin: 0,
  },
  content: {
    padding: 0,
    margin: "20px 0 0",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }, //mobile
  },
}));
