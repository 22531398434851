import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { FormControl, Typography } from "@material-ui/core";
import Button from "components/ui/Buttons/button";
import { Alert } from "@material-ui/lab";
import DialogAction from "components/ui/DialogAction";
import { UserService } from "services/user-service";
import { AuthService } from "services/auth-service";
import { useTranslation } from 'react-i18next';

export default function DeleteUser({ currentUser }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
    const { t, i18n } = useTranslation();

  const deleteUserById = () => {
    UserService.deleteUserById(currentUser.id)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          AuthService.logout();
        }, 1000);
      })
      .catch((err) => {
        setShowError(true);
      });
  };

  const handleDeleteUser = () => {
    setIsOpen(false);
    deleteUserById();
  };

  return (
    <div>
      <div className={classes.container}>
        <FormControl className={classes.textContainer}>
          <Typography variant="subtitle1" className={classes.text}>
                      {t("settingsPage.deleteProfile")}
          </Typography>
        </FormControl>
        <div className={classes.buttonContainer}>
                  <Button title={t("buttons.delete")} color="secondary" onClick={() => setIsOpen(true)} />
        </div>
      </div>
      <div>
              {showSuccess && <Alert severity="success">{t("settingsPage.deleteSuccess")}</Alert>}
              {showError && <Alert severity="error">{t("settingsPage.deleteError")}</Alert>}
      </div>
      <DialogAction
        isOpen={isOpen}
        onClickYes={handleDeleteUser}
        onClickNo={() => setIsOpen(false)}
        text={t("settingsPage.deleteMessage")}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  textContainer: {
    width: "350px",
    marginBottom: "20px",
  },
  text: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
  },
  buttonContainer: {
    marginBottom: "10px",
    width: "350px",
    display: "flex",
    justifyContent: "center",
  },
}));
