import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const styles = ({ palette, spacing, breakpoints }) => ({
  root: {
    margin: 0,
    padding: spacing(2),
    [breakpoints.only("lg")]: {
      width: "550px",
    },
    [breakpoints.only("md")]: {
      width: "450px",
    },
    [breakpoints.only("sm")]: {
      width: "400px",
    },
  },
  closeButton: {
    position: "absolute",
    right: spacing(1),
    top: spacing(1),
    color: palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MessageModal({ modalShow, modalClose }) {
  return (
    <div>
      <Dialog onClose={modalClose} aria-labelledby="customized-dialog-title" open={modalShow}>
        <DialogTitle id="customized-dialog-title" onClose={modalClose}>
          Опишите инцидент:
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth="true"
            id="outlined-multiline-static"
            multiline
            rows="8"
            variant="outlined"
            className={styles.textField}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={modalClose} color="primary" variant="outlined">
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
