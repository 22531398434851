import React from "react";
import { Dialog, DialogTitle, Button } from "@material-ui/core";
// import './index.scss';
import "./index.css";
import { useTranslation } from 'react-i18next';

const DialogAction = ({ text = null, isOpen = false, onClickYes = () => null, onClickNo = () => null }) => {
    const { t, i18n } = useTranslation();

    if (!text) return null;

  return (
    <Dialog onClose={onClickNo} open={isOpen}>
      <DialogTitle>{text}</DialogTitle>
      <div className="buttons">
        <Button variant="contained" color="secondary" onClick={onClickYes}>
                  {t("buttons.yes")}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickNo}>
                  {t("buttons.no")}
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogAction;
