import { API_URL } from "./../config";
import { fetchWrapper } from "./../helpers/fetchWrapper";

export const InfoService = {
  getLookups,
  getImagesPath,
};

function getLookups() {
    const lang = localStorage.getItem("i18nextLng")
    let num = 1;
    switch (lang) {
        case "en":
            num = 1
            break;
        case "ua":
            num = 2
            break;
        case "ru":
            num = 3
            break;
    
        default:
            break;
    }
    return fetchWrapper.get(`${API_URL}/Info/lookups?id=${num}`);
}

function getImagesPath() {
  return fetchWrapper.get(`${API_URL}/Info/images-path`);
}
