import React, { useEffect, useState } from "react";
import { CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { SettingsService } from "services/system-settings";
import StopRegistrationModals from "./stopRegistrationModals";

const styleContainer = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};

const StopRegistartion = ({ currentUser }) => {
  const [registartion, setRegistration] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openAskModal, setOpenAskModal] = useState(false);

  useEffect(() => {
    SettingsService.getSettingsStatus().then((res) => setRegistration(res.registrationIsOpened));
  }, []);

  const handleSubmit = () => {
    const obj = {
      id: currentUser.id,
      registrationIsOpened: registartion,
    };
    SettingsService.setSettingsStatus(obj).then(() => {
      setOpenAskModal(false);
      setOpenRegistration(true);
    });
  };

  return (
    <CardContent>
      <div style={styleContainer}>
        <FormControlLabel
          control={
            <Checkbox checked={registartion} onChange={(e) => setRegistration(e.target.checked)} color="primary" />
          }
          label="Остановить регистрацию"
        />
        <Button variant="contained" color="primary" onClick={() => setOpenAskModal(true)}>
          Отправить
        </Button>
      </div>
      <StopRegistrationModals
        openAskModal={openAskModal}
        setOpenAskModal={setOpenAskModal}
        openRegistration={openRegistration}
        setOpenRegistration={setOpenRegistration}
        handleSubmit={handleSubmit}
      />
    </CardContent>
  );
};

export default StopRegistartion;
