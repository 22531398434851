import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = ({ breakpoints }) => ({
  root: {
    [breakpoints.up("sm")]: {
      width: "100%",
      height: "100%",
    },
    [breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
    },
  },
});

const Content = ({ classes, content }) => <div className={classes.root}>{content}</div>;

Content.propTypes = {};
Content.defaultProps = {};

export default withStyles(styles)(Content);
