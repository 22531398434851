import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

import TopSearch from "./topSearchForm";
import BottomSearch from "./bottomSearchForm";
import Switch from "components/ui/Switch/switch";

const SearchForm = ({ lookups, onSearchClick, selectCurrentValue, searchValues, currentUser }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [toggleOpen, setToggleOpen] = useState(false);
  const [itFieldsOpen, setItFieldsOpen] = useState(false); 

  return (
    <Container className={classes.allSearchContainer}>
      <Paper outlined="true" elevation={3} className={classes.paper}>
        <Grid item xs={10} className={classes.searchContainer}>
          <TopSearch
            selectCurrentValue={selectCurrentValue}
            searchValues={searchValues}
            lookups={lookups}
            toggleOpen={toggleOpen}
          />
          {toggleOpen && (
            <BottomSearch
              setItFieldsOpen={setItFieldsOpen}
              currentUser={currentUser}
              selectCurrentValue={selectCurrentValue}
              searchValues={searchValues}
              lookups={lookups}
              itFieldsOpen={itFieldsOpen}
            />
          )}
        </Grid>

        <Grid item xs={2} className={classes.rightSearchContainer}>
            <Switch toggleOpen={toggleOpen} setToggleOpen={setToggleOpen} openedTitle={"Full"} closedTitle={"Mini"} />
          <div
            style={{ marginBottom: "10px" }}
            className={toggleOpen ? classes.bottomSwitchContainer : classes.switchContainer}
          >
            <div onClick={() => onSearchClick(0, true)}>
              <Button className={classes.button} variant="contained">
                {t("buttons.search")}
              </Button>
            </div>
          </div>
        </Grid>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  allSearchContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "58rem",
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
    marginBottom: "10px",
    padding: '30px',
  },
  searchContainer: {
    background: "transparent",
    color: "black",
  },
  rightSearchContainer: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'flex-end',
    textAlign: 'right',
    marginTop: '16px',
  },
  typography: {
    fontSize: 19,
    color: "white",
    fontWeight: "400",
    paddingRight: "10px",
  },
  topButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  bottomSwitchContainer: {
    bottom: "5px",
    left: "20px",
  },
  switchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  button: {
    color: "white",
    background: "#512da8",
    "&:hover": {
      backgroundColor: "#7E4CCB",
    },
  },
}));

export default SearchForm;
