import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";

import CountriesSelect from "./countries";
import CitiesSelect from "./cities";
import { debounce } from "lodash"
import { CitiesService } from "services/cities-service";
import { usePrevious } from "hooks/usePrevious";
import { useTranslation } from 'react-i18next';

const CountriesAndCities = React.memo(function CountriesAndCities({
    pickedCity,
    setPickedCity,
    pickedCountry,
    setPickedCountry,
    inputCitiesValue,
    setInputCitiesValue,
    setInputCountriesValue,
    inputCountriesValue,
    selectCurrentValue,
    searchValues,
    userPage,
}) {
    const classes = useStyles();
    const [searchedCountries, setSearchedCountries] = useState([]);
    const [searchedCities, setSearchedCities] = useState([]);
    const [topCountries, setTopCountries] = useState([]);
    const [topCities, setTopCities] = useState([]);
    const [visibleCountryList, setVisibleCountryList] = useState(false);
    const [visibleCityList, setVisibleCityList] = useState(false);
    const { t, i18n } = useTranslation();

    const enteredCity = searchedCities
        ? searchedCities.find((item) => item.name.toLowerCase() === inputCitiesValue.trim() || item.name === inputCitiesValue.trim())
        : false;
    const enteredTopCity = topCities
        ? topCities.find((item) => item.name.toLowerCase() === inputCitiesValue.trim() || item.name === inputCitiesValue.trim())
        : false;
    const choosedCity = enteredCity ? enteredCity : enteredTopCity ? enteredTopCity : false;
    const prevCountryId = usePrevious(pickedCountry?.id);

    useEffect(() => {
        let isFetching = true

        if (!sessionStorage.getItem('topCountries') || sessionStorage.getItem('topCountries').length === 0) {
            CitiesService.getTopCountries().then((res) => {
                if (isFetching) {
                    sessionStorage.setItem("topCountries", JSON.stringify(res));
                    setTopCountries(res)
                    console.log("CitiesService.getTopCountries ~ res:", res)
                }
            });
        }
        else {
            setTopCountries(JSON.parse(sessionStorage.getItem("topCountries")))
        }
        return () => {
            isFetching = false;
        }
    }, []);

    useEffect(() => {
        if (pickedCountry.id) {
            selectCurrentValue("countryId", pickedCountry.id);
            CitiesService.getTopCities(pickedCountry.id).then((res) => setTopCities(res));
            console.log("topCities",topCities)
        }

        if (!pickedCountry) {
            setInputCitiesValue("");
        }
    }, [pickedCountry]);

    useEffect(() => {
        if (choosedCity) {
            selectCurrentValue("cityId", choosedCity.id);
            setPickedCity(choosedCity);
            // setInputCitiesValue(choosedCity.nameRU);
        } else { selectCurrentValue("cityId", ''); }
    }, [choosedCity]);

    useEffect(() => {
        if (!userPage && pickedCountry?.id !== prevCountryId) {
            setInputCitiesValue("");
        }
        if (userPage && pickedCountry?.id !== prevCountryId) {
            if (!prevCountryId) {
                // setInputCitiesValue(choosedCity.nameRU);
            } else {
                setInputCitiesValue("");
            }
        }
    }, [userPage, pickedCountry]);

    useEffect(() => {
        if (pickedCity?.id && inputCitiesValue?.length < pickedCity?.name?.length) {
            setPickedCity({});

        }
    }, [pickedCity, inputCitiesValue]);

    useEffect(() => {
        if (pickedCountry?.id && inputCountriesValue?.length < pickedCountry?.name?.length) {
            setPickedCity({});
            setTopCities([]);
            setPickedCountry({})
        }
    }, [pickedCountry, inputCountriesValue]);

    const fetchSearchedCity = useCallback(debounce((pickedCountry, inputCitiesValue) => {
        if (!pickedCountry) return
        if (inputCitiesValue.length >= 2) {
            CitiesService.getSearchedEnteredCities(pickedCountry, inputCitiesValue.trim())
                .then((res) => {
                    setSearchedCities(res);

                    if ((res.length === 1 && res[0].name.toLowerCase() === inputCitiesValue.trim())
                        || (res.length === 1 && res[0].name.toLowerCase() === inputCitiesValue.trim())) {
                        console.log(res[0].name, inputCitiesValue)
                        console.log(res[0], inputCitiesValue)
                        setPickedCity(res)
                    }
                    console.log(pickedCountry)
                    console.log(pickedCity)
                });
        } else { setSearchedCities([]); }
    }, 300), [])

    const fetchSearchedCountry = useCallback(debounce((inputCountryValue) => {
        if (inputCountryValue.length >= 2) {
            CitiesService.getSearchedEnteredCountries(inputCountryValue.trim())
                .then((res) => {
                    setSearchedCountries(res);

                    if ((res.length === 1 && res[0].name.toLowerCase() === inputCountryValue.trim())
                        || (res.length === 1 && res[0].name.toLowerCase() === inputCountryValue.trim())) {
                        console.log(res[0].name, inputCountryValue)
                        setPickedCountry(res[0])

                    }
                })
        } else {
            setSearchedCountries([]);
        }
    }, 300), [])

    function changeInputCountryCitiesValue(value) {
        setInputCitiesValue(value);
        fetchSearchedCity(pickedCountry.id, value);
    }
    function changeInputCountriesValue(value) {
        setInputCountriesValue(value);
        fetchSearchedCountry(value);
    }

    function inputChooseItemInListClick(item) {
        setPickedCity(item);
        setInputCitiesValue(item.name || item);
    }

    function inputChooseItemInCountryListClick(item) {
        setPickedCountry(item);
        setInputCountriesValue(item.name || item);
        setSearchedCities([])
    }

    return (
        <div className={userPage ? classes.containerInUserPage : classes.container}>
            <div>
                {/* <div className={!userPage && classes.countriesContainer}> */}
                <CountriesSelect
                    inputChooseItemInListClick={inputChooseItemInCountryListClick}
                    topCountries={topCountries}
                    inputCountriesValue={inputCountriesValue}
                    searchedCountries={searchedCountries}
                    changeInputCountriesValue={changeInputCountriesValue}
                    userPage={userPage}
                    visibleCountryList={visibleCountryList}
                    setVisibleCountryList={setVisibleCountryList}
                    changeInputCountryCitiesValue={changeInputCountryCitiesValue}
                    setPickedCountry={setPickedCountry}
                    pickedCountry={pickedCountry}
                    title={t("userPage.country")}
                />
            </div>

            <div className={!userPage ? classes.citiesContainer : classes.citiesContainerInUserPage}>
                <CitiesSelect
                    topCities={topCities}
                    inputChooseItemInListClick={inputChooseItemInListClick}
                    searchedCities={searchedCities}
                    inputCitiesValue={inputCitiesValue}
                    pickedCountry={pickedCountry}
                    changeInputCountryCitiesValue={changeInputCountryCitiesValue}
                    setSearchedCities={setSearchedCities}
                    userPage={userPage}
                    visibleCityList={visibleCityList}
                    setVisibleCityList={setVisibleCityList}
                />
            </div>
        </div>
    );
});

const useStyles = makeStyles(() => ({
    containerInUserPage: {
        display: "flex",
        flexDirection: "column",
    },
    citiesContainerInUserPage: {
        // width: '50%',
        marginTop: "10px",
        position: "relative",
    },
    container: {
        display: "grid",
        gridTemplateColumns: "100%",
        gridColumnGap: "20px",
        marginTop: "-13px",

    },
    citiesContainer: {},
    countriesContainer: {
        backgroundColor: "transparent",
    },
    /*notchedOutline: {
        //borderColor: toggle ? 'green !important' : null
      }*/
}));

export default CountriesAndCities;
