import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import { useTranslation } from 'react-i18next';

export default function CitiesSelect({
    topCities,
    inputChooseItemInListClick,
    searchedCities,
    inputCitiesValue,
    pickedCountry,
    changeInputCountryCitiesValue,
    userPage,
    setSearchedCities, visibleCityList, setVisibleCityList
}) {
    const { t, i18n } = useTranslation();

    const [placeholder, setPlaceholder] = useState(t("userPage.city"));
    const dropdownRef = useRef(null);
    const inputCityRef = useRef(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);

    const handleClick = e => {
        if (dropdownRef.current.contains(e.target)) {
            return;
        }
        setVisibleCityList(false);
    };
    function clear() {
        changeInputCountryCitiesValue('');
        inputCityRef.current.focus();
    }

    const selectItem = item => {
        inputChooseItemInListClick(item)
        setVisibleCityList(false);
        setSearchedCities([])
    };

    function onInputFocus() {
        setPlaceholder(" ")
        setVisibleCityList(true);
    }
    function onInputBlur() {
        setTimeout(() => {
            setPlaceholder(t("userPage.city"))
        }, 100);
    }

    return (
        <div ref={dropdownRef} className="container">
            <div tabIndex="0" className={`input_container ${visibleCityList ? 'line' : ''}`}>
                {!userPage && <label className={`city ${visibleCityList ? 'v' : ''} ${inputCitiesValue ? 'a' : ''}`}>Город</label>}
                <div className="input-wrapper">
                    <input
                        ref={inputCityRef}
                        className="input"
                        type="text"
                        placeholder={placeholder}
                        disabled={pickedCountry?.name ? false : true}
                        value={inputCitiesValue}
                        onChange={(e) => changeInputCountryCitiesValue(e.target.value)}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                    />
                    {inputCitiesValue && <div className="clearIcon" onClick={() => clear()}></div>}
                </div>
            </div>
            <div ref={dropdownRef} className={`dropdown ${visibleCityList ? 'v' : ''}`}>
                {visibleCityList && (

                    <ul className="list">
                        {searchedCities.length > 0 &&
                            searchedCities.map(x => (
                                <li
                                    key={x.id}
                                    onClick={() => selectItem(x)}
                                    className="dropdown_item"
                                >
                                    <div className="item_text">{x.name}</div>
                                </li>
                            ))}
                        {/* you can remove the searchFilter if you get results from Filtered API like Google search */}
                        {searchedCities.length === 0 && topCities.length > 0 &&
                            topCities.map(x => (
                                <li
                                    key={x.id}
                                    onClick={() => selectItem(x)}
                                    className="dropdown_item"

                                >
                                    <div className="item_text">{x.name}</div>
                                </li>
                            ))}
                    </ul>

                )}
            </div>

        </div>
    );
}


