import { BehaviorSubject } from "rxjs";
import { API_URL } from "config";
import { fetchWrapper } from "helpers/fetchWrapper";
import { history } from "helpers/history";

const userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("user")));

export const AuthService = {
  login,
  loginWithGoogle,
  loginWithFacebook,
  logout,
  register,
  refreshToken,
  getFacebookKey,
  getGoogleKey,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  appKeys: {},
  userSubject: userSubject,
};

function login(email, password) {
  return fetchWrapper.post(`${API_URL}/Auth/login-credentials`, { email, password });
}

function logout() {
  fetchWrapper.post(`${API_URL}/Auth/logout`);
  localStorage.clear(); // CLEAR ALL!!!!
  userSubject.next(null);
  history.push("/login");
}

async function refreshToken(refreshToken) {
  return await fetchWrapper.post(`${API_URL}/Auth/login-refresh`,  {refreshToken} ).then((user) => {
    userSubject.next(user);
    return user;
  });
}

function register(email, isMale) {
  return fetchWrapper.put(`${API_URL}/Auth/register`, { email, isMale });
}

function loginWithGoogle(payload) {
  return fetchWrapper.post(`${API_URL}/Auth/ValidateGoogleToken`, payload);
}

function loginWithFacebook(payload) {
  return fetchWrapper.post(`${API_URL}/Auth/ValidateFacebookToken`, payload);
}

function getFacebookKey() {
  return fetchWrapper.get(`${API_URL}/Info/facebook-app-id`);
}

function getGoogleKey() {
  return fetchWrapper.get(`${API_URL}/Info/google-client-id`);
}
