import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const RulesPersonalInfo = () => {
    const { t, i18n } = useTranslation();

  return (
    <div className="rules-personal-info">
          <Link to="/rules">{t("settingsPage.terms")}</Link>
          <Link to="/personal-info">{t("settingsPage.personalTerms")}</Link>
    </div>
  );
};

export default RulesPersonalInfo;
