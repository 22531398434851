import { makeStyles } from "@material-ui/core";
import React from "react";
import ChatListItemShort from "../chatListItemShort/chatListItemShort";

export default function ChatSidebarShort({
  currentUser,
  chatList,
  activeRecipientId,
  chatListIsLoading,
  activateChat,
  searchChats,
  addUser,
}) {
  const styles = useStyles();

  return (
    <div className={styles.sidebarContainer}>
      {chatListIsLoading && <div className={styles.chatsListLoading}>Loading...</div>}
      {!chatListIsLoading && (
        <div className={styles.chatsList}>
          {chatList.map((chatItem) => (
            <ChatListItemShort
              isActive={chatItem.partnerId == activeRecipientId}
              key={chatItem.partnerId}
              chatItem={chatItem}
            ></ChatListItemShort>
          ))}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  sidebarContainer: {
    borderRight: "1px solid #eeeeee",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chatsList: {
    overflowY: "scroll",
  },
  chatsListLoading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
