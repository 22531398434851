import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import BadRequestImage from "assets/badRequest.png";
import { useTranslation } from 'react-i18next';

const ErrorPage = ({ props, error }) => {
  const classes = useStyles();
    const { t, i18n } = useTranslation();

  const buttonClick = () => {
    props.history.push("/");
  };

  return (
    <div className={classes.container}>
      <img src={BadRequestImage} className={classes.img}></img>
      <div className={classes.textContainer}>
        <Typography variant="h1">{error}</Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography variant="h6">ОШИБОЧНЫЙ ЗАПРОС</Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="secondary" onClick={buttonClick}>
                  {t("buttons.back")}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    maxHeight: "450px",
  },
  container: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default ErrorPage;
