import { makeStyles, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import { Done, MoreVert, Delete, HighlightOff } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { getFullBlobUrl } from "helpers/blobHelpers";
import { FILTER_MAP } from "helpers/complainstHelpers";

// import './userComplaintsTable.scss';
import "./userComplaintsTable.css";

export default function UserComplaintTable({
  complaints,
  totalComplaintsCount,
  paginationSettings,
  setPaginationSettings,
  onDeleteComplaint,
  onDeleteAllComplaintsByUserId,
  openUserProfile,
}) {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPaginationSettings({
      ...paginationSettings,
      currentPage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationSettings({
      ...paginationSettings,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  const handleDeleteItem = (complaintId) => {
    handleMenuClose();
    onDeleteComplaint(complaintId);
  };

  const handleDeleteAllItems = (userId) => {
    handleMenuClose();
    onDeleteAllComplaintsByUserId(userId);
  };

  const onUserClick = (userId) => {
    handleMenuClose();
    openUserProfile(userId);
  };

  const onFilterByReporter = (userId) => {
    handleMenuClose();
    const type = FILTER_MAP.Reporter;
    setPaginationSettings({
      ...paginationSettings,
      filter: { type, value: userId },
    });
  };

  const onFilterByReported = (userId) => {
    handleMenuClose();
    const type = FILTER_MAP.Reported;
    setPaginationSettings({
      ...paginationSettings,
      filter: { type, value: userId },
    });
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openMenuItemId, setOpenMenuItemId] = useState(null);
  const handleMenuClick = (event, complaintId) => {
    setMenuAnchorEl(event.currentTarget);
    setOpenMenuItemId(complaintId);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setOpenMenuItemId(null);
  };

  const clearFilter = () => {
    setPaginationSettings({
      ...paginationSettings,
      filter: null,
    });
  };

  const getFilteredByString = (filter) => {
    if (!filter) {
      return "";
    }
    if (filter.type === FILTER_MAP.Reporter) {
      return "Отфильтровано по отправителю";
    }
    if (filter.type === FILTER_MAP.Reported) {
      return "Отфильтровано по получателю";
    }
  };

  const ComplaintTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 16,
    },
  }))(Tooltip);

  return (
    <>
      {paginationSettings.filter && (
        <div className="filter-info-container">
          {getFilteredByString(paginationSettings.filter)}
          <button className="clear-filter-button">
            <HighlightOff onClick={clearFilter}></HighlightOff>
          </button>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>От:</TableCell>
            <TableCell>На:</TableCell>
            <TableCell align="right">Сообщение</TableCell>
            <TableCell align="right" className="complaints-table-control-cell"></TableCell>
            <TableCell align="right" className="complaints-table-control-cell"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {complaints.map((complaint) => (
            <TableRow key={complaint.id}>
              <TableCell scope="row">
                <div className="complains-user-container" onClick={() => onUserClick(complaint.reporterId)}>
                  <div className="complains-user-name">{complaint.reporterName}</div>
                  <Avatar alt={complaint.reporterName} src={getFullBlobUrl(complaint.reporterPrimaryImage)} />
                </div>
              </TableCell>
              <TableCell scope="row">
                <div className="complains-user-container" onClick={() => onUserClick(complaint.userId)}>
                  <div className="complains-user-name">{complaint.userName}</div>
                  <Avatar alt={complaint.userName} src={getFullBlobUrl(complaint.userPrimaryImage)} />
                </div>
              </TableCell>
              <TableCell align="right">
                <ComplaintTooltip title={complaint.message}>
                  <div className="complaint-message">{complaint.message}</div>
                </ComplaintTooltip>
              </TableCell>
              <TableCell className="complaints-table-control-cell" align="right">
                <button className="complaints-table-control" type="button">
                  <Delete onClick={() => handleDeleteItem(complaint.id)}></Delete>
                </button>
              </TableCell>
              <TableCell className="complaints-table-control-cell" align="right">
                <button className="complaints-table-control" type="button">
                  <MoreVert
                    id={`menu-button-${complaint.id}`}
                    aria-controls="complaints-item-menu"
                    aria-haspopup="true"
                    aria-expanded={openMenuItemId === complaint.id ? "true" : undefined}
                    onClick={(event) => handleMenuClick(event, complaint.id)}
                  ></MoreVert>
                </button>
                <Menu
                  id="complaints-item-menu"
                  anchorEl={menuAnchorEl}
                  open={openMenuItemId === complaint.id}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "complaints-menu-button",
                  }}
                >
                  <MenuItem onClick={() => handleDeleteAllItems(complaint.userId)}>
                    Удалить все жалобы на пользователя
                  </MenuItem>
                  <MenuItem onClick={() => onFilterByReporter(complaint.reporterId)}>Фильтровать отправителю</MenuItem>
                  <MenuItem onClick={() => onFilterByReported(complaint.userId)}>Фильтровать по получателю</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalComplaintsCount}
              rowsPerPage={paginationSettings.pageSize}
              page={paginationSettings.currentPage}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
