import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Tooltip, Avatar, IconButton, FormGroup, InputLabel } from "@material-ui/core";
import InputText from "components/ui/Inputs/InputText";
import itIcon from "assets/itIcon.png";
import editIcon from "assets/editFormIcon.png";
import EditForm from "./editForm";
import LastSeenOnline from "./components/LastSeenOnline";
import { useTranslation } from 'react-i18next';

const Infoform = ({ currentUser, setCurrentUser, lookups, anotherUser }) => {
  const styles = useStyles();

  const [openEditForm, setOpenEditForm] = useState(false);
  const [userValues, setUserValues] = useState([]);
  const [userValuesForEdit, setUserValuesForEdit] = useState({});
  const user = anotherUser ? anotherUser : currentUser;
  const { t, i18n } = useTranslation();

  const toggleOpenEditForm = () => setOpenEditForm(true);

  function selectCurrentValue(type, value) {
    setUserValuesForEdit((userValuesForEdit) => ({ ...userValuesForEdit, [type]: value }));
  }

  function setLookupsNamesFromSelectInput(lookups, user) {
    const lookupItem = lookups?.find((item) => item.id === user);
    if (lookupItem) {
      return lookupItem.name;
    }
  }

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    setUserValues({                                                                                                                     
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      fullName: user.fullName || "",
      genderId: user.genderId ? setLookupsNamesFromSelectInput(lookups.genders, user.genderId) : "",
      countryId: user.countryName || "",
      cityId: user.cityName || "",
      age: user.age || "",
      height: user.height || "",
      kids: user.kidsId ? setLookupsNamesFromSelectInput(lookups.kids, user.kidsId) : "",
      sexualOrientations: user.sexualOrientationId
        ? setLookupsNamesFromSelectInput(lookups.sexualOrientations, user.sexualOrientationId)
        : "",
      about: user.about || "",
      //next
      lookingForGenderId: user.lookingForGenderId
        ? setLookupsNamesFromSelectInput(lookups.lookingForGender, user.lookingForGenderId)
        : "",
      relationshipStatusId: user.relationshipStatusId
        ? setLookupsNamesFromSelectInput(lookups.currentRelationshipStatuses, user.relationshipStatusId)
        : "",
      sponsorship: user.sponsorship,
      kinky: user.kinky,
      lookingForDescription: user.lookingForDescription || "",
      //next
      positionId: user.positionId ? setLookupsNamesFromSelectInput(lookups.positions, user.positionId) : "",
      levelId: user.levelId ? setLookupsNamesFromSelectInput(lookups.level, user.levelId) : "",
      languageId: user.languageId ? setLookupsNamesFromSelectInput(lookups.languages, user.languageId) : "",
      company: user.company || "",
      canWorkRemotely: user.canWorkRemotely,
    });
  }, [currentUser, lookups, user]);

  useEffect(() => {
    setUserValuesForEdit({ ...userValues });
  }, [userValues]);
  console.log('user', user);

  return (
      <Container>
          {currentUser.id === user.id && !currentUser.HasEnoughInfoForShowingInSearch && <Tooltip title={<Typography>
              {t("userPage.messageNoSearch")}
		<ul>
			<li>- {t("userPage.firstName")} </li>
			<li>- {t("userPage.lastName")} </li>
            <li>- {t("userPage.city")} </li>
            <li>- {t("userPage.country")} </li>
            <li>- {t("userPage.age")} </li>
		</ul>
        </Typography>}>
             <div className={styles.noSearchLabel}>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.iconI} width="20px" height="20px" viewBox="0 0 20 20" fill="none">
                    <path fill="#fff"  d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm8-4a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm.01 8a1 1 0 102 0V9a1 1 0 10-2 0v5z"/>
                  </svg>
                  {t("userPage.messageTooltip")}</div>
        </Tooltip>}
      <div className={styles.header}>
              <div className={styles.nameContainer}>
          {!openEditForm ? (
            <Typography variant="h5" className={styles.name}>
              {user.fullName}
            </Typography>
          ) : (
            <div className={styles.editNameContainer}>
              <FormGroup>
                <InputLabel>{t("userPage.firstName")}</InputLabel>
                <InputText type="firstName" setValue={selectCurrentValue} value={userValuesForEdit.firstName} />
              </FormGroup>
              <FormGroup>
                <InputLabel>{t("userPage.lastName")}</InputLabel>
                <InputText type="lastName" setValue={selectCurrentValue} value={userValuesForEdit.lastName} />
              </FormGroup>
            </div>
          )}
          {!openEditForm && user.languageId && user.firstName && (
            <Avatar className={styles.itIcon} src={itIcon} classes={{ img: styles.imageIt }} />
          )}
        </div>
        <LastSeenOnline anotherUser={anotherUser} />
        {!openEditForm && !anotherUser && (
          <div className={styles.editButtonsContainer}>
            <IconButton onClick={toggleOpenEditForm} className={styles.button}>
              <Avatar src={editIcon} />
            </IconButton>
          </div>
        )}
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EditForm
          openEditForm={openEditForm}
          lookups={lookups}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          userValues={userValues}
          userValuesForEdit={userValuesForEdit}
          setUserValuesForEdit={setUserValuesForEdit}
          setOpenEditForm={setOpenEditForm}
          selectCurrentValue={selectCurrentValue}
        />
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    minHeight: "58px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    },
    noSearchLabel: {
        width: '100%',
        borderRadius: '4px',
        background: 'linear-gradient(to left, rgb(254,190,22),rgb(253,119,21))',
        color: "white",
        fontWeight: 600,
        cursor: "help",
        padding: "4px",
        margin: "8px 0 0",
        display: "flex",
        alignItems: "center"
    },
    iconI: {
        marginRight:"10px"
    },
  button: {
    padding: 8,
    childSize: 48,
  },
  itIcon: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    marginLeft: "10px",
    borderRadius: "0 !important",
  },
  imageIt: {
    borderRadius: "0 !important",
  },
  nameContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(2.4),
    paddingBottom: theme.spacing(2.4),
  },
  name: {
    color: "#303F9F",
  },
  flex: {
    display: "flex",
  },
  editNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  editButtonsContainer: {
    marginLeft: "auto",
  },
}));

export default Infoform;
