import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";

const SelectInput = ({ setValue, lookups, type, value, mini, title, searchPage, numeric, disabled, genderSelect }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      {title && <InputLabel>{title}</InputLabel>}
      <Select
        fullWidth={true}
        disabled={disabled}
        className={
          genderSelect
            ? classes.genderSelect
            : mini
            ? classes.miniSelect
            : numeric
            ? classes.numeric
            : classes.standartSelect
        }
        onChange={(e) => setValue(type, e.target.value)}
        value={value ? value : ""}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        classes={{ select: classes.select }}
      >
        {searchPage && <MenuItem divider={true} value={""} className={classes.emptyMenuItem}></MenuItem>}
        {lookups &&
          lookups.map((item, index) => (
            <MenuItem divider={true} dense={true} value={numeric ? item : searchPage ? item.id : item.name} key={index}>
              {numeric ? item : item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  genderSelect: {
    margin: 0,
  },
  standartSelect: {
    margin: "0px",
  },
  numeric: {
    margin: "0px",
  },
  miniSelect: {
    margin: "0px",
  },
  select: {},
  emptyMenuItem: {
    height: "33px",
  },
}));

export default SelectInput;
