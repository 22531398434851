import { makeStyles } from "@material-ui/core";
import { getFullBlobUrl } from "helpers/blobHelpers";
import React from "react";

export default function ChatSidebarHeader({ currentUser, openSenderProfile }) {
  const styles = useStyles();

  const openProfile = () => {
    console.log("opening stuff");
    openSenderProfile();
  };

  return (
    <div className={styles.chatItem} onClick={openProfile}>
      <div className={styles.userSection}>
        <div className={styles.imageWrapper}>
          <img src={getFullBlobUrl(currentUser.primaryImage)} alt={"Your user profile image"}></img>
        </div>
        <div className={styles.userInfo}>
          <p className={styles.userName}>{currentUser.fullName}</p>
          <p className={styles.latestMessage}>{"Some kind of user status I guess"}</p>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.75rem 1rem 0.5rem 1rem",
    marginBottom: "1px",
    cursor: "pointer",
    height: "3rem",
    borderRight: "3px solid transparent",
    borderBottom: "1px solid #eeeeee",
  },
  userSection: {
    flexGrow: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageWrapper: {
    width: "2rem",
    height: "2rem",
    marginRight: "0.3rem",
    "& > img": {
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      objectFit: "cover",
    },
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: "0.5rem",
    maxWidth: "60%",
  },
  userName: {
    padding: "0.1rem",
    margin: "0.1rem",
    color: "#263238",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  latestMessage: {
    padding: "0.1rem",
    margin: "0.1rem",
    color: "#616161",
    fontSize: "0.75rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
