import React from "react";
import { NavLink, Redirect, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Paper } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";

import { adminRoutes, userRoutes } from "routes";
import { useTranslation } from 'react-i18next';

export default function NavContent({ currentUser }) {
  const classes = useStyles();
  let match = useRouteMatch();
    const { t, i18n } = useTranslation();

  const currentRoutes = currentUser.role === "User" ? userRoutes : adminRoutes;

  const isActive = () => {
    if (+match.params.id === currentUser.id) {
      return true;
    }
    return;
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem} button disableGutters>
        <NavLink
          className={classes.link}
          exact
          to={`/user/${currentUser.id}`}
          isActive={isActive}
          activeClassName={classes.activeLink}
        >
          <FaceIcon className={classes.icon} />
                  <h6 className={classes.text}> {t("menu.myPage")}</h6>
        </NavLink>
      </ListItem>
      {currentRoutes.map(({ name, path, icon: Icon }, index) => {
        return (
          name?.length && (
            <div key={index}>
              <ListItem className={classes.listItem} button disableGutters>
                <NavLink className={classes.link} to={path} activeClassName={classes.activeLink}>
                  <Icon className={classes.icon} />
                  <h6 className={classes.text}>{name}</h6>
                </NavLink>
              </ListItem>
            </div>
          )
        );
      })}
    </List>
  );
}

const useStyles = makeStyles({
  list: {
    color: "blue",
    padding: "0px",
  },
  listItem: {
    padding: "0",
    height: "46px",
    paddingRight: "3px",
    borderRadius: "0 6px 6px 0",
  },
  activeLink: {
    color: "white !important",
    background: "linear-gradient(left, #373797, #7224a2)",
    borderRadius: "0 6px 6px 0",
    paddingRight: "5px",
    boxShadow: "2px 3px 3px 1px #cab2d6",
  },
  text: {
    marginLeft: "10px",
    fontWeight: "400",
    fontSize: "13.5px",
    paddingLeft: "10px",
  },
  link: {
    textDecoration: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  icon: {
    marginLeft: "15px",
  },
});
