import { makeStyles } from "@material-ui/core";
import React from "react";
import ChatMessageBlock from "../chatMessageBlock/chatMessageBlock";
import { getUserFriendlyDayBlockDate } from "helpers/dateHelpers";

export default function ChatMessageDay({ messageDay, openActiveRecipientProfile }) {
  const styles = useStyles();

  const { year, month, date, messageBlocks } = messageDay;

  const dateString = getUserFriendlyDayBlockDate(year, month, date);

  return (
    <div className={styles.chatMessageDay}>
      <div className={styles.chatDayHeader}>
        <p className={styles.chatDayParagraph}>{dateString}</p>
      </div>
      {messageBlocks.map((messageBlock, idx) => (
        <ChatMessageBlock
          key={idx}
          messageBlock={messageBlock}
          openActiveRecipientProfile={openActiveRecipientProfile}
        ></ChatMessageBlock>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chatMessageDay: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    minHeight: "100%",
    paddingBottom: "1rem",
    overflowX: "hidden",
  },
  chatDayHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#9e9e9e",
    fontSize: "1.25rem",
  },
  chatDayParagraph: {
    margin: "0.5rem 0 0 0",
  },
}));
