import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "components/ui/Buttons/button";
import DialogAction from "components/ui/DialogAction";
import { AuthService } from "services/auth-service";
import { useTranslation } from 'react-i18next';

const Logout = ({}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

  const handleLogout = () => {
    AuthService.logout();
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
                  <Button title={t("buttons.exit")} onClick={() => setIsOpen(true)} variant={"contained"} color={"primary"} />
        </div>
      </div>
      <DialogAction
        isOpen={isOpen}
        onClickYes={handleLogout}
        onClickNo={() => setIsOpen(false)}
              text={t("settingsPage.messageExit")}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  textContainer: {
    width: "350px",
    marginBottom: "20px",
  },
  text: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
  },
  buttonContainer: {
    marginBottom: "10px",
    width: "350px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default Logout;
