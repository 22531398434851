import React from "react";
import Email from "./cardItems/email";
import Lang from "./cardItems/lang";
import Password from "./cardItems/password";
import Delete from "./cardItems/delete";
import RulesPersonalInfo from "./cardItems/rules";
import Logout from "./cardItems/logout";
import ShowMe from "./cardItems/showMe";
import GorizontalLine from "../../../components/ui/GorizontalLine";

import Language from "@material-ui/icons/Language";
import EmailSharp from "@material-ui/icons/EmailSharp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SearchOutlined from "@material-ui/icons/SearchTwoTone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddToQueueRounded from "@material-ui/icons/AddToQueueRounded";
// import './settingsActions.scss';
import "./settingsActions.css";
import { useTranslation } from 'react-i18next';


const SettingsActions = ({ currentUser }) => {
    const { t, i18n } = useTranslation();

  return (
    <div className="settings-actions">
      <div className="settings-actions__item">
              <GorizontalLine text={t("settingsPage.selectLangTitle")}>
          <Language />
        </GorizontalLine>
        <Lang currentUser={currentUser} />
      </div>
      <div className="settings-actions__item">
              <GorizontalLine text={t("settingsPage.changeEmailTitle")}>
          <EmailSharp />
        </GorizontalLine>
        <Email currentUser={currentUser} />
      </div>
      <div className="settings-actions__item">
              <GorizontalLine text={t("settingsPage.changePassTitle")}>
          <VpnKeyIcon />
        </GorizontalLine>
        <Password currentUser={currentUser} />
      </div>
      <div className="settings-actions__item">
              <GorizontalLine text={t("settingsPage.deleteProfileTitle")}>
          <HighlightOffIcon />
        </GorizontalLine>
        <Delete currentUser={currentUser} />
      </div>
      <div className="settings-actions__item">
              <GorizontalLine text={t("settingsPage.closeProfileTitle")}>
          <SearchOutlined />
        </GorizontalLine>
        <ShowMe />
      </div>
      <div className="settings-actions__item">
              <GorizontalLine text={t("settingsPage.termsTitle")}>
          <AddToQueueRounded />
        </GorizontalLine>
        <RulesPersonalInfo />
      </div>
      <Logout />
    </div>
  );
};

export default SettingsActions;
